import react, { useEffect, useReducer, useMemo } from 'react'
import { lockedReducer } from '../../../utils/defaultReducer'
import { Box, Button } from '@mui/material'
import api from '../../../api'
import HistoricComponent from './historic_component'
import Loading from '../../Loading'

const Tabs = (contact, state={}, setState=()=>{}) => {
    return ({
        'historic': {
            name: 'Histórico',
            component: (<HistoricComponent
                contact={contact}
                data={state.historic}
                setParent={setState}
            />),
            permission: true,
            url: `/api/enterprises/${contact.enterprise}/contacts/cursor/${contact.id}/0/`
        },
        'business': { name: 'Negócios', component: (<>Historico2</>), permission: false },
        'tasks': { name: 'Tarefas', component: (<>Historico3</>), permission: false }
    })
}

const initialize = async (contact, setState) => {
    setState({ loading: true })

    const tabs = Object.entries(Tabs(contact)).filter(([, value]) => value.permission)

    const res = await Promise.all(tabs.map(([key, { url }]) => api.get(url).then(result => ({ ...result, hint: key }))))
    let newstate = { loading: false }
    res.map(response => {
        if (response.status === 200) {
            newstate = { ...newstate, [response['hint']]: response.data }
        }
    })
    setState(newstate)
}


const ProfileTab = ({ contact }) => {

    const [state, setState] = useReducer(lockedReducer, {
        tab: 'historic' || 'business' || 'tasks',
        historic: [[], 0],
        business: [],
        tasks: [],
        view: null,
        loading: false
    })

    useEffect(() => {
        initialize(contact, setState)
    }, [contact])

    const tabs = useMemo(() => Tabs(contact, state, setState), [state.historic, state.business, state.tasks])

    return (
        <Box className='profiletab'>
            <Loading loading={state.loading} />
            {/*<Box sx={{ padding: '0rem 0rem 0.5rem 0rem'}}>
                {Object.entries(tabs).flatMap(([key, value]) => {
                    if (value.permission) return (
                    <Button
                        onClick={() => setState({ tab: key })}
                        variant={state.tab === key ? 'contained' : 'standard'}
                        disabled={!value.permission}>
                        {value.name}
                    </Button>
                )})}
            </Box>*/}
            <Box className='profilecomponent'>
                {tabs[state.tab]?.component}
            </Box>
        </Box>
    )
}

export default ProfileTab
