import React, { useState } from "react"

import {
	Box,
    Card,
    CardContent,
    CardActions,
    Typography,
    IconButton,
    Menu,
    MenuItem
} from "@mui/material"

import AvatarName from "../../AvatarName"

import ApartmentIcon from "@mui/icons-material/Apartment"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import WhatsAppIcon from "@mui/icons-material/WhatsApp"
import EmailIcon from "@mui/icons-material/Email"

import "./styles.css"

const ContactCard = ({ client, onClick, onTelephoneClick, onEmailClick, onRemoveClick }) => {

    const [anchorEl, setAnchorEl] = useState(null)

    const open = Boolean(anchorEl)

    const handleClose = () => setAnchorEl(null)

    const handleRemove = (event) => {
        event.stopPropagation()
        if (onRemoveClick)
            onRemoveClick()
        handleClose()
    }

	return (
		<Card className="clients-card" onClick={onClick}>
            <CardContent className="clients-card-content">
                <Box className="header">
                    <AvatarName 
                        name={(client.name) ? client.name : "Sem Nome"} 
                        handleClick={() => {}} 
                        size="small" 
                    />
                    <Typography variant="h6" className="ellipsis">{client.name}</Typography>
                </Box>
                <Box className="content">
                    {client.contact_enterprise_name &&
                        <Box display="flex" alignItems="center" gap={1}>
                            <ApartmentIcon fontSize="small" sx={{ color: "#21446c" }} />
                            <Typography variant="body2">{client.contact_enterprise_name}</Typography>
                        </Box>
                    }
                    {client.telephone &&
                        <Box display="flex" alignItems="center" gap={1}>
                            <WhatsAppIcon fontSize="small" sx={{ color: "#34af23" }}/>
                            <Typography variant="body2" onClick={(event) => {
                                event.stopPropagation()
                                onTelephoneClick()
                            }}>{client.telephone}</Typography>
                        </Box>
                    }
                    {client.email &&
                        <Box display="flex" alignItems="center" gap={1}>
                            <EmailIcon fontSize="small" sx={{ color: "gray" }}/>
                            <Typography variant="body2" className="ellipsis" onClick={(event) => {
                                event.stopPropagation()
                                onEmailClick()
                            }}>{client.email}</Typography>
                        </Box>
                    }
                </Box>
                <Box className="footer" sx={{ overflow: "auto" }}>
                    {[...client.groups, ...client.enterprise_groups].map((group) => (
                        <Box className="client-group-item">
                            {group.name}
                        </Box>
                    ))}
                </Box>
                <IconButton className="actions" onClick={(event) => { 
                    event.stopPropagation()
                    setAnchorEl(event.currentTarget)
                }}>
                    <MoreVertIcon />
                </IconButton>
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    <MenuItem onClick={handleRemove}>Excluir</MenuItem>
                </Menu>
            </CardContent>
        </Card>
	)
}

export default ContactCard
