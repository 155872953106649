import React, { useState, useEffect } from 'react';

import {
    IconButton,
    Box,
    Divider,
    Typography,
    List,
    ListItem
} from "@mui/material"

import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import MaterialMenu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from "prop-types";
import AvatarName from "../../AvatarName";
import * as moment from "moment";

import LoginIcon from "@mui/icons-material/Login"
import PeopleIcon from "@mui/icons-material/People"
import PauseIcon from "@mui/icons-material/Pause"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"

import api from "../../../api"
import { saveSession } from "../../../auth"

import io from "socket.io-client"

import "./styles.css";

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <MaterialMenu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.secondary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

const AccountMenu = (
    { user, menuItems, isLogoutButtonLoading }
) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [pauseReason, setPauseReason] = useState()
    const [pauseStart, setPauseStart] = useState()
    const [loading, setLoading] = useState(false)
    const [connected, setConnected] = useState(false)
    const [lastSocketResponse, setLastSocketResponse] = useState(null)
    const [pauseReasons, setPauseReasons] = useState([])
    const [renderPauseAction, setRenderPauseAction] = useState(true)

    const useStyles = makeStyles({
        icon: {
            color: "#fff"
        },
        menuHeader: {
            padding: "10px 15px",
            minWidth: "200px",
            fontSize: "10pt"
        },
        menuName: {
            fontWeight: "bold",
            color: "#68686B",
            fontSize: "14pt"
        },
        pushLeft: {
            paddingLeft: "15px"
        },
        pushTop: {
            marginTop: "10px"
        },
        menuRow: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1rem"
        },
        menuRowSubtitle: {
            display: "flex",
            gap: ".5rem",
            alignItems: "center"
        },
        menuRowIcon: {
            fontSize: "1rem"
        },
        queueList: {
            paddingRight: 0,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: ".5rem"
        },
        actionRow: {
            margin: "-8px 0",
            "&:nth-of-type(1)": {
                marginTop: 0
            }
        },
        actionRowIcon: {
            color: "black"
        }
    });

    const classes = useStyles();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const nameQueues = () => {
        let queues = [];

        user.profile.queues.map(queue => (
            queues.push({
                name: queue.description,
                code: queue.name.split('_')[1]
            })
        ));

        return queues;
    }

    const handlePause = () => {
        setLoading(true)
        if (pauseReason)
            api.post(`/api/IV/agents/${user.profile.agent_id}/unpause/`)
        else
            api.post(`/api/IV/agents/${user.profile.agent_id}/pause/`, {
                "reason_number": pauseReasons[0]["number"]
            })
        setLoading(false)
    }

    const isInstantVoiceIntegrated = () => {
        return (user.profile.instantvoice_url
            && user.profile.instantvoice_accountcode
            && user.profile.instantvoice_api_key
            && user.profile.instantvoice_agent_code
        )
    }

    const handleCallEvent = (message) => {
        let session
        switch (message.type) {
            case "PAUSE":
                setPauseReason(message.direction)
                setPauseStart(Math.floor(Date.now() / 1000))
                user.profile.agent.agentstatus = "PAUSE"
                user.profile.agent.reasons_reason = message.direction
                user.profile.agent.agentstatusts = Math.floor(Date.now() / 1000)
                saveSession(user)
                break
            case "UNPAUSE":
                setPauseReason()
                setPauseStart()
                user.profile.agent.agentstatus = "LOGIN"
                user.profile.agent.reasons_reason = message.direction
                user.profile.agent.agentstatusts = Math.floor(Date.now() / 1000)
                saveSession(user)
                break
            default:
                break
        }
    }

    const isACDActive = async () => {
        const response = await api.get(`api/enterprises/${user.profile.enterprise.id}/is_acd_active/`)
        return response.status === 200 && Boolean(response.data)
    }

    const connectWebSocket = async (reasons) => {

        if (! await isACDActive()) {
            setRenderPauseAction(false)
            return
        }

        if (window.socketVoice && !isInstantVoiceIntegrated()) {
            // Socket is already connected for voice operations
            window.socketVoice.on("call-event", (event) => {
                const message = (JSON.parse(event.body.trim())).pop()
                handleCallEvent(message)
            })
            return
        }

        if (reasons.length === 0) return // Do not spend resources when there are no pauses registered

        let socket = null
        let keepAlive = null

        const options = { upgrade: false, transports: ["websocket"] }

        let url = `https://${location.host}:443`

        if (!connected) {
            socket = io(url, options)
            socket.on("connect", () => {
                let agent = user.profile.agent.user
                socket.emit("subscribe", { agent: agent })
                keepAlive = setInterval(() => {
                    // If server not responding for more than 11 seconds
                    // the connection might have been closed somewhere
                    // we need to close and reopen a new websocket connection
                    let curTime = new Date().getTime()
                    if (lastSocketResponse && curTime - lastSocketResponse > 11000) {
                        socket.disconnect()
                        socket.open()
                        window.accountMenuSocket = socket
                        return
                    }

                    // Pinging server every 20secs
                    socket.emit("xping", { agent: user.profile.agent.user })
                }, 10000)
            })
            socket.on("call-event", (event) => {
                const message = (JSON.parse(event.body.trim())).pop()
                handleCallEvent(message)
            })
            socket.on("disconnect", () => {
                clearInterval(keepAlive)
            })
            socket.on("xpong", (event) => {
                setLastSocketResponse(new Date().getTime())
            })
            setConnected(true)
        }

        if (socket) {
            socket.on("disconnect", () => {
                window.accountMenuSocket = null
                setLastSocketResponse(null)
            })
        }

        window.accountMenuSocket = socket
    }

    const closeWebSocket = () => {
        if (window.accountMenuSocket?.connected)
            window.accountMenuSocket.disconnect()
        window.accountMenuSocket = null;
    }

    const getPauseReasons = async () => {
        const response = await api.get(`api/IV/agents/${user.profile.id}/pause_reasons/`)
        if (response.status === 200) {
            setPauseReasons(response.data)
            return response.data
        }
    }

    useEffect(() => {
        const initialize = async () => {
            if (user.profile.agent.agentstatus == 'PAUSE') {
                setPauseReason(user.profile.agent.reasons_reason)
                setPauseStart(user.profile.agent.agentstatusts)
            }
            const reasons = await getPauseReasons()
            connectWebSocket(reasons)
        }
        initialize()
        return () => closeWebSocket()
    }, [])

    return (
        <Box>
            <IconButton size="large">
                <AvatarName
                    name={`${user.first_name} ${user.last_name}`}
                    handleClick={handleClick}
                    size="medium"
                />
                {user.profile?.agent?.agentstatus == "LOGIN" && !pauseReason &&
                    <Box className="circle login" title="Agente Logado"></Box>
                }
                {user.profile?.agent?.agentstatus == "LOGOFF" && !pauseReason &&
                    <Box className="circle logoff" title="Agente Deslogado"></Box>
                }
                {(user.profile?.agent?.agentstatus == "PAUSE" || pauseReason) &&
                    <Box className="circle pause" title="Agente Pausado"></Box>
                }
            </IconButton>
            <StyledMenu
                sx={{ zIndex: 2001 }}
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <Box>
                    {user &&
                        <div className={classes.menuHeader}>
                            <Typography variant="h6">Olá, {user.first_name} {user.last_name}</Typography>
                            {user.profile && user.profile.agent &&
                                <>
                                    <Box className={classes.menuRow} mt={2}>
                                        <Box className={classes.menuRowSubtitle}>
                                            <LoginIcon className={classes.menuRowIcon} />
                                            <Typography variant="subtitle2">Login</Typography>
                                        </Box>
                                        <Typography variant="body2">
                                            {moment(new Date(user.profile.agent.logints * 1000)).format("DD/MM HH:mm").toString()}
                                        </Typography>
                                    </Box>
                                    {pauseReason &&
                                        <Box className={classes.menuRow} mt={2}>
                                            <Box className={classes.menuRowSubtitle}>
                                                <PauseIcon className={classes.menuRowIcon} />
                                                <Typography variant="subtitle2">{pauseReason}</Typography>
                                            </Box>
                                            <Typography variant="body2">
                                                {moment.duration(moment(new Date()).diff(moment(pauseStart * 1000))).asMinutes().toFixed(0)} minuto(s)
                                            </Typography>
                                        </Box>
                                    }
                                    <Box mt={2}>
                                        <Box className={classes.menuRowSubtitle}>
                                            <PeopleIcon className={classes.menuRowIcon} />
                                            <Typography variant="subtitle2">Filas de atendimento</Typography>
                                        </Box>
                                        <List dense>
                                            {nameQueues().map((queue, index) => (
                                                <ListItem className={classes.queueList}>
                                                    <Typography variant="body2">{queue.name}</Typography>
                                                    <Typography variant="body2">{queue.code}</Typography>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Box>
                                </>
                            }
                        </div>
                    }
                    {renderPauseAction && pauseReasons.length > 0 &&
                        <>
                            <Divider />
                            <StyledMenuItem className={classes.actionRow} onClick={() => handlePause()} disabled={loading}>
                                <ListItemIcon className={classes.actionRowIcon}>
                                    {loading
                                        ? (<CircularProgress color="inherit" size={16} />)
                                        : (pauseReason)
                                            ? (<PlayArrowIcon fontSize="small" />)
                                            : (<PauseIcon fontSize="small" />)
                                    }
                                </ListItemIcon>
                                <ListItemText primary={(pauseReason) ? "Despausar" : "Pausar"} />
                            </StyledMenuItem>
                        </>
                    }
                    {menuItems.map((item, i) => (
                        <>
                            <Divider />
                            <StyledMenuItem className={classes.actionRow} onClick={item.onClick} disabled={isLogoutButtonLoading}>
                                <ListItemIcon className={classes.actionRowIcon}>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.title} />
                            </StyledMenuItem>
                        </>
                    ))}
                </Box>
            </StyledMenu>
        </Box>
    );
}

AccountMenu.propTypes = {
    menuItems: PropTypes.array,
    isLogoutButtonLoading: PropTypes.bool
};
export default AccountMenu;
