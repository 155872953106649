import React, { useState, useEffect, useMemo } from 'react'
import {
    Box, Paper, Tooltip, IconButton, Divider, List, FormGroup, FormControl, Popover,
    Switch, TextField, ButtonGroup, Button, InputBase, Chip, ListItem, FormControlLabel,
    InputAdornment, InputLabel, Select, MenuItem,
    Autocomplete
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

import * as moment from "moment";
import Filter from '../../Filter';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '../../Typography';
import FilterIcon from '@mui/icons-material/FilterAlt';
import { getSession } from '../../../auth';
import { alphabeticalSort } from '../../../utils/alphabeticalSort';

import { replaceMCDU } from '../../Settings/Vivre/Vivremenu/replaceMCDU';
import GroupAutocomplete from '../../Autocomplete/Group'
import api from '../../../api';

const filterForMethods = {
    'name': 'Nome',
    'phone': 'Telefone',
    'label': 'Etiqueta',
    'agent': 'Operador',
    'group': 'Segmento'
}

let timeout = 0
const delayedTime = 500
export const delayedChange = (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => searchValue(...args), delayedTime)
}

const searchValue = async (value, method, setFilterOptions, setSmallLoading) => {
    let res = null
    setSmallLoading(true)
    const enterpriseId = getSession().profile.enterprise.id
    switch (method) {
        case 'name':
            res = await api.get(`api/enterprises/${enterpriseId}/contacts/?limit=10&offset=0&name__icontains=${value}`)
            if (res.status === 200)
                res = res.data.results.map(e => ({ id: e.id, label: e.name }))
            break
            res = []
            break
        case 'phone':
            return []
        case 'label':
            res = await api.get(`api/enterprises/${enterpriseId}/labelchat/get_label_filtered/?limit=10&offset=0&name__icontains=${value}`)
            if (res.status === 200)
                res = res.data.results.map(e => ({ id: e.id, label: e.name }))
            break
            res = []
            break
        case 'agent':
            res = await api.get(`api/enterprises/${enterpriseId}/users/get_users_filtered/?limit=10&offset=0&name__icontains=${value}`)
            if (res.status === 200)
                res = res.data.results.map(e => ({ id: e.agent_id, label: e.name }))
            break
            res = []
            break
        case 'group':
            res = await api.get(`api/enterprises/${enterpriseId}/contact_group/get_group_filtered/?limit=10&offset=0&name__icontains=${value}`)
            if (res.status === 200)
                res = res.data.results.map(e => ({ id: e.id, label: e.name }))
            break
            res = []
            break
        default:
            break
    }
    setSmallLoading(false)
    setFilterOptions(res)
}

export const possible_channels = {
    'WHATSAPP': 'Whatsapp',
    'INSTANTCHAT': 'InstantChat',
    'EMAIL': 'Email',
    'TELEGRAM': 'Telegram',
    'INSTAGRAM': 'Instagram',
    'MESSENGER': 'Messenger',
    'MSTEAMS': 'MS Teams'
}

const FilterComponent = ({ mode, filterSettings, setFilterSettings, reloadState, setHeartbeat, sortComponent, hideSort, tooglejustme, showjustme, allGroups }) => {
    let timeout = 0
    const [smallLoading, setSmallLoading] = useState(false)
    const [showPopover, setShowPopover] = useState(false)
    const [anchorEl, setAnchorEl] = useState()
    const [refreshing, setRefreshing] = useState(false)
    const [Queues, setQueues] = useState({})
    const [filterText, setFilterText] = useState('')
    const [platforms, setPlatforms] = useState({})
    const [filterData, setFilterData] = useState({
        Ini: '',
        End: '',
        before: true,
        after: true
    })
    const [filterFor, setFilterFor] = useState('')
    const [filterOptions, setFilterOptions] = useState([])
    const [group, setGroup] = useState()

    const now = new Date()
    const limit_date = now.toISOString().substring(0, 16)
    now.setTime(now.getTime() - 7 * 24 * 3600 * 1000)
    const init_date = now.toISOString().substring(0, 16)

    const refreshState = () => { setRefreshing(p => !p) }

    const user = getSession()


    if (user.profile.enterprise.automation) {
        possible_channels['AUTOMATION'] = 'Automação';
    }


    useMemo(() => {
        user.profile.queues.map(queue => {
            Queues[queue.id] = { name: queue.description, code: queue.name, amount: 0, checked: true }
        })
        Queues['0'] = { name: 'Sem fila', code: 0, amount: 0, checked: true }
    }, [Queues])



    const doFilter = (event, clean) => {
        if (clean) {
            setFilterSettings(null)
            setFilterText('')
            setFilterFor('')
            setPlatforms({})
            setQueues({})
            setHeartbeat(p => !p)
            return
        }

        let string = '&f=oo'
        if (filterText && filterText !== '')
            string += `&search=${filterText?.id || filterText}`
        if (filterFor)
            string += `&filterfor=${filterFor}`
        if (!clean && filterData.Ini != '')
            string += `&ini=${filterData.Ini}`
        if (!clean && filterData.End != '')
            string += `&end=${filterData.End}`

        if (group)
            string += `&group=${group.id}`

        Object.keys(platforms).map(each => {
            string += `&platforms=${each}`
            return 0
        })

        const selectedQueues = Object.entries(Queues).filter((each, value) => (each[1].checked == false))

        selectedQueues.map(each => each[0]).map(
            each => { string += `&removeQueue=${each}` }
        )


        setFilterSettings({ data: string, filterfor: filterFor, search: filterText, ini: filterData.Ini, end: filterData.End, queues: selectedQueues.map(each => each[1].code), platforms: platforms })
        setShowPopover(false)
        //create string by filterText, filterQueues, dataInterval

    }

    const setDateTime = (type, value, enableFilterData) => {
        const now = new Date()
        now.setTime(now.getTime() + 3 * 3600 * 1000)

        const initialDate = moment(now).subtract(value, type).format("YYYY-MM-DDTHH:mm").toLocaleString();
        const finalDate = moment(now).format("YYYY-MM-DDTHH:mm").toLocaleString();

        filterData.Ini = initialDate
        filterData.End = finalDate
        if (enableFilterData)
            setFilterData(filterData)
        refreshState()
    }

    const setWindows = (type, condition) => {
        if (type === 'before') {
            if (condition) {
                filterData.End = moment(new Date()).format("YYYY-MM-DDTHH:mm").toLocaleString();
            } else {
                filterData.End = moment(new Date()).subtract(1, 'day').format("YYYY-MM-DDTHH:mm").toLocaleString();
            }
            filterData.before = condition;
        } else if (type === 'after') {
            if (condition) {
                filterData.Ini = moment(new Date()).subtract(7, 'day').format("YYYY-MM-DDTHH:mm").toLocaleString();
            } else {
                filterData.Ini = moment(new Date()).subtract(1, 'day').format("YYYY-MM-DDTHH:mm").toLocaleString();
            }
            filterData.after = condition;
        }
        setFilterData(filterData)
        refreshState()
    }

    useEffect(() => {
        setDateTime('day', 7);
    }, [])

    const QueueRow = Object.values(Queues).sort(alphabeticalSort)

    const showFilters = () => {
        return (
            <>
                {QueueRow.length > 0 && <Box>
                    <ListItem button key={-1} className="group-item">
                        <FormControlLabel
                            label="Todas"
                            control={
                                <Switch
                                    checked={QueueRow.map(each => each.checked).reduce((x, y) => x && y)}
                                    onChange={(event) => {
                                        QueueRow.map(each => { each.checked = event.target.checked });
                                        refreshState()
                                    }}
                                />
                            }
                        />
                    </ListItem>
                    {QueueRow.map((value, index) => (
                        <ListItem button key={index} className="group-item">
                            <FormControlLabel
                                label={value.name}
                                control={
                                    <Switch
                                        checked={value.checked}
                                        onChange={() => {
                                            value.checked = !value.checked;
                                            refreshState()
                                        }}
                                    />
                                }
                            />
                        </ListItem>
                    ))}
                </Box>}
            </>
        );
    }

    return (
        <Box className={`filter-chats ${mode == 2 ? 'finished-chats-filter' : ''}`} >

            <Paper
                component="form"
                sx={{ display: 'flex', alignItems: 'center', width: 400, height: 50, bgcolor: '#efefef' }}
            >
                {/* <Tooltip title="Filtrar por número de telefone, nome do contato, código, operador ou etiqueta" placement="bottom" disableInteractive>
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Pesquisar atendimentos"
                        onChange={(event) => { !event.target.value.includes('&') ? setFilterText(event.target.value) : null }}
                        value={filterText}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                                e.preventDefault(); doFilter()
                            }
                        }}
                    />
                </Tooltip>
                <Tooltip title="Aplicar filtro da pesquisa por texto" placement="bottom" disableInteractive>
                    <IconButton
                        sx={{ p: '10px' }} aria-label="search"
                        onClick={doFilter}
                    >
                        <SearchIcon />
                    </IconButton>
                </Tooltip> */}
                <Box sx={{ flex: 1, display:'flex', justifyContent:'center' }}>
                    {filterSettings &&
                        <Button sx={{height: '3rem'}} onClick={(e)=> doFilter(e, true)}>
                            Limpar Filtros
                        </Button>
                    }
                </Box>
                {
                    (sortComponent && !hideSort) && <>
                        {sortComponent}
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    </>
                }
                {
                    (tooglejustme && showjustme) && <>
                        {tooglejustme}
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    </>
                }
                <>
                    <Tooltip title="Filtrar por filas de atendimentos" placement="bottom" disableInteractive>
                        <Box className="manage-calls-filter">
                            <Filter content={
                                <List className="manage-calls-list">
                                    <Box className="manage-calls-queues">
                                        <Typography align="center"
                                            text="Filas de Atendimento"
                                            color="darkGray"
                                            fontWeight='500'
                                            className="group-item"
                                        />
                                        
                                    </Box>
                                    {showFilters()}
                                    <Button
                                        variant="contained" sx={{ width: "5rem", marginTop: "1rem" }}
                                        onClick={doFilter}
                                    >Aplicar</Button>
                                </List>
                            } />
                        </Box>
                    </Tooltip>
                </>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <Tooltip title="Filtros" placement="bottom" disableInteractive>
                    <IconButton sx={{ mr: ".25rem" }}
                        color="primary" 
                        aria-label="directions"
                        onClick={(event) => { setShowPopover(true); setAnchorEl(event.currentTarget) }}
                        className="filter-icon"
                    >
                       <FilterIcon />
                    </IconButton>
                </Tooltip>
                <Popover
                    className="popover-filter"
                    open={showPopover}
                    anchorEl={anchorEl}
                    onClose={() => { setShowPopover(false); setAnchorEl(null) }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <FormGroup className="filter-switch">
                        <Box className="title-filters">Visualização dos contatos</Box>
                        <Box className="switchs-filters">
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={filterData.before}
                                        onClick={() => { setWindows('before', !filterData.before) }}
                                    />
                                }
                                label="Dentro da janela de 24 horas"
                                className="switch-item"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={filterData.after}
                                        onClick={() => { setWindows('after', !filterData.after) }}
                                    />
                                }
                                label="Fora da janela de 24 horas"
                                className="switch-item"
                            />
                        </Box>
                    </FormGroup>
                    <FormGroup className="filters-body" key={'dsad'}>
                        <Box className="title-filters">Intervalo de datas</Box>
                        <Tooltip title="Data inicial do filtro" placement="right" disableInteractive>
                            <TextField
                                className="date-start-time"
                                size="small"
                                label="Início"
                                type="datetime-local"
                                variant="outlined"
                                value={filterData.Ini}
                                disabled={!(filterData.before || filterData.after)}
                                onChange={(event) => { setFilterData(prevState => ({ ...prevState, Ini: event.target.value })) }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    // min: init_date,
                                    max: limit_date
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Data final do filtro" placement="right" disableInteractive>
                            <TextField
                                className="date-end-time"
                                size="small"
                                label="Fim"
                                type="datetime-local"
                                variant="outlined"
                                value={filterData.End}
                                disabled={!(filterData.before || filterData.after)}
                                onChange={(event) => { setFilterData(prevState => ({ ...prevState, End: event.target.value })) }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    //min: init_date,
                                    max: limit_date
                                }}
                            />
                        </Tooltip>
                    </FormGroup>
                    <Box className="filters-buttons">
                        <Box className="title-filters">Filtros rápidos</Box>
                        <ButtonGroup
                            className="buttons-group"
                            variant="outlined"
                            aria-label="outlined button group"
                            size="medium"
                            disabled={!(filterData.before || filterData.after)}
                        >
                            <Tooltip title="Definir intervalo de datas em 24 horas" placement="top" disableInteractive>
                                <Button onClick={() => { setDateTime('day', 1, true); doFilter() }}>
                                    24 horas
                                </Button>
                            </Tooltip>
                            <Tooltip title="Definir intervalo de datas em 1 semana" placement="top" disableInteractive>
                                <Button onClick={() => { setDateTime('day', 7, true); doFilter() }}>
                                    1 semana
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                    </Box>
                    <Box className="filters-buttons" sx={{ margin: '1rem 0rem' }}>
                        <Box className="title-filters">Canais</Box>
                        <ButtonGroup
                            className="buttons-group"
                            variant="outlined"
                            aria-label="outlined button group"
                            size="medium"
                        >
                            {Object.entries(possible_channels).map(([key, value]) =>
                                <Tooltip title={value} placement="top" disableInteractive>
                                    <Button
                                        sx={{ width: '1rem' }}
                                        onClick={() => {
                                            if (key in platforms)
                                                delete platforms[key]
                                            else
                                                platforms[key] = true
                                            setPlatforms({ ...platforms })
                                        }}
                                        variant={key in platforms ? 'contained' : 'standard'}
                                    >
                                        {replaceMCDU(key)}
                                    </Button>
                                </Tooltip>
                            )}
                        </ButtonGroup>
                    </Box>
                    {/* {user.profile.enterprise.show_chat_groups &&
                        <Box className="filters-buttons" sx={{ margin: '1rem 0rem' }}>
                            <Box className="title-filters">Segmento</Box>
                            <GroupAutocomplete
                                allGroups={allGroups}
                                onChange={(group) => setGroup(group)}
                                selectedGroup={group}
                            />
                        </Box>
                    } */}
                    <Box className="filters-buttons flex" sx={{ margin: '1rem 0rem' }}>
                        <Box className="title-filters">Contatos</Box>
                        <FormControl
                            fullWidth
                            size="small"
                            sx={{ flex: .45 }}
                        >
                            <InputLabel id="filter-select-label">Filtrar por</InputLabel>
                            <Select
                                labelId="filter-select-label"
                                id="filter-simple-select"
                                onChange={(event) => { setFilterFor(event.target.value); setFilterText(''); setFilterOptions([]) }}
                                value={filterFor}
                                label='Filtrar por'
                                sx={{ textAlignLast: 'left' }}
                            >
                                {Object.entries(filterForMethods).map(([key, value]) => (
                                    <MenuItem key={`${key}-select-filter`} value={key}>{value}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {filterFor !== '' && <Autocomplete
                            id="free-for-filter-select"
                            key={`free-for-filter-${filterFor}`}
                            freeSolo={filterFor==='phone'}
                            filterOptions={(x) => x}
                            sx={{ flex: .55 }}
                            size='small'
                            options={filterFor === 'phone' ? [] : filterOptions}
                            value={filterText}
                            onChange={(e, v) => { setFilterText(v) }}
                            noOptionsText={'Nenhum resultado encontrado. É necessário selecionar uma opção para filtrar corretamente.'}
                            disabled={filterFor === ''}
                            onInputChange={(e, v) => filterFor === 'phone' ? setFilterText(v) : delayedChange(v, filterFor, setFilterOptions, setSmallLoading)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    disabled={filterFor === ''}
                                    slotProps={{
                                        input: {
                                            ...params.InputProps,
                                            type: 'search',
                                            endAdornment: (
                                                <>
                                                    {smallLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        },
                                    }}
                                />)}
                        />}
                    </Box>
                    <Box className="filters-action">
                        <Button className="button-discard"
                            onClick={(e) => {
                                setShowPopover(false); setAnchorEl(null);
                                setDateTime('day', 7);
                                setPlatforms({})
                                setGroup()
                                doFilter(e, true)
                            }}
                        >
                            Limpar
                        </Button>
                        <Button variant="contained"
                            disabled={!(filterData.before || filterData.after)}
                            onClick={doFilter}
                        >
                            Aplicar
                        </Button>
                    </Box>
                </Popover>
            </Paper >
            {/* {mode != 2 ? <Button variant='contained' > Aplicar filtro </Button> : null} */}
        </Box >
    )
}


export default FilterComponent