import React, { useState, useEffect } from "react"

import "react-big-calendar/lib/css/react-big-calendar.css"

import {
	Box,
	Typography,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
    IconButton
} from "@mui/material"

import {
	Timeline,
	TimelineItem,
	TimelineSeparator,
	TimelineConnector,
	TimelineContent,
	TimelineDot
} from "@mui/lab"

import { Calendar, momentLocalizer } from "react-big-calendar"

import userPermissions from "../User/Permissions"

import WhatsAppIcon from "@mui/icons-material/WhatsApp"
import EmailIcon from "@mui/icons-material/Email"
import TelephoneIcon from "@mui/icons-material/Phone"
import EventIcon from "@mui/icons-material/Event"
import PersonIcon from "@mui/icons-material/Person"
import SupportAgentIcon from "@mui/icons-material/SupportAgent"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/Cancel"
import CelebrationIcon from "@mui/icons-material/Celebration"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import FacebookIcon from "@mui/icons-material/Facebook"
import TelegramIcon from "@mui/icons-material/Telegram"
import InstagramIcon from "@mui/icons-material/Instagram"
import SmsIcon from "@mui/icons-material/Sms"

import Event from "./Event"

import { ScreenContext } from "../Crm"
import ContactProfile from "../Crm/ContactProfile"
import Loading from "../Loading"
import useNotification from "../Notification"

import moment from "moment"

import dayjs from "dayjs"

import { getSession } from "../../auth"
import api from "../../api"

import "./styles.css"

moment.updateLocale("pt-br", {
    months: [ "Janeiro",  "Fevereiro",  "Março",  "Abril",  "Maio",  "Junho", "Julho",  "Agosto",  "Setembro",  "Outubro",  "Novembro",  "Dezembro" ],
    monthsShort: [ "Jan",  "Fev",  "Mar",  "Abr",  "Mai",  "Jun", "Jul",  "Ago",  "Set",  "Out",  "Nov",  "Dez" ],
    weekdays: [ "Domingo",  "Segunda",  "Terça", "Quarta",  "Quinta",  "Sexta",  "Sábado" ],
    weekdaysShort: [ "Dom",  "Seg",  "Ter",  "Qua",  "Qui",  "Sex",  "Sáb"]
});

moment.locale("pt-br")
const localizer = momentLocalizer(moment)

const groups = userPermissions()

const calendarTranslations = {
    allDay: "Dia inteiro",
    previous: "Anterior",
    next: "Próximo",
    today: "Hoje",
    month: "Mês",
    week: "Semana",
    day: "Dia",
    agenda: "Agenda",
    date: "Data",
    time: "Hora",
    event: "Evento",
    showMore: (total) => `+ Ver mais (${total})`,
    work_week: "Semana de Trabalho",
    yesterday: "Ontem",
    tomorrow: "Amanhã",
    noEventsInRange: "Nenhum evento neste período."
}

const platformIcon = {
    "WHATSAPP"  : <WhatsAppIcon sx={{ color: "#34af23" }} />,
    "EMAIL"     : <EmailIcon sx={{ color: "#757575" }}/>,
    "VOICE"     : <TelephoneIcon sx={{ color: "#333333" }} />,
    "BIRTHDAY"  : <CelebrationIcon sx={{ color: "#333333" }} />,
    "TELEGRAM"  : <TelegramIcon sx={{ color: "#3390ec" }} />,
    "INSTAGRAM" : <InstagramIcon sx={{ color: "#fd6b10" }} />,
    "MESSENGER" : <FacebookIcon sx={{ color: "#0866ff" }} />,
    "SMS"       : <SmsIcon sx={{ color: "#757575" }} />,
}

const statusIcon = {
    "COMPLETED" : <CheckCircleIcon sx={{ color: "green" }} />,
    "CANCELLED" : <CancelIcon sx={{ color: "red" }} />,
    "SCHEDULED" : <EventIcon sx={{ color: "#5166a6" }} />
}

const Agenda = () => {

    const [status, setStatus] = useState("SCHEDULED")
    const [dateStatus, setDateStatus] = useState("today")
    const [timelineTasks, setTimelineTasks] = useState([])
    const [calendarTasks, setCalendarTasks] = useState([])
    const [profile, setProfile] = useState(null)
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [myTeam, setMyTeam] = useState([])
    const [loading, setLoading] = useState(false)
    
    const [date, setDate] = useState({
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear()
    })

    const [profileData, setProfileData] = useState({
        originNumbers : [],
        customFields  : [],
        usersEmails   : []
    })

    const fetchTasks = async () => {
        setLoading(true)
        const response = await api.get(`api/users/${getSession().id}/tasks/?supervisor=${groups.isSupervisor}&month=${date.month}&year=${date.year}`)
        setLoading(false)
        if (response.status === 200) {
            setTimelineTasks(response.data)
            setCalendarTasks(response.data.map((item) => ({ 
                ...item,
                title : item.description,  
                start : new Date(item.target_date), 
                end   : new Date(item.target_date),
            })))
        }
    }

    useEffect(() => {
        fetchTasks()
    }, [date])

    useEffect(() => {
        const fetchContactData = async () => {
            setLoading(true)
            const [reqDialogNumbers, reqMetaNumbers, reqCustomFields, reqUsersEmails, reqMyTeam] = await Promise.all([
                api.get(`/api/IS/account/${getSession().profile.enterprise.accountcode}/senders/`),
                api.get(`/api/enterprises/${getSession().profile.enterprise.id}/whatsapp/`),
                api.get(`api/enterprises/${getSession().profile.enterprise.id}/custom_fields/`),
                api.get(`/api/enterprises/${getSession().profile.enterprise.id}/users/get_users_emails/`)
            ])
            setLoading(false)
            setProfileData({
                originNumbers : (reqDialogNumbers.status === 200 && reqMetaNumbers.status === 200) ? [...reqDialogNumbers.data, ...reqMetaNumbers.data].map((item) => item.phone_number) : [],
                customFields  : (reqCustomFields.status === 200) ? reqCustomFields.data : [],
                usersEmails   : (reqUsersEmails.status === 200) ? reqUsersEmails.data : []
            })

            if (groups.isSupervisor) {
                const response = await api.get(`api/users/${getSession().id}/myteam/`)
                if (response.status === 200)
                    setMyTeam(response.data[0].members_data)
            }
        }
        fetchContactData()
    }, [])

    const updateEvent = async (data) => {
        setLoading(true)
        const response = await api.patch(`api/enterprises/${getSession().profile.enterprise.id}/tasks/${data.id}/`, {
            ...data,
            target_date: dayjs(data.target_date).format("YYYY-MM-DDTHH:mm"),
            contact: data.contact?.id
        })
        setLoading(false)
        if (response.status === 200) {
            setTimelineTasks((prevTasks) => prevTasks.map((task) => task.id === response.data.id ? response.data : task))
            setCalendarTasks((prevTasks) =>
                prevTasks.map((task) =>
                    task.id === response.data.id
                        ? { 
                            ...response.data,
                            title: response.data.description,
                            start: response.data.target_date,
                            end: response.data.target_date
                        }
                        : task
                )
            )
            setSelectedEvent(null)
            await useNotification(
                "Sucesso",
                "Tarefa atualizada com sucesso.",
                "success"
            )
            return
        }
        await useNotification(
            "Ops",
            "Algo deu errado, tente novamente.",
            "danger"
        )
    }

    const createEvent = async (data) => {
        setLoading(true)
        const response = await api.post(`api/enterprises/${getSession().profile.enterprise.id}/tasks/`, {
            ...data,
            target_date: dayjs(data.target_date).format("YYYY-MM-DDTHH:mm"),
            contact: data.contact?.id,
            owner: getSession().id,
            status: "SCHEDULED",
            target: (! data.target) ? getSession().id : data.target
        })
        setLoading(false)
        if (response.status === 201) {
            setTimelineTasks((prevTasks) => [...prevTasks, response.data])
            setCalendarTasks((prevTasks) => [
                ...prevTasks,
                {
                    ...response.data,
                    title: response.data.description,
                    start: response.data.target_date,
                    end: response.data.target_date,
                }
            ])
            setSelectedEvent(null)
            await useNotification(
                "Sucesso",
                "Tarefa criada com sucesso.",
                "success"
            )
            return
        }
        await useNotification(
            "Ops",
            "Algo deu errado, tente novamente.",
            "danger"
        )
    }

	return (
        <>
            <Loading loading={loading} />
            {profile 
                ? (
                    <ScreenContext.Provider value={{ 
                        profile: profile,
                        templates: [],
                        queues: Object.fromEntries(getSession().profile.queues.map(queue => [queue.description, queue.name])),
                        clients: [profile],
                        originNumbers: profileData.originNumbers,
                        customFields: profileData.customFields,
                        enterpriseId: getSession().profile.enterprise.id,
                        setProfile: setProfile,
                        getClients: () => {},
                        usersEmails: profileData.usersEmails
                    }}>
                        <Box className="screen-wrapper">
                            <Box className="outer-Screen">
                                <IconButton onClick={() => setProfile(null)}><ArrowBackIcon /></IconButton>
                            </Box>
                            <Box className="table-screen">
                                <ContactProfile />
                            </Box>
                        </Box>
                    </ScreenContext.Provider>
                )
                : null
            }
    		<Box className="agenda-container">
    			<Box className="agenda-timeline">
    				<Box className="timeline-filters">
                        <FormControl fullWidth size="small">
                            <InputLabel>Data</InputLabel>
                            <Select label="Data" value={dateStatus} onChange={(event) => setDateStatus(event.target.value)}>
                                <MenuItem value="today">Hoje</MenuItem>
                                <MenuItem value="delayed">Atrasados</MenuItem>
                                <MenuItem value="all">Todos</MenuItem>
                            </Select>
                        </FormControl>
                    	{dateStatus !== "delayed" &&
                            <FormControl fullWidth size="small">
                                <InputLabel>Status</InputLabel>
                                <Select label="Status" value={status} onChange={(event) => setStatus(event.target.value)}>
                                    <MenuItem value="ALL">Todos</MenuItem>
                                    <MenuItem value="SCHEDULED">Agendados</MenuItem>
                                    <MenuItem value="COMPLETED">Finalizados</MenuItem>
                                    <MenuItem value="CANCELLED">Cancelados</MenuItem>
                                </Select>
                            </FormControl>
                    	}
                    </Box>
    				<Timeline position="right" className="timeline">
                        {timelineTasks
                            .filter(task => {
                                const taskDate = dayjs(task.target_date)
                                let dateCondition = true
                                if (dateStatus === "today")
                                    dateCondition = taskDate.isSame(dayjs(), "day")
                                if (dateStatus === "delayed")
                                    dateCondition = task.status === "SCHEDULED" && taskDate.isBefore(dayjs())
                                let statusCondition = true
                                if (status !== "ALL") 
                                    statusCondition = (task.status === status)
                                        ? true
                                        : (task.type === "BIRTHDAY" && status === "COMPLETED" && taskDate.isBefore(dayjs()) || task.type === "BIRTHDAY" && status === "SCHEDULED" && taskDate.isAfter(dayjs()))
                                return dateCondition && statusCondition
                            })
                            .sort((a, b) => Date.parse(b.target_date) - Date.parse(a.target_date))
                            .map((item, index) => (
                                <TimelineItem key={index}>
                                    <TimelineSeparator>
                                        <TimelineDot className="timeline-dot">
                                            {platformIcon[item.type]}}
                                        </TimelineDot>
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent className="timeline-content">
                                        <Typography variant="body1" className="timeline-content-title" onClick={() => setSelectedEvent(item)}>
                                            {item.description}
                                            {(item.type === "BIRTHDAY") 
                                                ? (dayjs(item.target_date).isBefore(dayjs().startOf("day"))) 
                                                    ? statusIcon["COMPLETED"] 
                                                    : statusIcon["SCHEDULED"]
                                                : statusIcon[item.status]
                                            }
                                        </Typography>
                                        <Typography variant="body2">{(item.type === "BIRTHDAY") ? dayjs(item.target_date).format("DD/MM/YYYY") : dayjs(item.target_date).format("DD/MM/YYYY HH:mm")}</Typography>
                                        <Typography variant="body2" className="timeline-description">
                                            <Box className="timeline-contact-target">
                                                {item.contact_data &&
                                                    <Box className="timeline-contact-data" onClick={() => setProfile(item.contact_data)}>
                                                        <PersonIcon sx={{ fontSize: "1rem" }}/> {item.contact_data.name}
                                                    </Box>
                                                }
                                                {item.target_data &&
                                                    <Box className="timeline-target-data">
                                                        <SupportAgentIcon sx={{ fontSize: "1rem" }}/> {item.target_data.name}
                                                    </Box>
                                                }
                                            </Box>
                                            {item.long_description}
                                        </Typography>
                                    </TimelineContent>
                                </TimelineItem>
                            ))
                        }
                    </Timeline>
    			</Box>
    			<Box className="agenda-calendar">
                    <Calendar
                        localizer={localizer}
                        events={calendarTasks}
                        startAccessor="start"
                        endAccessor="end"
                        defaultView="month"
                        messages={calendarTranslations}
                        style={{ width: "69vw", height: "84.25vh" }}
                        onSelectSlot={({start, end}) => setSelectedEvent({
                            target_date: start
                        })}
                        onSelectEvent={(event) => setSelectedEvent(event)}
                        selectable
                        onNavigate={(date, view) => {
                            const newYear = dayjs(date).year()
                            const newMonth = dayjs(date).month() + 1
                            if (newYear !== date.year || newMonth !== date.month)
                                setDate({ year: newYear, month: newMonth })
                        }}
                    />
    			</Box>
    		</Box>
            {selectedEvent &&
                <Event 
                    open={selectedEvent}
                    title={(selectedEvent.id) ? "Atualizar evento" : (selectedEvent.type === "BIRTHDAY") ? "Aniversário" : "Criar evento"}
                    handleClose={() => setSelectedEvent(null)}
                    handleSubmit={(data) => (selectedEvent.id) ? updateEvent(data) : createEvent(data)}
                    isSupervisor={groups.isSupervisor}
                    myTeam={myTeam}
                    selectedEvent={selectedEvent}
                    setProfile={setProfile}
                />
            }
        </>
	)
}

export default Agenda
