import React, { useState, useRef, useEffect, useMemo } from 'react'
import { Box, Grid, List, Divider, ListItem, ListItemButton } from "@mui/material";
import { CircularProgress } from "@mui/material";
import ConversationItem from '../../Chat/ConversationList/ConversationItem';

import { FixedSizeList } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer"
import Skeleton from '@mui/material/Skeleton';

const buildNotificationMessage = (notification) => {
    let template = JSON.parse(notification.template)
    let finalMessage = template.components.find(component => component.type === 'BODY').text
    let initialMessage = null
    let response = []
    let headerFormat =  template.components.find(component => component.type === 'HEADER')?.format

    if (notification.parameters !== null) {
        let parameters = JSON.parse(notification.parameters);
        if (typeof parameters === 'string' || parameters instanceof String)
            // Reparse JSON so it works
            parameters = JSON.parse(parameters);

        let bodyparameters = parameters.find(each => each.body)
        if (bodyparameters?.body?.length > 0) {
            bodyparameters = bodyparameters.body;
        }

        bodyparameters?.forEach((parameter) => {
            finalMessage = finalMessage.replace(/\{\{\d*\}\}/, parameter)
        })

        let parametersHead = parameters.find(parameter => parameter.headerfile)
        if (parametersHead) {
            switch(headerFormat){
                case 'IMAGE':
                    initialMessage = `[img]${parametersHead.headerfile}[/img]`
                    break
                case 'VIDEO':
                    initialMessage = `[video]${parametersHead.headerfile}[/video]`
                    break
                case 'DOCUMENT':
                    initialMessage = `[url=${parametersHead.headerfile}][/url]`
                    break
                default:
                    initialMessage = parametersHead.headerbody
            }
            
        }

    }
    
    let time = new Date(notification.processed_at)
    let final_time = (new Date(time - 3 * 60 * 60 * 1000)).toLocaleTimeString(
        [], { hour: '2-digit', minute: '2-digit', hour12: false })


    if (initialMessage)
        response.push(
            {
                message: initialMessage,
                user_id: -1,
                is_read: true,
                iso_time: final_time
            }
        )
    if (finalMessage)
        response.push(
            {
                message: finalMessage,
                user_id: -1,
                is_read: true,
                iso_time: final_time
            }
        )
    return response
}

const styles = {
    row: {
        width: '100%',
        backgroundColor: '',
        maxHeight: '65vh',
        overflow: 'auto',
        padding: 0,
        position: '',
        overflow: 'auto'
    },
};

const QueueContainer = ({ queueName, queueWhere, queueAmount, queueRow, onClickItem,
    fetching, limit, callData, queueAdded, listMode, isManageCalls,
    setReadMessages, activeChat, AsNotification, labels, connectEmailChat, sortComponent, regenerate }) => {

    const [loaded, setLoaded] = useState([])
    const [thisQueue, setThisQueue] = useState([])
    const [updating, setUpdating] = useState(false)
    const [offset, setOffset] = useState(0)

    const memolist =  [updating, fetching, activeChat]

    useEffect(()=>{
        if (regenerate)
            regenerate()
    }, [])

    useEffect(() => {
        if (queueRow.length !== 0){
            setThisQueue(queueRow)
        }
    }, [queueRow])

    const updateData = async (newOffset) => {
        if (loaded.includes(offset) || updating) return

        setUpdating(true)
        const signal = await callData(queueWhere, newOffset)
        if (signal) {
            setLoaded([...loaded, newOffset])
        }
        setUpdating(false)
    }

    useEffect(() => {
        updateData(offset)
    }, [offset])

    useEffect(() => {
        setLoaded([])
    }, [queueAdded])


    if (!updating && offset >= 0 && !loaded.includes(offset)) {
        updateData(offset)
    }

    

    const renderListItem = (props) => {
        const { index, style } = props;
        let difference = Math.abs(index % limit)

        if (!updating && difference === 0 && !loaded.includes(index)) {
            setOffset(index)
        }

        let chat = thisQueue.find(item => item.position === index)

        //Aux Variables
        if (chat) {
            chat.where = queueWhere
        }

        return (
            <>
                {
                    chat ?
                        <>
                            < ListItem style={style} key={`${chat.uniqueid}-key-list-${queueName}`} component="div" disablePadding >
                                <ListItemButton sx={{ padding: 0 }}>
                                    <Box key={`${chat.uniqueid}-key-on-${queueName}`} bgcolor='' sx={{ width: "100%", height: "100%" }}>
                                        <ConversationItem
                                            onClick={() => { onClickItem(chat); }}
                                            showProgressTime={isManageCalls || queueWhere != 'onchat' ? false : true}
                                            activeId={activeChat?.uniqueid}
                                            conversation={chat}
                                            isManageCalls={isManageCalls ? true : false}
                                            setReadMessages={!isManageCalls ? setReadMessages : () => { }}
                                            labels={labels}
                                            connectEmailChat={connectEmailChat}
                                        />
                                        <Divider />
                                    </Box>
                                </ListItemButton >
                            </ListItem>

                        </>
                        :
                        <>
                            < ListItem style={style} key={index} component="div" disablePadding >
                                <Box key={`${index}-key-on-queue`} bgcolor='' sx={{ width: "100%", height: "100%" }}>
                                    <Box className={'loader-skeleton'}
                                        sx={{ height: "100%", justifyContent: "center" }}
                                    >
                                        <CircularProgress />
                                    </Box>
                                    <Skeleton sx={{ height: "100%" }} variant="rectangular" animation="wave" />
                                    <Divider />

                                </Box>

                            </ListItem>
                        </>
                }
            </>

        )

    }


    const renderList = useMemo(() => {

        return (
            <>
                {!fetching ?
                    <AutoSizer >
                        {({ height, width }) =>
                        (
                            <>
                                <FixedSizeList
                                    height={height}
                                    width={width}
                                    itemSize={100}
                                    itemCount={queueAmount}
                                    overscanCount={5}
                                >
                                    {renderListItem}
                                </FixedSizeList>
                            </>
                        )}
                    </AutoSizer>
                    :
                    <Box id="conversationList-box-4">
                        <CircularProgress />
                    </Box>
                }
            </>
        )
    }, memolist)


    return (
        <>
            {listMode ?
                renderList
                :

                <Grid item lg={3} md={3} sm={3} xs={3} sx={{ minWidth: '25rem' }}>
                    <Box className="call-columns queue-chats" >
                        <Box className="column-header" >
                            <Box className="column-title">
                                {queueName} 
                                {<Box className={queueAmount < 100 ? 'queue-length length-small' : 'queue-length length-big'}>{queueAmount}</Box>} 
                                {sortComponent}
                            </Box>
                        </Box>
                        <Box className={'fit-tab'}>
                            {renderList}
                        </Box>
                    </Box>
                </Grid >
            }
        </>

    )
}

export default QueueContainer