import React from "react";
import { Box, Grid, CircularProgress } from "@mui/material";
import './styles.css';
import isMobile from "../../utils/isMobile";


class Loading extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isMobile: isMobile()
        }
    }

    render() {
        return (
            <>
                {this.props.loading &&
                    <div className={`loading-container ${this.props.nofixed ? 'nofixed':''}`}>
                        <Box className="loading">
                            <Grid container spacing={2}>
                                <Grid item lg={12} md={12} sm={12} xs={12} id="loading-grid">
                                    <CircularProgress id="refresh-icon" />
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    {this.props.message ? this.props.message : 'Carregando...'}
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                }
            </>
        );
    }
}



export default Loading;