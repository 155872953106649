import react, { useEffect, useLayoutEffect, useReducer } from 'react'
import { lockedReducer } from '../../../utils/defaultReducer'
import { Box, List, Button } from '@mui/material'
import api from '../../../api'
import HistoricRow from './historic_row'
import { getSession } from '../../../auth'
import dayjs from 'dayjs'

const LoadMore = async (contact, rows, cursor, setParent) => {
    setParent({ loading: true })
    const res = await api.get(`/api/enterprises/${contact.enterprise}/contacts/cursor/${contact.id}/${cursor}/`)
    const newstate = { loading: false }
    if (res.status === 200) {
        const [newrows, newcursor] = res.data
        newstate.historic = [[...rows, ...newrows], newcursor]
    }
    setParent(newstate)
}

const getScout = async (contact, setState) => {

    const res = await api.post(
      `api/enterprises/${contact.enterprise}/scout_accounts/customer_contacts/`,
      {
        phone_number: contact.telephone,
      },
    );
    if (res.status === 200) {
        setState({scoutRows: res.data.map(row => {
            const date = dayjs.unix(row.last_message_timestamp)
            return (
                {...row, platform_entrypoint: 'SCOUT', data:date.format('DD/MM/YYYY'), time:date.format('HH:mm:ss'), timestamp: row.last_message_timestamp}
            )
        })})
    }
    return [[], 0]
}


const HistoricComponent = ({ contact, data, setParent }) => {

    const [state, setState] = useReducer(lockedReducer, {
        activeDialog: null,
        scoutRows: []
    })

    const [rows, cursor] = data

    useLayoutEffect(()=>{
        const enterprise = getSession()?.profile?.enterprise
        if (enterprise?.platforms?.scout)
            getScout(contact, setState)
    }, [])

    const mergedRows = [...rows.map(row => ({...row, timestamp: dayjs(`${row.date}T${row.time}`).unix()})),
         ...state.scoutRows].toSorted((a, b) => b.timestamp - a.timestamp)

    return (
        <Box>
            {state.activeDialog}
            <List className='historic-list-gap'>
                {mergedRows.map(row => (
                    <HistoricRow key={`historic-unique-row-${row.id}`} data={row} contact={contact} setParent={setState}/>
                ))}
            </List>
            {(data[0].length > 0 && cursor !== -1) &&
                <Box className='historic-show-more'>
                    <Button onClick={() => LoadMore(contact, rows, cursor, setParent)}>Mostrar mais</Button>
                </Box>
            }
        </Box>
    )
}

export default HistoricComponent