import React, { createRef } from "react";

import {
  Button,
  Grid,
  TextField,
  InputLabel,
  OutlinedInput,
  Tooltip,
  Select,
  MenuItem,
  CircularProgress,
  IconButton,
  InputAdornment,
  FormControl,
  Autocomplete,
  ListItem,
  ListItemText,
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  Divider,
  Box,
  FormLabel
} from "@mui/material"

import LoadingButton from "@mui/lab/LoadingButton"

import { Save } from '@mui/icons-material';

import api from '../../api';
import { getSession } from "../../auth"
import Typography from "../Typography";
import OutsideClickHandler from 'react-outside-click-handler';
import "./styles.css";

import useNotification from "../Notification";
import useValidationError from '../ErrorValidation';
import ClearIcon from "@mui/icons-material/Clear";

import { CpfMask, CnpjMask } from "../../utils/masks"

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ptBR as localePTBR } from '@mui/x-date-pickers/locales';

import { number2dayweek } from '../Report/NewDetailedRegister/detailed_register_leftmenu'

import dayjs from 'dayjs';

const default_timezone = "America/Sao_Paulo"

import "./styles.css"

class ChangeField extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      showChangeField        : false,
      inputValue             : props.value,
      loading                : false,
      inputText              : "",
      createEnterpriseDialog : false,
      newEnterpriseName      : "",
      newEnterpriseCnpj      : "",
      datePickerOpen         : false
    };

    this.autocompleteRef = createRef()
  }

  fetchUpdate = async (value = null) => {
    this.setState({ loading: true });

    const dataField = (value !== null) 
      ? value 
      : (this.props.date)
        ? null
        : document.getElementById(this.props.field).value
    ;
    
    let body = {};

    if (this.props.customField) {
      body.update_custom_fields = this.props.customField;
      const updated_field = body.update_custom_fields.find(field => field.custom_field_name === this.props.label)
      if (updated_field)
        updated_field.value = dataField
      else {
        body.create_custom_fields = [{ ...this.props.data?.custom_field, value: dataField, custom_field: this.props.data?.custom_field?.id }]
      }
    }
    else
      body[this.props.field] = dataField;

    await api.patch(this.props.router, body).then(async (res) => {
      if (res.status === 200) {

        if (body.create_custom_fields)
          Object.assign(this.props.customField, res.data.custom_fields)

        await this.setState({
          inputValue: dataField,
          showChangeField: false,
          loading: false
        });
        useNotification(
          'Sucesso!',
          'Contato alterado com sucesso.',
          'success'
        );

        this.props.updateField(dataField);

      } else if (res.status === 206) {
        useNotification(
          'Sucesso!',
          'Contato alterado com sucesso.',
          'success'
        );
        this.props.reloadContact(res.data);
      }
      else if (res.status === 400) {
        let message = 'Ocorreu um erro no sistema'
        if (res.data?.error?.non_field_errors?.pop() === 'The fields enterprise, email must make a unique set.') {
          const name = res.data.contact?.owner__first_name ? `${res.data.contact.owner__first_name} ${res.data.contact.owner__last_name}` : 'Público'
          message = `Já existe um contato cadastrado com esse e-mail nessa conta e pertence ao usuário ${name}.`
        }
        else if (res.data?.error?.pop() === 'Enter a valid email address.') {
          message = 'Utilize um e-mail válido.'
        }
        this.setState({ loading: false });
        useNotification('Ops!', message, 'danger');
      }
      else {
        this.setState({ loading: false });
        useValidationError(res.status, null);
        throw res;
      }
    }).catch((err) => {
      console.error(err);
    });
  }

  createEnterprise() {
    api.post(`api/enterprises/${getSession().profile.enterprise.id}/contact_enterprises/`, {
      name: this.state.newEnterpriseName,
      cnpj: this.state.newEnterpriseCnpj,
    }).then((res) => {
      if (res.status === 201) {
        this.props.fetchContactEnterprises()
        this.fetchUpdate(res.data["id"])
        this.setState({ createEnterpriseDialog: false })
      }
    }).catch((err) => { })
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.showChangeField && !prevState.showChangeField && this.props.field === "contact_enterprise") {
      setTimeout(() => {
        if (this.autocompleteRef.current) {
          this.autocompleteRef.current.focus()
        }
      }, 0)
    }
  }

  componentWillMount() {
    if (this.props.editmode)
      this.setState({ showChangeField: true })
  }

  decideDisplay = () => {
    if (this.props.options)
      return 'options'
    else if (this.props.contactEnterprises)
      return 'contactEnterprises'
    else if (this.props.date)
      return 'date'
    else
      return 'normal'
  }

  render() {
    let contactEnterpriseName = undefined
    if (this.props.contactEnterprises) {
      const contactEnterprise = this.props.contactEnterprises.find((enterprise) => enterprise.id == this.state.inputValue)
      contactEnterpriseName = contactEnterprise ? contactEnterprise.name : ""
      if (contactEnterpriseName && contactEnterprise.cnpj)
        contactEnterpriseName = `${contactEnterpriseName} ${contactEnterprise.cnpj}`
    }
    return (
      <div onClick={() => this.setState({ showChangeField: true })}>
        {(!this.state.showChangeField || this.props.disabledChange) &&
          <Typography
            text={this.state.inputValue
              ? contactEnterpriseName !== undefined
                ? contactEnterpriseName
                : this.state.inputValue
              : this.props.label}
            fontStyle={this.state.inputValue
              ? ""
              : "italic"}
            color={this.state.inputValue
              ? ""
              : "lightGray"}
          />
        }
        {this.state.showChangeField && !this.props.disabledChange && !this.state.createEnterpriseDialog &&
          <>
            {this.decideDisplay() === 'options' &&
              <OutsideClickHandler onOutsideClick={() => { this.setState({ showChangeField: false }); this.props.editmode ? this.props.editmode() : null }}>
                <FormControl  sx={{ width: 200 }} size="small">
                  <InputLabel sx={{background: 'white', padding: '0rem 0.25rem'}} shrink={true} id={`${this.props.field}-inputlabel-for-options`}>Opções</InputLabel>
                  <Select
                    id={this.props.field}
                    label={'Opções'}
                    labelId={`${this.props.field}-inputlabel-for-options`}
                    defaultValue={this.state.inputValue}
                    displayEmpty
                    MenuProps={{
                      disablePortal: true
                    }}
                    onChange={({ target }) => {
                      this.fetchUpdate(target.value);
                    }}
                  >
                    {Object.entries(this.props.options).map(([key, value]) => <MenuItem disablePortal value={key}>{value}</MenuItem>)}
                  </Select>
                </FormControl>
              </OutsideClickHandler>
            }
            {this.decideDisplay() === 'contactEnterprises' &&
              <FormControl sx={{ width: 200 }} size="small">
                <OutsideClickHandler onOutsideClick={() => { this.setState({ showChangeField: false }); this.props.editmode ? this.props.editmode() : null }}>
                  <Autocomplete
                    options={this.props.contactEnterprises}
                    disablePortal
                    getOptionLabel={(option) => option.name || ""}
                    renderOption={(props, option) => (
                    <li {...props}>
                      <div>
                        <div>{option.name}</div>
                        {option.cnpj && 
                          <div style={{ fontSize: "0.75rem", color: "#666" }}>
                            {option.cnpj}
                          </div>
                        }
                      </div>
                    </li>
                  )}
                  filterOptions={(options, { inputValue }) => {
                    return options.filter((option) => (option.name !== null && option.name.toLowerCase().includes(inputValue.toLowerCase())) || (option.cnpj && option.cnpj.includes(inputValue)))
                  }}
                  value={this.props.contactEnterprises.find(e => e.id === this.state.inputValue) || null}
                  onChange={(event, newValue) => {
                    this.setState({ inputValue: newValue ? newValue.id : "" });
                    if (newValue !== null)
                      this.fetchUpdate(newValue ? newValue.id : "");
                    
                  }}
                  clearText="Limpar"
                  onInputChange={(event, newInputValue) => {
                    this.setState({ inputText: newInputValue })
                  }}
                    renderInput={(params) => (
                      <TextField {...params} label={this.props.label} size="small" inputRef={this.autocompleteRef} />
                    )}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onBlur={() => { this.setState({ showChangeField: false }); this.props.editmode ? this.props.editmode() : null }}
                    noOptionsText={
                      <Button onClick={() => this.setState({ createEnterpriseDialog: true, newEnterpriseName: this.state.inputText })} variant="contained" sx={{ width: "100%" }}>
                        Criar Empresa
                      </Button>
                    }
                  />
                </OutsideClickHandler>
              </FormControl>
            }
            {this.decideDisplay() === 'date' &&
              <OutsideClickHandler onOutsideClick={() => { if (! this.state.datePickerOpen) { this.setState({ showChangeField: false }); this.props.editmode ? this.props.editmode() : null }}}>
                <LocalizationProvider sx={{ justifyContent: 'space-between' }} 
                  dateAdapter={AdapterDayjs} 
                  adapterLocale={'pt-br'} 
                  localeText={localePTBR.components.MuiLocalizationProvider.defaultProps.localeText}
                >
                  <Box className='automation-end-form-control' sx={{ justifyContent: 'space-between' }}>
                    <DemoContainer components={['DatePicker']}>
                      <DateTimePicker
                        sx={{ minWidth: "8rem" }}
                        views={["year", "month", "day"]}
                        format={"DD/MM/YYYY"}
                        timezone={default_timezone}
                        timeSteps={1}
                        slotProps={{ textField: { 
                          size: 'small', 
                          disabled: true,
                          InputProps: {
                            startAdornment: (
                              <InputAdornment position="start">
                                <ClearIcon onClick={() => {
                                  this.setState({ inputValue: null });
                                  this.fetchUpdate(null);
                                }} style={{ cursor: 'pointer' }} />
                              </InputAdornment>
                            )
                          }
                        }}}
                        value={this.state.inputValue ? dayjs(this.state.inputValue) : null}
                        onAccept={(event) => {
                          if (event) {
                            this.setState({ inputValue: dayjs(event).format("DD/MM/YYYY") });
                            this.fetchUpdate(dayjs(event).format("YYYY-MM-DDTHH:mm:ss"));
                          }
                        }}
                        onOpen={() => this.setState({ datePickerOpen: true })}
                        onClose={() => this.setState({ datePickerOpen: false })}
                        dayOfWeekFormatter={(n, date) => number2dayweek[date.$W]}
                      />
                    </DemoContainer>
                  </Box>
                </LocalizationProvider>
              </OutsideClickHandler>
            }
            {this.decideDisplay() === 'normal' &&
              <OutsideClickHandler onOutsideClick={() => { this.setState({ showChangeField: false }); this.props.editmode ? this.props.editmode() : null }}>
                <TextField
                  id={this.props.field}
                  label={this.props.label}
                  defaultValue={this.state.inputValue}
                  disabled={this.state.loading}
                  size="small"
                  type={(this.props.data?.custom_field && this.props.data?.custom_field?.field_type === 'integer') ? 'number': 'text'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title={`Salvar ${this.props.label.toLowerCase()}`} placement="bottom" disableInteractive>
                          <IconButton
                            onClick={() => this.fetchUpdate()}
                            edge="end"
                            size="small">
                            {this.state.loading ? <CircularProgress size={25} /> : <Save />}
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                    ...(this.props.cpfField && { inputComponent: CpfMask })
                  }}
                />
              </OutsideClickHandler>
            }
          </>
        }
        {this.state.createEnterpriseDialog &&
          <Dialog 
            open={this.state.createEnterpriseDialog} 
            onClose={() => this.setState({ createEnterpriseDialog: false })} 
            fullWidth
            maxWidth="xs"
            onClick={(e) => e.stopPropagation()}
          >
            <DialogTitle>Nova empresa</DialogTitle>
            <Divider />
            <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "1rem" }} >
              <TextField
                size="small"
                fullWidth
                label="Nome"
                value={this.state.newEnterpriseName}
                onChange={(event) => this.setState({ newEnterpriseName: event.target.value })}
              />
              <TextField
                size="small"
                fullWidth
                label="CNPJ"
                value={this.state.newEnterpriseCnpj}
                onChange={(event) => this.setState({ newEnterpriseCnpj: event.target.value })}
                InputProps={{ inputComponent: CnpjMask }}
              />
            </DialogContent>
            <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
              <LoadingButton onClick={() => this.setState({ createEnterpriseDialog: false })} color="primary">
                Cancelar
              </LoadingButton>
              <LoadingButton onClick={() => this.createEnterprise()} color="primary" disabled={! this.state.newEnterpriseName}>
                Salvar
              </LoadingButton>
            </DialogActions>
          </Dialog>
        }
      </div>
    );
  }
};

export default ChangeField;
