import react from 'react'
import { parseMessage, reactionInfo } from '../../Scout/Pages/ScoutMessageComponent'
import { Box, Tooltip, ListItem, List } from '@mui/material'
import dayjs from 'dayjs'
import { transformTimeData } from '../../Scout/Pages/ChatList'

const RenderDetail = ({ detail, data, contact }) => {
    return (
        <>
            {detail === 'uniqueid' &&
                <>
                    {data.uniqueid}
                </>
            }
            {detail === 'messages' &&
                <>
                    {data.messages}
                </>
            }
            {detail === 'inout' &&
                <>
                    {data.custom_field2_value}, {data.origin} → {data.destination}, {data.status}.
                </>
            }
            {detail === 'scout_last_message' &&
                <List className='scout-window-content-list reverse'>
                    {
                        data.messages.flatMap((message, index) => {
                            if (message.messageType === 'reactionMessage') return
                            return (
                            <ListItem disableGutters
                                key={`individual-message-id-${index}`}
                                className={`scout-window-content-item ${message.fromMe ? 'right' : 'left'} ${message.restricted == true ? 'restricted' : ''}`}>
                                <Box className='scout-conversation-box'>
                                    <span>{parseMessage(message, data.messages.filter(msg => msg.type && msg.protocolId === message.id), () => { })}</span>
                                    <Tooltip title={dayjs(message.timestamp * 1000).format('DD/MM/YYYY HH:mm')}>
                                        <Box className='scout-conversation-time'>{transformTimeData(message.timestamp)}
                                        </Box>
                                    </Tooltip>
                                    <Box className='scout-conversation-reaction'>
                                        {reactionInfo(
                                            data.messages.filter(msg => msg.messageType === 'reactionMessage' && msg.message.reactedMessageId == message.id),
                                            () => { }
                                        )}
                                    </Box>
                                </Box>
                            </ListItem>
                        )})

                    }
                </List>
            }

        </>
    )
}

export default RenderDetail