import React, { useEffect, useState, useContext } from "react"

import { IMaskInput } from "react-imask"

import { 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    Switch, 
    Grid, 
    Box, 
    Button, 
    Divider,
    DialogActions,
    TextField,
    FormHelperText,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@mui/material"

import LoadingButton from "@mui/lab/LoadingButton"

import api from "../../../../../api"
import Table from "../../../../Table"

import { ScreenContext } from "../../.."
import CloseIcon from "@mui/icons-material/Close"

import useNotification from "../../../../Notification"

import UserPermissions from "../../../../User/Permissions"

const EditGroupNameModal = (props) => {
    const { updateGroups, subsObj } = useContext(ScreenContext)
    const [enterpriseGroupName, updateEnterpriseGroupName] = useState("")
    const [enterpriseGroupDescription, updateEnterpriseGroupDescription] = useState("")
    const [usage, updateUsage] = useState("")
    const [owner, updateOwner] = useState("")

    const submit = async () => {
        const url = `/api/enterprises/${props.enterpriseId}/contact_group/${props.selectedGroup.id}/`
        const body = {
            name: enterpriseGroupName,
            description: enterpriseGroupDescription,
        }

        const response = await api.patch(url, body)

        if (response.status === 400) {
            let message = "Ocorreu um erro com a requisição."
            if ("non_field_errors" in response.data && response.data.non_field_errors)
                message = "Já existe um segmento com esse nome nessa empresa."
            useNotification(
                "Ops!",
                message,
                "danger"
            )
            return
        }

        if (response.status == 403) {
            useNotification(
                "Ops!",
                "Você não tem permissão para ver esse conteúdo",
                "danger"
            )
            return
        }
        updateGroups(prevState => subsObj(prevState, response.data))
        props.submit(response.data)
    }

    const getOwnerName = async (ownerId) => {
        const response = await api.get(`/api/users/${ownerId}/`)
        if (response.status === 200)
            return `${response.data["first_name"]} ${response.data["last_name"]}`
        return ""
    }

    useEffect(() => {
        updateEnterpriseGroupName(props.selectedGroup.name)
        updateEnterpriseGroupDescription(props.selectedGroup.description)
        updateUsage(props.selectedGroup.owner_id === null ? "CORPORATE" : "PERSONAL")
        const fetchOwnerName = async () => {
            const ownerName = await getOwnerName(props.selectedGroup.owner_id)
            updateOwner(ownerName)
        }
        fetchOwnerName()
    }, [props.selectedGroup])

    return (
        <div>
            {
                <Dialog keepMounted
                    open={props.open}
                    // onClose={props.handleClose}
                    aria-labelledby="customized-dialog-title"
                    disablePortal
                    disableEnforceFocus
                    sx={{position:"absolute"}}
                >
                    <DialogTitle id="customized-dialog-title" className={"group-flexbox"}>
                        <span> {props.title} </span> <Button sx={{ "minWidth": "2rem", "padding": "0" }} onClick={props.handleClose} ><CloseIcon /></Button>
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <>
                            <TextField
                                size="small"
                                label="Nome"
                                fullWidth
                                type="text"
                                variant="outlined"
                                className="push-top"
                                value={enterpriseGroupName}
                                InputLabelProps={{ shrink: true }}
                                onChange={(event) => { updateEnterpriseGroupName(event.target.value) }}

                            />
                            <TextField
                                size="small"
                                label="Descrição"
                                fullWidth
                                type="text"
                                variant="outlined"
                                className="push-top"
                                value={enterpriseGroupDescription}
                                InputLabelProps={{ shrink: true }}
                                onChange={(event) => { updateEnterpriseGroupDescription(event.target.value) }}

                            />
                            <FormControl fullWidth size="small" className="push-top">
                                <InputLabel id="usage-select-label">Uso</InputLabel>
                                <Select
                                    labelId="usage-select-label"
                                    id="usage-select"
                                    value={usage}
                                    defaultValue={usage}
                                    label="Uso"
                                    onChange={(event) => updateUsage(event.target.value)}
                                >
                                    <MenuItem value="CORPORATE" disabled={! UserPermissions().isAdmin && ! UserPermissions().isSupervisor}>Corporativo</MenuItem>
                                    <MenuItem value="PERSONAL">Pessoal</MenuItem>
                                </Select>
                            </FormControl>
                            {owner &&
                                <TextField 
                                    size="small"
                                    label="Dono"
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    className="push-top"
                                    value={owner}
                                    disabled
                                />
                            }
                        </>
                    </DialogContent>
                    <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                        <LoadingButton
                            onClick={props.handleClose}
                            color="primary"
                        >
                            Cancelar
                        </LoadingButton>
                        <LoadingButton
                            onClick={() => submit()}
                            color="primary"

                        >
                            {props.buttonText}
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            }
        </div>
    )
}

export default EditGroupNameModal
