import React from "react"

import "./styles.css"

import { 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    Button, 
    Divider,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    DialogActions,
    TextField
} from "@mui/material"

import LoadingButton from "@mui/lab/LoadingButton"

import api from "../../../../../api"
import useNotification from "../../../../Notification"

import CloseIcon from "@mui/icons-material/Close"

import UserPermissions from "../../../../User/Permissions"
import { getSession } from "../../../../../auth"

class AddEnterpriseGroupForm extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            name        : "",
            description : "",
            usage       : "",
            loading     : false
        }
    }

    submit = async () => {

        const promise1 = this.createEnterpriseGroup()
        const promise2 = this.props.submit()

        await Promise.all([promise1, promise2])
    }


    createEnterpriseGroup = () => {
        const selectionIds = this.props.selectionIds

        const body = {
            "name"        : this.state.name,
            "description" : this.state.description,
            "owner"       : (this.state.usage === "PERSONAL") ? getSession().id : null,
            "enterprises"     : selectionIds
        }

        this.postEnterpriseGroup(body)
    }

    postEnterpriseGroup = async (body) => {
        this.props.updateReady(false)
        const res = await api.post(`api/enterprises/${this.props.enterpriseId}/contact_group/`, body)

        if (res.status == 403) {
            useNotification(
                "Ops!",
                "Você não tem permissão para realizar essa ação.",
                "danger"
            )
        }

        if (res.status != 201) {
            let message = "Ocorreu um erro com a requisição."
            if ("non_field_errors" in res.data && res.data.non_field_errors)
                message = "Já existe um segmento com esse nome."
            useNotification(
                "Ops!",
                message,
                "danger"
            )
            this.props.updateReady(true)
            return
        }

        res.data.contacts = this.props.selectionIds.map(id => ({ contact: id }))

        this.props.updateGroups(prevState => this.props.subsObj(prevState, res.data))
        this.props.updateEnterprises(prevState => {
            prevState.filter(each => body.enterprises.includes(each.id))
                .map(each => (
                    each.groups = this.props.subsObj(each.groups, res.data)
                ))
            return prevState
        })
        this.setState({ name: "", description: "" })
        this.props.updateReady(true)
        this.props.updateForceReload(prevState => !prevState)
        this.setState({ loading: false })
        if (res.status === 201) {
            await this.props.handleClose()
            useNotification(
                "Sucesso!",
                "Segmento de empresas criado.",
                "success"
            )
            return
        }
        return res
    }

    changeEnterpriseGroup = () => {

        let name = ""
        let description = ""

        if (this.props.model) {
            name = this.props.model.name
            description = this.props.model.description
        }

        this.setState({
            name: name,
            description: description
        })
    }

    render() {
        return (
            <>
                <Dialog keepMounted
                    open={this.props.open}
                    // onClose={this.props.handleClose}
                    aria-labelledby="customized-dialog-title"
                    disablePortal
                    disableEnforceFocus
                    sx={{position:"absolute"}}
                >
                    <DialogTitle id="customized-dialog-title" className={"group-flexbox"}>
                        <span>{this.props.optionalTitle ? this.props.optionalTitle : "Criar novo segmento de empresas"}</span>
                        <Button sx={{ "minWidth": "2rem", "padding": "0" }} onClick={this.props.handleClose} >
                            <CloseIcon />
                        </Button>
                    </DialogTitle>
                    <Divider />
                    <DialogContent ref={instance => { this.content = instance }}>
                        <TextField
                            size="small"
                            label="Nome"
                            fullWidth
                            type="text"
                            variant="outlined"
                            value={this.state.name}
                            onChange={(event) => this.setState({
                                name: event.target.value
                            })}
                        />
                        <TextField
                            size="small"
                            label="Descrição"
                            fullWidth
                            type="text"
                            variant="outlined"
                            className="push-top"
                            value={this.state.description}
                            onChange={(event) => this.setState({
                                description: event.target.value
                            })}
                        />
                        <FormControl fullWidth size="small" className="push-top">
                            <InputLabel id="usage-select-label">Uso</InputLabel>
                            <Select
                                labelId="usage-select-label"
                                id="usage-select"
                                value={this.state.usage}
                                defaultValue={this.state.usage}
                                label="Uso"
                                onChange={(event) => this.setState({ usage: event.target.value })}
                            >
                                <MenuItem value="CORPORATE" disabled={! UserPermissions().isAdmin && ! UserPermissions().isSupervisor}>Corporativo</MenuItem>
                                <MenuItem value="PERSONAL">Pessoal</MenuItem>
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                        <LoadingButton
                            onClick={this.props.handleClose}
                            color="primary"
                            loading={this.state.loading}
                        >
                            {this.props.cancelMsg ? this.props.cancelMsg : "Cancelar"}
                        </LoadingButton>
                        <LoadingButton
                            onClick={() => this.submit()}
                            color="primary"
                            loading={this.state.loading}
                        >
                            Salvar
                        </LoadingButton>

                    </DialogActions>
                </Dialog>
            </>
        )
    }
}

export default AddEnterpriseGroupForm
