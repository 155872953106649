import React, { useContext, useEffect } from "react"

import MiniDrawer from "../../components/Drawer"
import Main from "../../components/Main"

import { QueueSocket } from "../../routes"

const MainPage = (socket) => {
    const {connections} = useContext(QueueSocket)
    return (
        <MiniDrawer child={<Main connections={connections}/>} socket={socket.socket} />
    )
}

export default MainPage
