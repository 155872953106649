import react, { useCallback, useContext, useEffect, useMemo, useReducer } from 'react'

import { Box, ListItemButton, Tooltip, List, ListItem, InputLabel, TextField, Button, IconButton } from '@mui/material'
import { ScreenContext } from '..'
import { lockedReducer } from '../../../utils/defaultReducer'
import AvatarName from '../../AvatarName'
import ChangeField from '../../ChangeField'
import dayjs from 'dayjs'
import './styles.css'
import useNotification from '../../Notification'
import api from '../../../api'
import { getSession } from '../../../auth'
import { GeneralData } from './aux_components'

import { WhatsApp, Sms, Email, Phone, Edit, Add } from '@mui/icons-material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined';
import NumbersOutlinedIcon from '@mui/icons-material/NumbersOutlined';
import RuleOutlinedIcon from '@mui/icons-material/RuleOutlined';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import CelebrationIcon from '@mui/icons-material/Celebration';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

import ProfileTab from './profiletab'

import { callContact } from '../TabTables/ClientsTable'
import Templates from '../Templates'
import SendEmail from '../TabButtons/SendMessage/SendEmail'
import SendSMS from '../TabButtons/SendMessage/SendSMS'
import GroupAutocomplete from '../../Autocomplete/Group'
import OutsideClickHandler from 'react-outside-click-handler'

const customFieldHash = {
    'string': <NotesOutlinedIcon />,
    'integer': <NumbersOutlinedIcon />,
    'boolean': <RuleOutlinedIcon />,

}

const generateDate = (value) => {
    const date = dayjs(value).utcOffset(-6).locale('pt-BR').format('MMMM DD, YYYY HH:mm')
    return date[0].toLocaleUpperCase() + date.slice(1, date.length)
}

const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value)
    useNotification(
        'Sucesso!',
        'Texto copiado',
        'success'
    )
}

const createChannels = (state, setState) => {
    return {
        'whatsapp': { name: 'Whatsapp', component: (<></>), icon: (<WhatsApp sx={{ color: '#34af23' }} />), permission: true, onClick: () => setState({ singleClientWhatsAppNotify: state.id }) },
        'sms': { name: 'SMS', component: (<></>), icon: (<Sms sx={{ color: '#757575' }} />), permission: true, onClick: () => setState({ singleClientSmsNotify: [state.telephone] }) },
        'email': { name: 'E-Mail', component: (<></>), icon: (<Email sx={{ color: '#757575' }} />), permission: true, onClick: () => setState({ singleClientEmailNotify: state.id }) },
        'phone': { name: 'Ligação', component: (<></>), icon: (<Phone />), permission: true, onClick: () => callContact(state.telephone) }
    }
}

const loadContact = async (id, setState) => {
    setState({ loading: true })
    const enterpriseId = getSession()?.profile.enterprise.id
    const [contact] = await Promise.all([
        api.get(`api/enterprises/${enterpriseId}/contacts/${id}/`)
    ])
    let result = { loading: false }
    if (contact.status === 200)
        result = { ...result, ...contact.data }
    setState(result)
}

const updateGroups = async (profile, state, setState) => {
    if (!state.groups_changed) {
        setState({ show_change_groups: false })
        return
    }
    setState({ loading: true })
    const enterpriseId = getSession()?.profile.enterprise.id
    const res = await api.patch(`api/enterprises/${enterpriseId}/contacts/${profile.id}/`, { groups: state.groups.map(group => group.id) })
    let result = { loading: false, groups_changed: false, show_change_groups: false }
    if (res.status === 200) {
        useNotification(
            'Sucesso!',
            'Segmentos atualizados!',
            'success'
        )
        result.groups = res.data.groups
        profile.groups = result.groups
        if ('reloadChat' in window)
            window.reloadChat()
    }

    setState(result)
}

const ContactProfile = () => {

    const { profile, templates, queues, clients, originNumbers, customFields, usersEmails } = useContext(ScreenContext)

    const [state, setState] = useReducer(lockedReducer, {
        contact_enterprise_name: '',
        loading: false,
        notification_opt_out: false,
        custom_fields: [],
        groups: [],
        ...profile,
        singleClientWhatsAppNotify: null,
        singleClientSmsNotify: null,
        singleClientEmailNotify: null,
        show_change_groups: false,
        groups_changed: false
    })

    const channels = useMemo(() => createChannels(state, setState), [state])
    const custom_field_hash = useMemo(() => Object.fromEntries(state.custom_fields.map(field => ([field.custom_field.id, field]))))

    useEffect(() => {
        loadContact(profile.id, setState)
    }, [profile])

    const users = useMemo(()=>({
        '': 'Público',
        ...Object.fromEntries(usersEmails.map((user) => ([user.id, `${user.first_name} ${user.last_name}`])))
    }), [usersEmails])

    return (
        <>
            {state.singleClientWhatsAppNotify &&
                <Templates
                    title="Enviar whatsapp ao contato"
                    open={true}
                    templates={templates}
                    queues={queues}
                    clients={clients}
                    selectionIds={[state.singleClientWhatsAppNotify]}
                    originNumbers={originNumbers}
                    handleClose={() => setState({ singleClientWhatsAppNotify: null })}
                    handlePostSuccess={() => { }}
                    positionFixed
                />
            }

            {state.singleClientEmailNotify &&
                <SendEmail
                    title="Enviar e-mail ao contato"
                    queues={queues}
                    clients={[state.singleClientEmailNotify]}
                    handleClose={() => setState({ singleClientEmailNotify: null })}
                    positionFixed
                />
            }

            {state.singleClientSmsNotify &&
                <SendSMS
                    queues={queues}
                    selectedNumberModel={state.singleClientSmsNotify}
                    handleClose={() => setState({ singleClientSmsNotify: null })}
                    positionFixed
                    title="Enviar SMS ao contato"
                />}


            <Box className='contactprofile-mainframe'>
                <Box className='contactprofile-left'>
                    <Box className="avatar-profile">
                        {state.name
                            ? <AvatarName name={state.name} size="big" />
                            : <AvatarName name="N" size="big" />
                        }
                    </Box>
                    <span className='contact-name'>
                        <GeneralData icon={<></>} name={''} data={state} editable='name' onClick={() => copyToClipboard(state.telephone)} reloadParent={() => setState({ ...state })} placeholder="Nome" />
                    </span >
                    <Box sx={{ fontSize: '.9em' }}>
                        {generateDate(state.created_at)}
                    </Box>
                    <List className='contact-channels' disablePadding>
                        {Object.entries(channels).map(([key, value]) => (
                            <Tooltip title={value.name}>
                                <ListItemButton disablePadding onClick={() => { 'onClick' in value ? value.onClick() : null }}>
                                    <ListItem disablePadding>
                                        {value.icon}
                                    </ListItem>
                                </ListItemButton>
                            </Tooltip>
                        ))}
                    </List>
                    <List className='contact-items' disablePadding>
                        {/* <GeneralData  icon={<InfoOutlinedIcon />} name={'Status'} data={state.active} /> */}
                        <GeneralData
                            icon={<MapsHomeWorkOutlinedIcon />}
                            name={'Empresa'}
                            placeholder={'Empresa'}
                            data={state}
                            editable='contact_enterprise'
                            namealias={state.contact_enterprise_name}
                            onClick={() => copyToClipboard(state.contact_enterprise_name)}
                            reloadParent={() => setState({ ...state })}
                        />
                        <GeneralData
                            icon={<SupportAgentIcon />}
                            name={'Dono'}
                            placeholder={'Público'}
                            data={state}
                            editable='owner'
                            namealias={state.owner_name}
                            options={users}
                            onClick={() => copyToClipboard(state.owner_name)}
                            reloadParent={() => setState({ ...state })}
                        />
                        <GeneralData icon={<CallOutlinedIcon />} name={'Telefone'} placeholder={'Telefone'} data={state} editable='telephone' onClick={() => copyToClipboard(state.telephone)} reloadParent={() => setState({ ...state })} />
                        <GeneralData icon={<EmailOutlinedIcon />} name={'E-mail'} data={state} placeholder={'E-mail'} editable='email' onClick={() => copyToClipboard(state.email)} reloadParent={() => setState({ ...state })} />
                        {/* <GeneralData  icon={<LocationOnOutlinedIcon />} name={'Endereço'} data={'Somewhere'} editable onClick={() => copyToClipboard(state.telephone)} reloadParent={()=>setState({...state})}/> */}
                        <GeneralData icon={<SendOutlinedIcon />} name={'Recebe Campanhas'} data={state} placeholder={'Sim/Não'} editable='notification_opt_out' options={{ 'false': 'Sim', 'true': 'Não' }} reloadParent={() => setState({ ...state })} />
                        <GeneralData icon={<SpeakerNotesOffIcon />} name={'Não perturbar'} data={state} placeholder={'Sim/Não'} editable='no_disturb' options={{ 'true': 'Sim', 'false': 'Não' }} reloadParent={() => setState({ ...state })} />
                        <GeneralData icon={<CelebrationIcon />} date={true} name={'Aniversário'} data={state} placeholder={'DD/MM/YYYY'} editable='birthday' reloadParent={() => setState({ ...state })} />
                        <GeneralData icon={<AssignmentIndIcon />} cpf={true} name={'CPF'} data={state} placeholder={'000.000.000-00'} editable='cpf' reloadParent={() => setState({ ...state })} />
                    </List>
                    {/* <Box className='contact-description'>
                    <InputLabel sx={{ fontWeight: 'bold' }}>Anotações</InputLabel>
                    <TextField
                        multiline
                        size='small'
                        rows={3}
                        value={state.description}
                        placeholder='Descreva o contato'
                        onChange={({ target }) => setState({ description: target.value })}
                        inputProps={{
                            maxLength: 5000
                        }}
                        InputProps={{
                            endAdornment: <Button className='contact-textarea-button' variant='contained'>Salvar</Button>
                        }}
                    />

                </Box> */}
                    {/* <Box className='contact-tasks'>
                    <InputLabel sx={{ fontWeight: 'bold' }}>Tarefa</InputLabel>
                    <TextField
                        multiline
                        minRows={1}
                        maxRows={3}
                        size='small'
                        placeholder='Nova tarefa'
                        value={state.task}
                        onChange={({ target }) => setState({ task: target.value })}
                        inputProps={{
                            maxLength: 1000
                        }}
                        InputProps={{
                            endAdornment: <Button className='contact-textarea-button' variant='contained'>Salvar</Button>
                        }}
                    />
                </Box> */}
                    <Box className='contact-groups'>
                        <Box className='flexrow'>
                            <InputLabel sx={{ fontWeight: 'bold' }}>Segmentos</InputLabel>
                            {!state.show_change_groups && <IconButton onClick={() => setState({ show_change_groups: true })} sx={{ padding: '.1rem', fontSize: '.8em' }}><Edit sx={{ fontSize: '2em' }} /></IconButton>}
                        </Box>
                        {state.show_change_groups ?
                            <OutsideClickHandler onOutsideClick={() => {
                                updateGroups(profile, state, setState)
                            }}>
                                <GroupAutocomplete selectedGroup={state.groups} onChange={(data) => setState({ groups: data, groups_changed: true })} disablePortal={true} multiple={true} />
                            </OutsideClickHandler>
                            :
                            <Box className='contact-groups-list'>
                                {state.groups.map(({ name }) => (
                                    <Box className="client-group-item">{name}</Box>
                                ))}
                            </Box>
                        }

                    </Box>
                    <Box className='custom-fields'>
                        <InputLabel className='field-label' sx={{ fontWeight: 'bold' }}>Campos customizados</InputLabel>
                        <List className='contact-items' disablePadding>
                            {customFields.map(field => (
                                <GeneralData icon={customFieldHash[field.field_type]}
                                    name={field.field_name}
                                    data={{ ...custom_field_hash[field.id], profileId: state.id, custom_field: field }}
                                    editable={'value'}
                                    placeholder={'Valor'}
                                    onClick={() => copyToClipboard(state.telephone)}
                                    reloadParent={() => setState({ ...state })}
                                    customField={state.custom_fields} />
                            ))}
                        </List>
                    </Box>
                </Box>
                <Box className='contactprofile-right'>
                    <ProfileTab contact={profile} />
                </Box>
            </Box>
        </>
    )
}


export default ContactProfile
