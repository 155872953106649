import React, { useMemo, useState, useEffect, useContext } from "react"

import { ScreenContext } from "../.."

import qs from "qs"

import {
    Box,
    Tooltip,
    Button,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemButton
} from "@mui/material"

import { useLocation, useHistory } from "react-router-dom"

import LoadingButton from '@mui/lab/LoadingButton'

import {
    DataGridPro,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    ptBR
} from "@mui/x-data-grid-pro"

import {
    createTheme,
    ThemeProvider
} from "@mui/material/styles"

import { ptBR as corePtBR } from "@mui/material/locale"

import AddCircleIcon from "@mui/icons-material/AddCircle"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import GroupsIcon from "@mui/icons-material/Groups"
import EmailIcon from "@mui/icons-material/Email"
import PhoneIcon from "@mui/icons-material/Phone"
import GroupAddIcon from "@mui/icons-material/GroupAdd"

import { stringOperators } from "../../../../utils/filterOperators"
import { getSession } from "../../../../auth"

import EnterpriseGroupButton from "./EnterpriseGroupButton"
import AddEnterpriseGroupForm from "./AddEnterpriseGroupForm"
import EditGroupNameModal from "./EditGroupNameModal"
import EditGroupEnterprisesModal from "./EditGroupEnterprisesModal"

import ContactEnterpriseDialog from "../../../Dialogs/ContactEnterprise"
import ConfirmationDialog from "../../../Dialogs/Confirmation"
import ConfirmationDialogCaptcha from "../../../Dialogs/ConfimationDialogCaptcha"
import MergeEnterpriseDialog from "../../../Dialogs/MergeEnterprise"
import ContactCard from "../../ContactCard"
import useNotification from "../../../Notification"
import Loading from "../../../Loading"

import Templates from "../../Templates"
import SendEmail from "../../TabButtons/SendMessage/SendEmail"

import api from "../../../../api"

const parseFilters = (filterModel, type = "object") => {
    let filterString = filterModel.items.map(each => {
        if (each.value)
            return (`&${each.columnField}__${each.operatorValue}=${each.value}`)
        if (each.operatorValue == "is_empty")
            return (`&${each.columnField}__${each.operatorValue}=true`)
    }).join("")
    if ("linkOperator" in filterModel && filterModel.linkOperator == "or")
        filterString += "&or=True"
    if (type == "object") {
        const filters = {}
        const params = new URLSearchParams(filterString.startsWith("&") ? filterString.slice(1) : filterString)
        for (const [key, value] of params.entries()) {
            if (filters.hasOwnProperty(key))
                filters[key] = Array.isArray(filters[key]) ? [...filters[key], value] : [filters[key], value]
            else
                filters[key] = value
        }
        return filters
    }
    return filterString
}

const parseSort = (sortModel, type = "object") => {
    const sortString = sortModel.map(each => (`&sort=${each.field}__${each.sort}`)).join('')
    if (type == "object")
        return Object.fromEntries(new URLSearchParams(sortString.startsWith("&") ? sortString.slice(1) : sortString).entries())
    return sortString
}

export const getEnterpriseColumns = (setSelectedEnterprise, setShowDeleteDialog, setShowContactEnterpriseDialog, updateSelectedTab, getClients, setFilterModel) => {

    const location = useLocation()
    const history = useHistory()

    return [
        { field: 'name', headerName: 'Nome', flex: 1, filterOperators: stringOperators },
        { field: 'social_reason', headerName: 'Razão Social', flex: 1, filterOperators: stringOperators },
        { field: 'telephone', headerName: 'Telefone', flex: 1, filterOperators: stringOperators },
        { field: 'email', headerName: 'E-mail', flex: 1, filterOperators: stringOperators },
        { field: 'cnpj', headerName: 'CNPJ', flex: 1, filterOperators: stringOperators },
        { field: 'groups', headerName: 'Segmentos', flex: 1, sortable: false, filterOperators: stringOperators,
            valueGetter: ({ value }) => ((value) ? value.map(each => each.name).join() : ""),
            renderCell: (params) => (
                params.row.groups
                    ? <>
                        {params.row.groups.map((group) =>
                            <Box onClick={() => setFilterModel({
                                items: [{
                                    columnField: "groups",
                                    operatorValue: "contains",
                                    value: String(group.name)
                                }],
                            })} className='client-group-item pointer'>
                                {group.name}
                            </Box>
                        )}
                    </>
                    : <></>
            )
        },

        { field: 'address', headerName: 'Endereço', flex: 1, hide: true, filterOperators: stringOperators },
        { field: 'address_number', headerName: 'Número', flex: 1, hide: true, filterOperators: stringOperators },
        { field: 'address_extra', headerName: 'Complemento', flex: 1, hide: true, filterOperators: stringOperators },
        { field: 'neighborhood', headerName: 'Bairro', flex: 1, hide: true, filterOperators: stringOperators },
        { field: 'city', headerName: 'Cidade', flex: 1, hide: true, filterOperators: stringOperators },
        { field: 'estate', headerName: 'Estado', flex: 1, hide: true, filterOperators: stringOperators },
        { field: 'postal_code', headerName: 'CEP', flex: 1, hide: true, filterOperators: stringOperators },
        { field: 'action', headerName: 'Ações', flex: .5, sortable: false, filterable: false,
            renderCell: (params) => (
                <Box>
                    <Tooltip title="Ver Contatos" interactive>
                        <Button className="action-button" onClick={() => {
                            let currentFilters = JSON.parse(localStorage.getItem('crmFilters'))
                            if (! currentFilters)
                                currentFilters = {
                                    "campaigns"     : {"items": []},
                                    "clients"       : {"items": []},
                                    "notifications" : {"items": []}
                                }
                            currentFilters["clients"]["items"] = [{
                                columnField: "contact_enterprise_name",
                                operatorValue: "equals",
                                value: params.row.name
                            }]
                            localStorage.setItem("crmFilters", JSON.stringify(currentFilters))
                            const urlParams = new URLSearchParams(location.search)
                            urlParams.set("selectedTab", "contacts")
                            history.push(`${location.pathname}?${urlParams.toString()}`)
                        }}>
                            <GroupsIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Editar" interactive>
                        <Button className="action-button" onClick={() => { setSelectedEnterprise(params.row); setShowContactEnterpriseDialog(true); }}>
                            <EditIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Excluir" interactive>
                        <Button className="action-button delete" onClick={() => { setSelectedEnterprise(params.row); setShowDeleteDialog(true); }}>
                            <DeleteIcon />
                        </Button>
                    </Tooltip>
                </Box>
            )
        }
    ]
}

const CustomToolbar = (setSelectedEnterprise, setShowContactEnterpriseDialog, setShowMassDeleteEnterpriseDialog, selectedEnterprises, checkShowMergeDialog, setShowAddEnterpriseGroup, setShowDeleteEnterpriseGroup, setSelectedEnterpriseGroup, setShowUpdateEnterpriseGroup, addSelectedEnterprisesToGroup, setFilterModel, setShowGroupEnterprises, loadMoreGroups) => {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <LoadingButton component="label" startIcon={<AddCircleIcon />} onClick={() => {
                setSelectedEnterprise(null)
                setShowContactEnterpriseDialog(true)
            }} size="small">Adicionar</LoadingButton>
            <EnterpriseGroupButton
                addEnterpriseGroup={() => setShowAddEnterpriseGroup(true)}
                deleteEnterpriseGroupModal={(value) => {
                    setSelectedEnterpriseGroup(value)
                    setShowDeleteEnterpriseGroup(true)
                }}
                editEnterpriseGroupModal={(value) => {
                    setSelectedEnterpriseGroup(value)
                    setShowUpdateEnterpriseGroup(true)
                }}
                selectedEnterpriseGroup={(value) => {
                    setSelectedEnterpriseGroup(value)
                    setShowGroupEnterprises(true)
                }}
                filterOnClick={(groupName) => {
                    setFilterModel({
                        items: [{
                            columnField: "groups",
                            operatorValue: "contains",
                            value: String(groupName)
                        }],
                    })
                }}
                selectedEnterprises={selectedEnterprises}
                addSelectedEnterprisesToGroup={addSelectedEnterprisesToGroup}
                loadMoreGroups={loadMoreGroups}
            />
            <LoadingButton component="label" startIcon={<GroupAddIcon />} onClick={() => {
                checkShowMergeDialog()
            }} size="small">Mesclar</LoadingButton>
            <LoadingButton component="label" startIcon={<DeleteIcon />} onClick={() => {
                if (selectedEnterprises.length === 0) {
                    useNotification(
                        "Atenção!",
                        "Seleciona uma ou mais empresas para excluir.",
                        "warning"
                    )
                    return
                }
                setShowMassDeleteEnterpriseDialog(true)
            }} size="small">Excluir</LoadingButton>
        </GridToolbarContainer>
    )
}

const theme = createTheme({
    palette: {
        primary: { main: "#21446C" },
    },
}, ptBR, corePtBR);

const EnterpriseTable = ({ updateSelectedTab, getClients, loadMoreGroups }) => {

    const { subsObj, updateGroups, rmvObj, groups, setProfile, templates, queues, clients, originNumbers, profile } = useContext(ScreenContext)

    const [selectedEnterprise, setSelectedEnterprise] = useState()
    const [showContactEnterpriseDialog, setShowContactEnterpriseDialog] = useState(false)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [showMassDeleteEnterpriseDialog, setShowMassDeleteEnterpriseDialog] = useState(false)
    const [selectedEnterprises, updateSelectedEnterprises] = useState([])
    const [enterprises, setEnterprises] = useState([])
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(25)
    const [rowCount, setRowCount] = useState(0)
    const [sortModel, setSortModel] = useState([])
    const [filterModel, setFilterModel] = useState((JSON.parse(localStorage.getItem('crmEnterpriseFilters'))) ? JSON.parse(localStorage.getItem('crmEnterpriseFilters')) : { items: [] })
    const [loading, setLoading] = useState(false)
    const [showMergeDialog, setShowMergeDialog] = useState(false)
    const [showAddEnterpriseGroup, setShowAddEnterpriseGroup] = useState(false)
    const [showDeleteEnterpriseGroup, setShowDeleteEnterpriseGroup] = useState(false)
    const [selectedEnterpriseGroup, setSelectedEnterpriseGroup] = useState([])
    const [showUpdateEnterpriseGroup, setShowUpdateEnterpriseGroup] = useState(false)
    const [showGroupEnterprises, setShowGroupEnterprises] = useState(false)
    const [selectedContact, setSelectedContact] = useState()
    const [deleteContactDialog, showDeleteContactDialog] = useState(false)
    const [singleClientWhatsAppNotify, setSingleClientWhatsAppNotify] = useState(null)
    const [singleClientEmailNotify, setSingleClientEmailNotify] = useState(null)

    const checkShowMergeDialog = () => {
        if (selectedEnterprises.length !== 2) {
            useNotification(
                'Atenção!',
                'Selecione 2 empresas na tabela para mesclar!',
                'warning'
            );
            return
        }
        setShowMergeDialog(true)
    }

    const deleteEnterprises = async (ids) => {
        const response = await api.post(`api/enterprises/${getSession().profile.enterprise.id}/contact_enterprises/bulk_delete/`, {
            ids: ids
        })
        if (response.status === 204) {
            setEnterprises((prevEnterprises) => prevEnterprises.filter(enterprise => !ids.includes(enterprise.id)))
            setShowMassDeleteEnterpriseDialog(false)
            useNotification(
                "Sucesso!",
                "Empresas excluídas com sucesso.",
                "success"
            )
            return
        }

        useNotification(
            "Atenção!",
            "Uma ou mais empresas estão relacionadas com contatos, portanto não podem ser excluídas.",
            "warning"
        )
    }

    const deleteEnterprise = async (id) => {
        const response = await api.delete(`api/enterprises/${getSession().profile.enterprise.id}/contact_enterprises/${id}/`)
        if (response.status === 204) {
            setEnterprises((prevEnterprises) => prevEnterprises.filter(enterprise => enterprise.id !== id))
            setShowDeleteDialog(false)
            useNotification(
                "Sucesso!",
                "Empresa excluída com sucesso.",
                "success"
            )
            return
        }

        useNotification(
            "Atenção!",
            "Esta empresa está relacionada com um ou mais contatos, portanto não pode ser excluída.",
            "warning"
        )
    }

    const createEnterprise = async (data) => {

        const contactGroups = data.selectedGroups

        // Parent is sending the full state
        delete data.groupsSelectOpen
        delete data.contactGroups
        delete data.groupsLoading
        delete data.selectedGroups
        delete data.nextGroupsPointer

        data["contactGroups"] = contactGroups

        data = Object.keys(data).reduce((acc, key) => {
            const snakeKey = key.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase()
            acc[snakeKey] = data[key]
            return acc
        }, {})
        const response = await api.post(`api/enterprises/${getSession().profile.enterprise.id}/contact_enterprises/`, data)

        if (response.status === 201) {
            setEnterprises((prevEnterprises) => [
                response.data,
                ...prevEnterprises
            ])
            setShowContactEnterpriseDialog(false)
            useNotification(
                "Sucesso!",
                `Empresa criada com sucesso.`,
                "success"
            )
            return
        }

        useNotification(
            "Ops!",
            `Algo deu errado, tente novamente.`,
            "danger"
        )
    }

    const updateEnterprise = async (data) => {

        const contactGroups = data.selectedGroups

        // Parent is sending the full state
        delete data.groupsSelectOpen
        delete data.contactGroups
        delete data.groupsLoading
        delete data.selectedGroups
        delete data.nextGroupsPointer

        data["contactGroups"] = contactGroups

        data = Object.keys(data).reduce((acc, key) => {
            const snakeKey = key.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase()
            acc[snakeKey] = data[key]
            return acc
        }, {})
        const response = await api.patch(`api/enterprises/${getSession().profile.enterprise.id}/contact_enterprises/${data.id}/`, data)

        if (response.status === 200) {
            setEnterprises((prevEnterprises) =>
                prevEnterprises.map((enterprise) =>
                    enterprise.id === data.id ? { ...enterprise, ...response.data } : enterprise
                )
            )
            setShowContactEnterpriseDialog(false)
            useNotification(
                "Sucesso!",
                `Empresa atualizada com sucesso.`,
                "success"
            )
            return
        }

        if (response.status === 400) {
            useNotification(
                "Atenção!",
                "Já existe um empresa com esse nome.",
                "warning"
            )
            return
        }

        useNotification(
            "Ops!",
            "Algo deu errado, tente novamente.",
            "danger"
        )
    }

    const columns = getEnterpriseColumns(setSelectedEnterprise, setShowDeleteDialog, setShowContactEnterpriseDialog, updateSelectedTab, getClients, setFilterModel)

    const fetchEnterprises = async () => {
        setLoading(true)
        const response = await api.get(`api/enterprises/${getSession().profile.enterprise.id}/contact_enterprises/paginated/`, {
            params: {
                ...{ page: page + 1, pageSize },
                ...parseFilters(filterModel),
                ...parseSort(sortModel)
            },
            paramsSerializer: params => qs.stringify(params, { arrayFormat: "repeat" })
        })
        setLoading(false)
        if (response.status === 200) {
            setRowCount(response.data.count)
            setEnterprises(response.data.results)
            return
        }

        useNotification(
            "Ops!",
            "Algo deu errado ao buscar as empresas.",
            "danger"
        )
    }


    const mergeSelectedEnterprises = async (enterprise1, enterprise2, finalEnterprise) => {

        // Switch all contacts from secondary contact enterprise to primary contact enterprise
        const update = await api.post(`api/enterprises/${getSession().profile.enterprise.id}/contact_enterprises/relocate_contacts/`, {

            primary_id   : finalEnterprise.id,
            secondary_id : (enterprise1.id === finalEnterprise.id) ? enterprise2.id : enterprise1.id
        })
        if (update.status !== 200) {
            useNotification(
                "Ops!",
                "Algo deu errado, tente novamente...",
                "danger"
            )
            return
        }
        
        // Remove secondary contact enterprise
        const toRemove = (enterprise1.id === finalEnterprise.id) ? enterprise2 : enterprise1
        const delete_ = await api.delete(`api/enterprises/${getSession().profile.enterprise.id}/contact_enterprises/${toRemove.id}/`)
        if (delete_.status !== 204) {
            useNotification(
                "Ops!",
                "Algo deu errado, tente novamente...",
                "danger"
            )
            return
        }
        setEnterprises((prevEnterprises) => prevEnterprises.filter(enterprise => enterprise.id !== toRemove.id))

        // Update primary contact enterprise
        const patch = await api.patch(`api/enterprises/${getSession().profile.enterprise.id}/contact_enterprises/${finalEnterprise.id}/`, finalEnterprise)
        if (![200, 205].includes(patch.status)) {
            useNotification(
                "Ops!",
                "Algo deu errado, tente novamente...",
                "danger"
            )
            return
        }
        setEnterprises((prevEnterprises) => prevEnterprises.map((enterprise) => enterprise.id === finalEnterprise.id ? { ...enterprise, ...patch.data } : enterprise))

        setShowMergeDialog(false)

        useNotification(
            "Sucesso!",
            "Empresas mescladas com sucesso!",
            "success"
        );
    }

    const deleteEnterpriseGroup = async () => {
        const response = await api.delete(`/api/enterprises/${getSession().profile.enterprise.id}/contact_group/${selectedEnterpriseGroup.id}/`)
        if (response.status == 403) {
            useNotification(
                "Ops!",
                "Você não tem permissão para excluir este segmento.",
                "danger"
            );
            return
        }

        updateGroups((prevState) => rmvObj(prevState, selectedEnterpriseGroup.id))
        setEnterprises((prevState) => prevState.map(each => {
            each.groups = each.groups.filter(group => group.id !== selectedEnterpriseGroup.id)
            return each
        }))

        useNotification(
            "Sucesso!",
            "Segmento excluído com sucesso!",
            "success"
        );
        setShowDeleteEnterpriseGroup(false)
    }

    const addSelectedEnterprisesToGroup = async (selectedGroup) => {
        if (selectedEnterprises.length === 0) {
            useNotification(
                "Atenção!",
                "Selecione ao menos um empresa.",
                "warning"
            )   
            return
        }
        const response = await api.post(`/api/enterprises/${getSession().profile.enterprise.id}/contact_enterprises/add_to_group/`, {
            group_id: selectedGroup.id,
            contact_enterprises: selectedEnterprises
        })
        if (response.status === 201) {
            setEnterprises((prevState) => prevState.map(each => {
                if (selectedEnterprises.includes(each.id)) {
                    const existingGroups = each.groups || []
                    if (! existingGroups.some(group => group.id === selectedGroup.id))
                        return { ...each, groups: [...existingGroups, selectedGroup] }
                }
                return each
            }))
            useNotification(
                "Sucesso!",
                "Empresas adicionados ao segmento com sucesso!",
                "success"
            )
            return
        }
        useNotification(
            "Ops!",
            "Algo deu errado, tente novamente!",
            "danger"
        )
    }

    useEffect(() => {
        fetchEnterprises()
        window.fetchEnterprises = fetchEnterprises
        return () => {
            window.fetchEnterprises = () => {}
        }
    }, [page, pageSize, sortModel, filterModel])

    const table = useMemo(() => {

        return (
            <Box sx={{ width: "100%", height: "100%" }}>
                {loading ? <Loading loading={true} /> : null}
                <ThemeProvider theme={theme}>
                    <DataGridPro
                        columns={columns}
                        rows={enterprises}
                        components={{ Toolbar: () => CustomToolbar(setSelectedEnterprise, setShowContactEnterpriseDialog, setShowMassDeleteEnterpriseDialog, selectedEnterprises, checkShowMergeDialog, setShowAddEnterpriseGroup, setShowDeleteEnterpriseGroup, setSelectedEnterpriseGroup, setShowUpdateEnterpriseGroup, addSelectedEnterprisesToGroup, setFilterModel, setShowGroupEnterprises, loadMoreGroups) }}
                        density={"compact"}
                        checkboxSelection
                        disableSelectionOnClick
                        onSelectionModelChange={(newSelection) => updateSelectedEnterprises(newSelection)}
                        rowCount={rowCount}
                        pagination
                        paginationMode="server"
                        sortingMode="server"
                        filterMode="server"
                        page={page}
                        pageSize={pageSize}
                        onPageChange={(newPage) => setPage(newPage)}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        sortModel={sortModel}
                        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                        filterModel={filterModel}
                        onFilterModelChange={(newFilterModel) => {
                            localStorage.setItem('crmEnterpriseFilters', JSON.stringify(newFilterModel))
                            setFilterModel(newFilterModel)
                        }}
                        getDetailPanelContent={(params) => (
                            <Box sx={{ backgroundColor: "#f5f5f5", padding: "0 .5rem" }}>
                                {(params.row.contacts && params.row.contacts.length > 0) ? (
                                    <List sx={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", gap: 1 }}>
                                        {params.row.contacts.map((contact, index) => (
                                            <ContactCard client={contact} 
                                                onClick={() => setProfile(contact)}
                                                onTelephoneClick={() => setSingleClientWhatsAppNotify([contact.id])}
                                                onEmailClick={() => setSingleClientEmailNotify([contact.id])}
                                                onRemoveClick={() => {
                                                    setSelectedContact(contact)
                                                    showDeleteContactDialog(true)
                                                }}
                                            />
                                        ))}
                                    </List>
                                ) : (
                                    <Typography variant="body2" color="textSecondary" sx={{ padding: 2 }}>
                                        Sem contatos.
                                    </Typography>
                                )}
                            </Box>
                        )}
                        getDetailPanelHeight={({ row }) => {
                            const contactsLength = Array.isArray(row.contacts) ? row.contacts.length : 0
                            const data = (contactsLength / window.innerWidth * 1000) / 3.125
                            return (data > 5 ? 5 : contactsLength ? Math.ceil(data) : .5 ) * 176
                        }}
                    />
                </ThemeProvider>
            </Box>)
    }, [enterprises, selectedEnterprises, showDeleteContactDialog, loading, profile])

    const deleteContact = async (contact) => {
        const response = await api.delete(`/api/enterprises/${getSession().profile.enterprise.id}/contacts/${contact.id}/`)
        if (response.status == 204) {
            useNotification(
                "Sucesso!",
                "Cliente removido com sucesso",
                "success"
            )
            setEnterprises((prevEnterprises) =>
                prevEnterprises.map((enterprise) => {
                    if (enterprise.id === contact.contact_enterprise) {
                        return {
                            ...enterprise,
                            contacts: enterprise.contacts.filter(c => c.id !== contact.id),
                        }
                    }
                    return enterprise
                })
            )
            return
        }
        useNotification(
            "Ops!",
            "Algo deu errado, tente novamente",
            "danger"
        )
    }

    return (
        <>
            {table}
            {showContactEnterpriseDialog &&
                <ContactEnterpriseDialog
                    open={showContactEnterpriseDialog}
                    handleClose={() => setShowContactEnterpriseDialog()}
                    createEnterprise={createEnterprise}
                    updateEnterprise={updateEnterprise}
                    selectedEnterprise={selectedEnterprise}
                />
            }
            {showDeleteDialog &&
                <ConfirmationDialogCaptcha
                    open={showDeleteDialog}
                    title="Confirmação"
                    description={
                        <>
                            <Box>
                                Para excluir a empresa <span style={{ 'fontWeight': 'bold' }}>{selectedEnterprise.name}</span>, digite: <span style={{ 'fontWeight': 'bold' }}>excluir</span> no campo abaixo.
                            </Box>
                        </>
                    }
                    handleClose={() => setShowDeleteDialog(false)}
                    handleSubmit={() => deleteEnterprise(selectedEnterprise.id)}
                    captcha={'excluir'}
                />
            }
            {showMassDeleteEnterpriseDialog &&
                <ConfirmationDialogCaptcha
                    open={showMassDeleteEnterpriseDialog}
                    title="Confirmação"
                    description={
                        <>
                            <Box>
                                Para excluir as empresas selecionadas, digite: <span style={{ 'fontWeight': 'bold' }}>excluir</span> no campo abaixo.
                            </Box>
                        </>
                    }
                    handleClose={() => setShowMassDeleteEnterpriseDialog(false)}
                    handleSubmit={() => deleteEnterprises(selectedEnterprises)}
                    captcha={'excluir'}
                />
            }
            {showMergeDialog &&
                <MergeEnterpriseDialog open={showMergeDialog}
                    enterprises={enterprises}
                    selectedEnterprises={selectedEnterprises}
                    handleClose={() => setShowMergeDialog(false)}
                    handleSubmit={mergeSelectedEnterprises}
                />
            }
            {showAddEnterpriseGroup &&
                <AddEnterpriseGroupForm
                    selectionIds={[]}
                    enterpriseId={getSession().profile.enterprise.id}
                    open={showAddEnterpriseGroup}
                    handleClose={() => setShowAddEnterpriseGroup(false)}
                    submit={() => { }} // Already handling action within component
                    updateEnterprises={() => { }}
                    updateGroups={updateGroups}
                    subsObj={subsObj}
                    enterprises={enterprises}
                    updateForceReload={() => { }}
                    updateReady={() => { }}
                />
            }
            {showDeleteEnterpriseGroup &&
                <ConfirmationDialog open={showDeleteEnterpriseGroup}
                    title="Confirmação"
                    description="Tem certeza que deseja excluir o segmento selecionado?"
                    handleClose={() => setShowDeleteEnterpriseGroup(false)}
                    handleSubmit={deleteEnterpriseGroup}
                />
            }
            {showUpdateEnterpriseGroup &&
                <EditGroupNameModal
                    open={showUpdateEnterpriseGroup}
                    title="Editar Segmento"
                    buttonText="Salvar"
                    groups={groups}
                    handleClose={() => setShowUpdateEnterpriseGroup(false)}
                    submit={(data) => {
                        setEnterprises(prevState =>
                            prevState.map(each => ({
                                ...each,
                                groups: each.groups.map(group =>
                                    group.id === data.id ? { ...group, ...data } : group
                                )
                            }))
                        )
                        setShowUpdateEnterpriseGroup(false)
                    }}
                    enterpriseId={getSession().profile.enterprise.id}
                    selectedGroup={selectedEnterpriseGroup}
                />
            }
            {showGroupEnterprises &&
                <EditGroupEnterprisesModal
                    open={showGroupEnterprises}
                    title="Editar Segmento"
                    buttonText="Salvar"
                    groups={groups}
                    handleClose={() => setShowGroupEnterprises(false)}
                    submit={() => {
                        fetchEnterprises()
                        setShowGroupEnterprises(false)
                    }}
                    enterprises={enterprises}
                    enterpriseId={getSession().profile.enterprise.id}
                    selectedGroup={selectedEnterpriseGroup}
    updateForceReload={() => { }}
                />
            }
            {deleteContactDialog &&
                <ConfirmationDialog open={deleteContactDialog}
                    title="Confirmação"
                    description={`Deseja deletar o cliente ${selectedContact.name ? selectedContact.name : selectedContact.telephone}?`}
                    handleClose={() => {
                        setSelectedContact(null)
                        showDeleteContactDialog(false) 
                    }}
                    handleSubmit={() => {
                        deleteContact(selectedContact)
                        setSelectedContact(null)
                        showDeleteContactDialog(false)
                    }}
                />
            }
            {singleClientWhatsAppNotify &&
                <Templates
                    title="Notificar contato"
                    open={singleClientWhatsAppNotify}
                    templates={templates}
                    queues={queues}
                    clients={clients}
                    selectionIds={[singleClientWhatsAppNotify]}
                    originNumbers={originNumbers}
                    handleClose={() => setSingleClientWhatsAppNotify(null)}
                    handlePostSuccess={() => {}}
                />
            }
            {singleClientEmailNotify &&
                <SendEmail
                    title="Enviar e-mail ao contato"
                    queues={queues}
                    clients={[singleClientEmailNotify]}
                    handleClose={() => setSingleClientEmailNotify(null)}
                />
            }
            {deleteContactDialog &&
                <ConfirmationDialog open={deleteContactDialog}
                    title="Confirmação"
                    description={`Deseja deletar o cliente ${selectedContact.name ? selectedContact.name : selectedContact.telephone}?`}
                    handleClose={() => {
                        setSelectedContact(null)
                        showDeleteContactDialog(false) 
                    }}
                    handleSubmit={() => {
                        deleteContact(selectedContact)
                        setSelectedContact(null)
                        showDeleteContactDialog(false)
                    }}
                />
            }
            {singleClientWhatsAppNotify &&
                <Templates
                    title="Notificar contato"
                    open={singleClientWhatsAppNotify}
                    templates={templates}
                    queues={queues}
                    clients={clients}
                    selectionIds={[singleClientWhatsAppNotify]}
                    originNumbers={originNumbers}
                    handleClose={() => setSingleClientWhatsAppNotify(null)}
                    handlePostSuccess={() => {}}
                />
            }
            {singleClientEmailNotify &&
                <SendEmail
                    title="Enviar e-mail ao contato"
                    queues={queues}
                    clients={[singleClientEmailNotify]}
                    handleClose={() => setSingleClientEmailNotify(null)}
                />
            }
        </>
    )
}

export default EnterpriseTable
