import react, { useEffect, useReducer } from 'react'
import { lockedReducer } from '../../../utils/defaultReducer'
import QueueContainer from '../QueueContainer'
import { Badge, Box, List, ListItemAvatar, ListItemButton, ListItemText, FormControl, InputLabel, Select, MenuItem, InputBase, IconButton, Popover, Button } from '@mui/material'
import TooltipIndicators from '../../Dash/Tooltip'
import Typography from '../../Typography'
import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid,
    Legend, ResponsiveContainer, PieChart, Pie, Sector
} from 'recharts';
import ChatsAnswered from '../../Report/NewDetailedRegister/Graphs/Views/chats_answered'
import NPSChart from '../../D3/NPSChart'
import CSATChart from '../../D3/CSATChart'
import Loading from '../../Loading'
import api from '../../../api'
import { getSession } from '../../../auth'
import AvatarName from '../../AvatarName'
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Tooltip } from '@mui/material'
import { convert_time } from '../../Report/NewDetailedRegister/Graphs/default-toolbar'
import { platformIcon } from '../../Report/NewDetailedRegister/contact_graph_exception'
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from '@mui/icons-material/Refresh';
import FilterIcon from '@mui/icons-material/FilterAlt';
import MultipleSelections from '../../Input/MultipleSelections';
import SettingsIcon from '@mui/icons-material/Settings';
import MyTeam from '../../MyTeam'

let timeout = 0
const timeoutinterval = 60 * 1000

const itemClicked = async () => {

}

const statusdict = {
    'LOGIN': { name: 'Ativo', status: 'success' },
    'LOGOFF': { name: 'Inativo', status: 'error' },
    'PAUSE': { name: 'Pausado', status: 'primary' },
    "PENDING_LOGOFF": { name: 'Logoff pendente', status: 'warning' }
}

const NOTIFICATIONS = ["EMAIL", "SMS", "WHATSAPP_ATIVO"]

const initialize = async (setState, filter) => {
    const enterpriseId = getSession().profile.enterprise.id
    setState({ loading: true })

    const querystring = new URLSearchParams(filter)

    const res = await api.get(`api/enterprises/${enterpriseId}/teams/real_time/?${querystring}`)
    const result = { loading: false }
    if (res.status === 200) {

        result.team = res.data.team
        result.team.forEach(t => { t.checked = false })
        result.csat_nps = res.data.csat_nps
        result.labels = res.data.labels
        result.new_contacts = res.data.new_contacts
        result.notifications = res.data.notifications

        result.team.all_ongoingchats = 0
        result.team.all_callok = 0
        result.team.all_notification = 0
        result.team.all_callnok = 0
        result.team.channels = {}
        result.team.TMA = 0
        result.team.TME = 0
        result.team.TMR = 0
        result.team.hastma = 0
        result.team.hastme = 0
        result.team.hastmr = 0
        result.team.map(member => {
            member.onchatlen = member.onchat.length
            member.notifications = 0
            member.callok = 0
            member.callnok = 0

            member.TMA = 0
            member.TME = 0
            member.TMR = 0

            member.reports.forEach(r => {
                if (NOTIFICATIONS.includes(r.platform_entrypoit))
                    member.notifications += 1
                else if (r.classification === "Atendida")
                    member.callok += 1
                else
                    member.callnok += 1

                member.TMR += (r.average_response_time || 0)
                member.TME += (r.duration || 0) - (r.queue_duration || 0)
                member.TMA += (r.queue_duration || 0)

                result.team.hastmr += r.average_response_time ? 1 : 0
                result.team.hastma += (r.duration || 0) - (r.queue_duration || 0) ? 1 : 0
                result.team.hastme += r.queue_duration ? 1 : 0

                result.team.channels[r.platform_entrypoint] = (result.team.channels[r.platform_entrypoint] || 0) + 1
            })

            result.team.TMR += member.TMR
            result.team.TMA += member.TMA
            result.team.TME += member.TME
            result.team.all_callok += member.callok
            result.team.all_notification += member.notifications
            result.team.all_callnok += member.callnok
            result.team.all_ongoingchats += member.onchatlen
        })
        result.team.TMR = result.team.TMR / (result.team.hastmr || 1)
        result.team.TMA = result.team.TMA / (result.team.hastma || 1)
        result.team.TME = result.team.TME / (result.team.hastme || 1)

    }

    setState(result)
}

const OperatorManageCalls = ({ backButton }) => {

    const [state, setState] = useReducer(lockedReducer, {
        team: [],
        labels: [],
        new_contacts: 0,
        csat_nps: {},
        notifications: 0,
        loading: false,
        filter: 'all',
        showPopOver: null,
        anchorEl: null
    })

    const [filter, setFilter] = useReducer(lockedReducer, {
        status: 'all',
        operators: []
    })

    useEffect(() => {
        initialize(setState, filter)

        timeout = setInterval(() => { initialize(setState, filter) }, timeoutinterval)
        return () => {
            clearInterval(timeout)
        }
    }, [])

    return (
        <Box className="manage-calls-content">
            <Loading loading={state.loading} />
            <Popover
                className={`popover-filter ${state.showPopOver === 'settings' ? 'managercalls' : ''}`}
                open={state.showPopOver}
                anchorEl={state.anchorEl}
                onClose={() => { setState({ showPopOver: null, anchorEl: null }) }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <>
                    {state.showPopOver === 'settings' &&
                        <Box className='settings-on-popover'>
                            <MyTeam refresh={() => {setState({ showPopOver: null, anchorEl: null }); initialize(setState, filter)}}/>
                        </Box>
                    }
                    {state.showPopOver === 'filter' && <Box className="manage-teams-filter-popover">
                        <Box className='manage-teams-menu-title' >Filtros</Box>
                        <Box>
                            <FormControl size='small' fullWidth>
                                <InputLabel id='set-status-on-manage-teams' >Status</InputLabel>
                                <Select
                                    value={filter.status}
                                    labelId={'set-status-on-manage-teams'}
                                    label='Status'
                                    onChange={({ target }) => setFilter({ status: target.value })}
                                >
                                    <MenuItem value={'all'}>Todos</MenuItem>
                                    {Object.entries(statusdict).map(([key, value]) => (
                                        <MenuItem value={key}>{value.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box>
                            <FormControl size='small' fullWidth>
                                <InputLabel id='set-operators-on-manage-teams' >Operadores</InputLabel>
                                <Select
                                    multiline
                                    multiple={true}
                                    value={filter.operators}
                                    labelId={'set-operators-on-manage-teams'}
                                    label='Operadores'
                                    onChange={({ target }) => setFilter({ operators: target.value })}
                                >
                                    {state.team.map(member => (
                                        <MenuItem value={member.id}>{member.first_name} {member.last_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Box>
                        <Box sx={{ alignSelf: 'end' }}>
                            <Button onClick={() => { setFilter({ operators: [], status: 'all' }) }}>
                                Limpar filtros
                            </Button>
                            <Button onClick={() => { setState({ showPopOver: null, anchorEl: null }); initialize(setState, filter) }}>
                                Aplicar
                            </Button>
                        </Box>
                    </Box>}
                </>
            </Popover>
            <Box className='manage-teams-content'>
                <Box className='manage-teams-filter'>
                    {backButton}
                    <Box className='manage-teams-menu-title' >Minha equipe</Box>
                    <Box className='manage-teams-space-icons'>
                        <Tooltip title="Configurar equipe" placement="bottom" disableInteractive>
                            <IconButton sx={{ mr: ".25rem" }}
                                color="primary"
                                aria-label="settings"
                                onClick={(event) => { setState({ showPopOver: 'settings', anchorEl: event.currentTarget }) }}
                                className="filter-icon"
                            >
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Atualizar" placement="bottom" disableInteractive>
                            <IconButton sx={{ mr: ".25rem" }}
                                color="primary"
                                aria-label="refresh"
                                onClick={(event) => { initialize(setState, filter) }}
                                className="filter-icon"
                            >
                                <RefreshIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Filtros" placement="bottom" disableInteractive>
                            <IconButton sx={{ mr: ".25rem" }}
                                color="primary"
                                aria-label="directions"
                                onClick={(event) => { setState({ showPopOver: 'filter', anchorEl: event.currentTarget }) }}
                                className="filter-icon"
                            >
                                <FilterIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
                <Box className='manage-calls-divider'></Box>
                <List disablePadding sx={{ overflow: 'auto' }}>
                    {state.team.flatMap(member => {
                        if (!member.filterin) return
                        return (
                            <ListItemButton className='manage-teams-team-buttom'>
                                <ListItemAvatar >
                                    <AvatarName
                                        name={member.first_name}
                                        size="medium"
                                    />
                                </ListItemAvatar>
                                <ListItemText className='manage-teams-team-text'>
                                    <Box>
                                        <Box>
                                            <Tooltip title={statusdict[member.agent.agentstatus]?.name || ''} placement="bottom" disableInteractive>
                                                <Badge color={statusdict[member.agent.agentstatus]?.status || 'error'} variant="dot" className='manage-teams-team-badge' >
                                                    {member.first_name} {member.last_name}
                                                </Badge>
                                            </Tooltip>
                                        </Box>

                                    </Box>
                                    <Box className='manage-teams-team-icons'>
                                        <Tooltip title="Chamadas em atendimento"><Box >{member.onchatlen || 0}<SupportAgentIcon className='onchat' /></Box></Tooltip>
                                        <Tooltip title="Chamadas concluídas"><Box >{member.callok || 0}<CheckCircleIcon className='finished' /></Box></Tooltip>
                                    </Box>
                                </ListItemText>
                            </ListItemButton>
                        )
                    })}
                </List>
            </Box>
            <Box className='manage-teams-dashboard'>
                <Box className='manage-teams-dashboard-container' >
                    <Box className='manage-teams-dashboard-box'>
                        <Box className='manage-teams-dashboard-title'>Atendimentos em andamento</Box>
                        <Box className='manage-teams-dashboard-value'>{state.team?.all_ongoingchats || 0}</Box>
                    </Box>
                    <Box className='manage-teams-dashboard-box'>
                        <Box className='manage-teams-dashboard-title'>Atendimentos realizados</Box>
                        <Box className='manage-teams-dashboard-value'>{state.team?.all_callok || 0}</Box>
                    </Box>
                    <Box className='manage-teams-dashboard-box'>
                        <Box className='manage-teams-dashboard-title'>Atendimentos perdidos</Box>
                        <Box className='manage-teams-dashboard-value'>{state.team?.all_callnok || 0}</Box>
                    </Box>
                    <Box className='manage-teams-dashboard-box'>
                        <Box className='manage-teams-dashboard-title'>Novos contatos</Box>
                        <Box className='manage-teams-dashboard-value'>{state.new_contacts || 0}</Box>
                    </Box>
                </Box>
                <Box className='manage-teams-dashboard-container' >
                    <Box className='manage-teams-dashboard-box'>
                        <Box className='manage-teams-dashboard-title'>Tarefas concluídas</Box>
                        <Box className='manage-teams-dashboard-value'>0</Box>
                    </Box>
                    <Box className='manage-teams-dashboard-box'>
                        <Box className='manage-teams-dashboard-title'>Tarefas atrasadas</Box>
                        <Box className='manage-teams-dashboard-value'>0</Box>
                    </Box>
                    <Box className='manage-teams-dashboard-box'>
                        <Box className='manage-teams-dashboard-title'>Tarefas pendentes</Box>
                        <Box className='manage-teams-dashboard-value'>0</Box>
                    </Box>
                    <Box className='manage-teams-dashboard-box'>
                        <Box className='manage-teams-dashboard-title'>Comunicações ativas</Box>
                        <Box className='manage-teams-dashboard-value'>{state.notifications || 0}</Box>
                    </Box>
                </Box>
                <Box className='manage-teams-dashboard-container' >
                    <Box className='manage-teams-dashboard-box'>
                        <Box className='manage-teams-dashboard-title'>Etiquetas </Box>
                        <Box className='manage-teams-dashboard-list'>{state.labels.toSorted((a, b) => b.label_amount > a.label_amount ? 1 : -1).map(label => (
                            <Box className='manage-teams-dashboard-listitem'>
                                {label.label_amount}
                                <Box className='label-item withpadding' sx={{ backgroundColor: `${label.label_color} !important` }}>
                                    {label.label_name}
                                </Box>
                            </Box>
                        ))}</Box>
                    </Box>
                    <Box className='manage-teams-dashboard-box'>
                        <Box className='manage-teams-dashboard-title'>Canais de atendimento</Box>
                        <Box className='manage-teams-dashboard-list'>{Object.entries(state.team.channels || {}).toSorted(([, a], [, b]) => b.label_amount > a.label_amount ? -1 : 1).map(([key, value]) => (
                            <Box className='manage-teams-dashboard-listitem'>{value} {platformIcon(key)}</Box>
                        ))}</Box>
                    </Box>
                    <Box className='manage-teams-dashboard-box graph'>
                        <CSATChart value={state.csat_nps.csat} />
                    </Box>
                    <Box className='manage-teams-dashboard-box graph'>
                        <NPSChart value={state.csat_nps.nps} />
                    </Box>
                </Box>
                <Box className='manage-teams-dashboard-container' >
                    <Box className='manage-teams-dashboard-box'>
                        <Box className='manage-teams-dashboard-title'>TMA</Box>
                        <Box className='manage-teams-dashboard-value'>{convert_time(state.team.TMA, 'v2')}</Box>
                    </Box>
                    <Box className='manage-teams-dashboard-box'>
                        <Box className='manage-teams-dashboard-title'>TME</Box>
                        <Box className='manage-teams-dashboard-value'>{convert_time(state.team.TME, 'v2')}</Box>
                    </Box>
                    <Box className='manage-teams-dashboard-box'>
                        <Box className='manage-teams-dashboard-title'>TMR</Box>
                        <Box className='manage-teams-dashboard-value'>{convert_time(state.team.TMR, 'v2')}</Box>
                    </Box>
                </Box>
            </Box>
        </Box >
    )
}

export default OperatorManageCalls