import React, { useEffect, useState } from 'react';
import ChatRightSideBar from '../../../Chat/ChatRightSideBar';
import { getSession } from '../../../../auth'
import api from '../../../../api'
 
const CRMSideBar = ({parent, setParent}) => {

    const [refresh, updateRefresh] = useState(false)
    const [allLabels, setAllLabels] = useState([])
    const [chatLabels, setChatLabels] = useState([])
    const [chatGroups, setChatGroups] = useState([])
    const [allGroups, setAllGroups] = useState([])
    const [contactData, setContactData] = useState(null)

    const account = parent.accounts.find(account => parent.activeChat.owner.endsWith(account.phone_number));

    const setRefresh = () => {
        updateRefresh(p => !p)
    }

    const updateChatGroups = (groups) => {
        setChatGroups(groups)
        setShowChatRightSideBar(false)
        setParent({showCRMSidebar: false})
    }

    const reloadContact = () => {
        // messageArea?.current?.children.reloadContact()
        console.log('TODO: reloadContact');
    }

    const fetchHistoryMessages = (row) => {
        api.get(`/api/LHC/chats/${row.hash}/`)
            .then((res) => {
                if (res.status == 200) {
                    let state = {
                        historyChat : res.data,
                        showChatHistory: true,
                        historyMessages: res.data.messages,
                        platform: res.data.platform,
                        uniqueId: row.uniqueid,
                        lastDateTime: res.data.time,
                        nick: contactData.nick,
                    };
                    setParent(state);
                    return;
                }
                throw res;
            })
            .catch((err) => {
                console.error(err);
            });
    }


    useEffect(async () => {
        if (parent.activeChat && parent.activeChat.kwik_contact_id) {
            const res = await api.get(`/api/enterprises/${getSession().profile.enterprise.id}/contacts/${parent.activeChat.kwik_contact_id}/`)
            if (res.status === 200) {
                setContactData(res.data)
                return
            } else if (res.status === 404){
                const res = await api.get(`/api/enterprises/${getSession().profile.enterprise.id}/scout_accounts/${account.id}/resync_contact/${parent.activeChat.id}/`)
                if (res.status === 200) {
                    parent.activeChat.kwik_contact_id = res.data.id
                    parent.activeChat.kwik_contact_name = res.data.name
                    setContactData(res.data)
                    return
                }
                    
            }
        }
        setParent({showCRMSidebar: false})
    }, [parent.activeChat])

    if (!contactData) {
        return <></>
    }

    return (<ChatRightSideBar 
        agentCanUpdateContact={true}
        contactData={contactData} 
        closeChatRightSideBar={
            () => setParent({showCRMSidebar: false})
        }
        updateField={setRefresh}
        isManageCalls={true}
        fetchHistoryMessages={fetchHistoryMessages}
        allLabels={allLabels}
        chatLabels={chatLabels}
        setChatLabels={setChatLabels}
        chat={{}}
        renderLabels={false}
        reloadContact={reloadContact}
        allGroups={allGroups}
        setChatGroups={updateChatGroups}
        parent={{templates: []}}
        fetchScoutMessages={(row) => {
            const activeChat = { account_id: account.id, ...row };
            setParent({
                activeAccount: account,
                activeChat: activeChat,
                showCRMSidebar: false,
                showSearch: false,
            });
        }}
    />);
}


export default CRMSideBar;
