import react from 'react'
import { Button, Tooltip } from '@mui/material'
import HistoryMessagesDialog from '../../Dialogs/HistoryMessagesDialog'
import { fetchHistoryMessages } from '../../Report/NewDetailedRegister/detailed_register_functions'
import ExportIcon from '@mui/icons-material/GetApp';
import ChatIcon from '@mui/icons-material/Chat';
import { RenderVoiceActions } from '../../Report/NewDetailedRegister/contact_graph_exception';

const RenderAction = ({ action, data, contact, setParent }) => {

    return (
        <>
            {action === 'see' &&
                <>
                    <Tooltip title="Visualizar histórico de mensagens" placement="top" disableInteractive>
                        <Button className={data.uniqueid
                            ? 'action-button'
                            : 'action-button is-transfer'
                        }
                            onClick={async () => {
                                const dialogData = await fetchHistoryMessages(data.uniqueid)
                                if (dialogData) {
                                    if (dialogData.messages.length === 0) {
                                        useNotification(
                                            'Atenção!',
                                            'Não há mensagens para exibir.',
                                            'info'
                                        )
                                        return
                                    }
                                    setParent({
                                        activeDialog: (
                                            <HistoryMessagesDialog open={true}
                                                activeChat={dialogData}
                                                uniqueId={dialogData.uniqueid}
                                                nick={dialogData.nick}
                                                lastDateTime={dialogData.time}
                                                historyMessages={dialogData.messages}
                                                platform={dialogData.platform}
                                                accountcode={dialogData.accountcode}
                                                isReport={true}
                                                handleClose={() => setParent({ activeDialog: null })}
                                                positionFixed
                                            />
                                        )
                                    })
                                }


                            }}
                        >
                            <ChatIcon />
                        </Button>
                    </Tooltip>
                </>
            }
            {action === 'download' &&
                <>
                    <Tooltip title="Exportar histórico de mensagens" placement="top" disableInteractive>
                        <Button className="action-button"
                            href={"/api/api/enterprises/"
                                + contact.enterprise
                                + "/reports/chat/detailed/download/"
                                + data.uniqueid
                                + "/"
                                + data.time
                                + "/"
                            }
                        >
                            <ExportIcon />
                        </Button>
                    </Tooltip>
                </>
            }
            {action === 'downloadvoice' &&
                <>
                    <RenderVoiceActions row={data} />
                </>
            }
            {action === 'movescout' &&
            <>
                <Tooltip title="Visualizar histórico de mensagens" placement="top" disableInteractive>
                    <Button className={data.uniqueid
                        ? 'action-button'
                        : 'action-button is-transfer'
                    }
                        onClick={() => {
                            const url = '/scout?account=' + data.owner + '&chat=' + data.id;
                            window.location = url;
                        }}
                    >
                        <ChatIcon />
                    </Button>
                </Tooltip>
            </>}

        </>
    )
}

export default RenderAction