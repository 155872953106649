import React, { Component } from 'react'

import { Box, IconButton } from '@mui/material';

import AudioReactRecorder, { RecordState } from 'audio-react-recorder'

import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import useNotification from '../Notification';

import api from '../../api';

class AudioRecorder extends Component {

    constructor(props) {
        super(props)
        this.state = {
            recordState: null,
            submitted: false,
            time: 0
        }
        this.timer = null;
    }

    start = () => {
        let that = this;
        navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
            if (stream.getAudioTracks().length > 0) {
                that.setState({
                    recordState: RecordState.START,
                    submitted: false
                });
                that.startTimer();
            }
        }).catch((err) => {
            console.error('Couldn\'t start recording [err:%o]', err);
        });
    }

    stop = async (action) => {
        this.setState({
            recordState: RecordState.STOP,
            submitted: (action == 'submit') ? true : false
        });
        this.stopTimer();

        if (action == 'submit') {
            if (this.props.isActiveChatOnQueue()) {
                await this.props.connectActiveChat();
            }
        }
    }

    onStop = (audio) => {
        if (!this.state.submitted) return;

        const formData = new FormData();
        formData.append('media', new File([audio.blob], 'audio'));
        formData.append('chat_hash', this.props.activeChat.channel);
        formData.append('queue_id', this.props.activeChat.queue.id);

        const chat = this.props.chat
        const queue_name = chat.queues_name ? chat.queues_name : chat.queue.name
        const channel = chat.channel ? chat.channel : chat.hash
        const connection = this.props.connections?.find(con => con.queue == queue_name)

        let newMessage = {
            "id": 0,
            "message": 'Enviando áudio',
            "iso_time": "--:--",
            "msg": 'SENDINGMEDIAMESSAGE',
        }

        this.props.addMessage(newMessage)

        api.post('/api/whatsapp/media/', formData, { timeout: 0 })
            .then(async (res) => {

                if (res.status !== 200) {
                    newMessage.id = -2
                    newMessage.expired = true
                    newMessage.message = 'Enviou falhou!'
                    newMessage.time = Math.floor(Date.now() / 1000)
                    this.props.addMessage()
                }

                if (res.status == 400) {
                    if (res.data == 'Session closed') {
                        await useNotification(
                            'Sessão encerrada!',
                            'Você não pode mais enviar mensagens para esta conversa',
                            'danger'
                        );
                    }
                    else if (res.data == 'Media type not allowed') {
                        await useNotification(
                            'Arquivo inválido!',
                            'Não é permitido o envio deste tipo de mídia',
                            'danger'
                        );
                    }
                    else if (res.data == 'Media size limit exceeded') {
                        await useNotification(
                            'Arquivo inválido!',
                            'O áudio excedeu o tempo máximo permitido de 2 minutos.',
                            'danger'
                        );
                    } else if (res.data == 'Sandbox environment cannot exchange media') {
                        await useNotification(
                            'Sandbox!',
                            'Não é pertimido o envio de mídia em ambiente sandbox',
                            'danger'
                        );
                    }
                    return;
                }

                if (res.status == 404) {
                    await useNotification(
                        'Chat não encontrado!',
                        'Este chat foi finalizado pelo sistema',
                        'danger'
                    );
                    return;
                }

                if (res.status == 200) {
                    Object.entries(res.data).map(([header, value]) => {
                        newMessage[header] = value
                    })
                    newMessage.time = Math.floor(Date.now() / 1000);

                    if (!this.isSupervised(this.props.activeChat.queue))
                        newMessage['message'] = newMessage['message']

                    this.props.activeChat.socket?.send(JSON.stringify(
                        {
                            'channel' : this.props.activeChat.channel,
                            'message' : JSON.stringify(res.data),
                            'user'    : -2
                        }))

                    this.props.addMessage()
                    this.props.scrollDown()
                    if (queue_name && connection && channel && 'chatstatus' in this.props.activeChat && this.props.activeChat.chatstatus == 'QUEUE')
                        connection.send(JSON.stringify({
                            message: "ONCHAT",
                            channel: channel
                        }))
                    return;
                }

                throw res;
            })
            .catch(async (err) => {
                console.error(err);
                await useNotification(
                    'Ops!',
                    'Ocorreu um erro no sistema',
                    'danger'
                );
            });
    }

    isSupervised = (queue) => {
        return 'settings' in this.props.activeChat.queue
            && this.props.activeChat.queue.settings
            && 'media_supervision' in this.props.activeChat.queue.settings
            && this.props.activeChat.queue.settings.media_supervision;
    }

    startTimer = () => {
        this.timer = setInterval(this.addSecond, 1000);
    }

    addSecond = () => {
        const time = this.state.time;
        this.setState({
            time: time + 1
        });
    }

    stopTimer = () => {
        clearInterval(this.timer);
        this.state.time = 0;
    }

    render() {

        const { recordState } = this.state

        return (
            <div>
                <Box style={{ display: "none" }}>
                    
                    <AudioReactRecorder state={recordState}
                        onStop={this.onStop}
                    />
                </Box>
                {this.state.recordState === RecordState.START &&
                    <Box display="flex" sx={{position:'relative'}}>
                        <Box className='audio-baloon-message'>Limite máximo de 2 minutos.</Box>
                        <IconButton onClick={() => this.stop('cancel')} size="large">
                            <CloseIcon color="secondary" />
                        </IconButton>
                        <div style={{ marginTop: ".6rem" }}>
                            {new Date(this.state.time * 1000).toISOString().substr(14, 5)}
                        </div>
                        <IconButton onClick={() => this.stop('submit')} size="large">
                            <CheckIcon color="primary" />
                        </IconButton>
                    </Box>
                }
                {this.state.recordState !== RecordState.START &&
                    <IconButton onClick={() => this.start()} size="large">
                        <MicIcon color="primary" />
                    </IconButton>
                }
            </div>
        );
    }
}

export default AudioRecorder;
