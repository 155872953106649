import React, { useState, useEffect, useReducer } from "react";

import {
    Box,
    IconButton,
    Tooltip,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Divider,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@mui/material";

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SaveIcon from '@mui/icons-material/Save'

import Profile from './Profile';
import LabelArea from './LabelArea';
import GroupArea from './GroupArea';

import History from "./History";
import NewHistory from "./History/newhistory";

import { getSession } from "../../../auth";
import api from "../../../api";
import useNotification from '../../Notification';
import ScoutContactHistory from "../../Scout/Pages/ScoutContactHistory";
import { ScreenContext } from "../../Crm";
import ContactProfile from "../../Crm/ContactProfile";

import "./styles.css";
import { lockedReducer } from "../../../utils/defaultReducer";

import KwikAssistant from "../../KwikAssistant"

const getOriginNumbers = async () => {

    const enterprise = getSession().profile.enterprise;

    let numbers = []

    await api.get(`/api/IS/account/${enterprise.accountcode}/senders/`, { timeout: 0 })
        .then((res) => {
            if (res.status == 200) {
                numbers = res.data
                return true;
            }
            throw res;
        })
        .catch((err) => {
            console.error(err);
        });

    // Meta numbers
    const response = await api.get(`/api/enterprises/${enterprise.id}/whatsapp/`)
    if (response.status === 200) {
        const metaNumbers = []
        response.data.forEach((each) => {
            metaNumbers.push(each.phone_number)
        })
        numbers = [...numbers, ...metaNumbers]
    }

    return numbers
}

const queues = () => {
    const queues_set = getSession().profile.queues;
    if (queues_set) {
        let user_queues = {};
        queues_set.map(queue => {
            user_queues[queue.description] = queue.name;
        });
        return user_queues;
    }
}

export default function ChatRightSideBar({
    closeChatRightSideBar,
    isManageCalls,
    contactData,
    updateField,
    fetchHistoryMessages,
    agentCanUpdateContact,
    allLabels,
    chatLabels,
    setChatLabels,
    chat,
    reloadContact,
    chatCursor,
    allGroups,
    setChatGroups,
    fetchScoutMessages,
    parent,
    profileContent="contact_data"
}) {

    const enterprise = getSession().profile.enterprise;

    const [state, setState] = useReducer(lockedReducer, {
        custom_fields: [],
        origin_numbers: [],
        usersEmails: []
    })

    useEffect(async () => {
        const [originNumbers, customFields, usersemails] = await Promise.all([
            getOriginNumbers(), 
            api.get(`api/enterprises/${enterprise.id}/custom_fields/`),
            api.get(`/api/enterprises/${enterprise.id}/users/get_users_emails/`)])
        const newstate = {}

        newstate.origin_numbers = originNumbers
        if (customFields.status === 200)
            newstate.custom_fields = customFields.data
        if (usersemails.status === 200)
            newstate.usersEmails = usersemails.data
        setState(newstate)
    }, [])

    return (
        <Box className="chat-right-sidebar" style={{ height: isManageCalls ? '100%' : '100%' }}>
            {profileContent === "contact_data" &&
                <>
                    <Accordion expanded={true} className='accordion'>
                        <AccordionSummary className='accordionHeader' onClick={() => closeChatRightSideBar()}>
                            <IconButton className="contactHistory-iconButton">
                                <ChevronRightIcon />
                            </IconButton>
                            Dados do contato
                        </AccordionSummary>
                        <Divider />
                        <AccordionDetails>
                            <Box className='vertical'>
                                {contactData &&
                                    <ScreenContext.Provider value={{
                                        profile: contactData,
                                        templates: parent.templates,
                                        queues: queues(),
                                        clients: [contactData],
                                        originNumbers: state.origin_numbers,
                                        customFields: state.custom_fields,
                                        enterpriseId: getSession().profile.enterprise.id,
                                        setProfile: (newprofile) => { 
                                            chat.contact = newprofile
                                            Object.assign(contactData, newprofile)
                                            if ('reloadChat' in window)
                                                window.reloadChat()
                                        },
                                        getClients: () => { },
                                        usersEmails: state.usersEmails
                                        
                                    }}>
                                        <ContactProfile />
                                    </ScreenContext.Provider>}
                            </Box>
                            {/* <Profile
                                contactData={contactData}
                                agentCanUpdateContact={agentCanUpdateContact}
                                isManageCalls={isManageCalls}
                                updateField={updateField}
                                fetchHistoryMessages={fetchHistoryMessages}
                                chat={chat}
                                reloadContact={reloadContact}
                            /> */}
                        </AccordionDetails>
                    </Accordion>
                    {/* <Divider /> */}
                    {/* {enterprise.platforms.scout &&
                        <>
                            <Divider />
                            <Accordion defaultExpanded={false} className='accordion'>
                                <AccordionSummary className='accordionHeader' expandIcon={<ExpandMoreIcon />}>
                                    Contatos Scout
                                </AccordionSummary>
                                <Divider />
                                <AccordionDetails>
                                    <ScoutContactHistory
                                        contactData={contactData}
                                        fetchHistoryMessages={fetchScoutMessages}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </>
                    } */}
                </>
            }
            {profileContent === "labels" &&
                <Accordion expanded={true} className='accordion'>
                    <AccordionSummary className='accordionHeader' onClick={() => closeChatRightSideBar()}>
                        <IconButton className="contactHistory-iconButton">
                            <ChevronRightIcon />
                        </IconButton>
                        Etiquetas
                    </AccordionSummary>
                    <Divider />
                    <AccordionDetails >
                        <Box alignItems={'center'}>
                            <LabelArea
                                chat={chat}
                                chatLabels={chatLabels}
                                allLabels={allLabels}
                                setChatLabels={setChatLabels}
                            />
                        </Box>
                    </AccordionDetails>
                </Accordion>
            }
            {profileContent === "cortex" &&
                <KwikAssistant closeAssistant={closeChatRightSideBar}/>
            }
        </Box>
    );
}
