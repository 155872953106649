import React, { useContext, useState } from 'react'

import { ScreenContext } from '..';

import Information from './Information';
import SendMessage from './SendMessage';
import AddClient from './AddClient';
import ImportClients from './ImportClients';
import NotificationFilter from './NotificationFilter';
import CreateCampaign from './CreateCampaign';
import { getSession } from "../../../auth";

const TabButtons = () => {

    const { isSmallScreen, selectedTab, settings, permissions, selectedView } = useContext(ScreenContext)

    const canCreateCampaigns = (permissions.isAdmin || permissions.isSupervisor) ? true : getSession().profile.change_campaigns

    const user = getSession()

    switch (selectedTab) {
        case "clients":
            if (selectedView === "table")
                return <SendMessage/>
            return <></>
        case 'notifications':
            return (
                <>
                    {!isSmallScreen ?
                        <NotificationFilter />
                        : null}
                </>
            )
        case 'campaigns':
            return (
                <>
                    {!isSmallScreen && canCreateCampaigns ?
                        <CreateCampaign />
                        : null}
                </>
            )
        default:
            return (<div></div>)
    }
}

export default TabButtons
