import React, { useState, useMemo, useEffect } from "react"

import { useLocation, useHistory } from "react-router-dom"

import {
	Box,
	Tabs,
	Tab
} from "@mui/material"

import {
	SupportAgent as SupportAgentIcon,
	Event as EventIcon,
	Groups as GroupsIcon,
	Apartment as ApartmentIcon,
	Campaign as CampaignIcon,
	Message as MessageIcon,
	ManageAccounts as ManageAccountsIcon,
	SmartToy as SmartToyIcon
} from "@mui/icons-material"

import userPermissions from "../User/Permissions"
import Chat from "../Chat"
import Crm from "../Crm"
import ManageCalls from "../ManageCalls"
import Agenda from "../Agenda"
import KwikAssistant from "../KwikAssistant"

import { getSession } from "../../auth"

import "./styles.css"

const groups = userPermissions()

const components = {
	"supervision"   : () => ({ label: "Supervisão", icon: <ManageAccountsIcon />, hidden: ! groups.isSupervisor, component: <ManageCalls /> }),
	"service"       : ({connections}) => ({ id: "service", label: "Atendimento", icon: <SupportAgentIcon />, component: <Chat connections={connections}/> }),
	"agenda"        : () => ({ label: "Agenda", icon: <EventIcon />, hidden: ! getSession()?.profile?.enterprise?.show_agenda, component: <Agenda /> }),
	"contacts"      : () => ({ label: "Contatos", icon: <GroupsIcon />, component: < Crm key={'crm-clients'} selectedTab="clients"/> }),
	"enterprises"   : () => ({ label: "Empresas", icon: <ApartmentIcon />, component: <Crm key={'crm-enterprises'} selectedTab="enterprises" /> }),
	"campaigns"     : () => ({ label: "Campanhas", icon: <CampaignIcon />, component: <Crm key={'crm-campaigns'} selectedTab="campaigns" /> }),
	"notifications" : () => ({ label: "Disparos", icon: <MessageIcon />, component: <Crm key={'crm-notifications'} selectedTab="notifications" /> }),
	"cortex"        : () => ({ label: "Cortex", icon: <SmartToyIcon />, component: <Box className="hide-assistant-close-btn"><KwikAssistant /></Box> })
}

const generateComponents = (kwargs) => {
	const new_components = {}
	Object.entries(components).forEach(([key, func]) => {
		const tab = func(kwargs)
		if (tab.hidden) return
		new_components[key] = tab
	})
	return new_components
}

const Main = ({ connections }) => {

	const location = useLocation()
	const history = useHistory()

	const exec_components = useMemo(()=> generateComponents({connections}), [connections])
	const tab_entries = useMemo(()=> Object.entries(exec_components), [connections])

	const getSelectedTab = () => {
	    const searchParams = new URLSearchParams(location.search)
	    const selectedTab = searchParams.get("selectedTab")

	    return tab_entries.some(([key]) => key === selectedTab) 
	        ? selectedTab 
	        : tab_entries.find(([, tab]) => !tab.hidden)?.[0]
	}

	const [activeTab, setActiveTab] = useState(getSelectedTab)

	useEffect(() => {
		setActiveTab(getSelectedTab())
	}, [location.search])

	return (
		<Box class="main-container">
			<Tabs className="main-container-tabs" variant="scrollable" scrollButtons="auto" value={activeTab}>
				{tab_entries.map(([key, tab]) => {
					return (
					<Tab 
						className={(activeTab === key) ? "active" : ""} 
						onClick={() => {
							setActiveTab(key)
							const urlParams = new URLSearchParams(location.search)
                            urlParams.set("selectedTab", key)
                            history.push(`${location.pathname}?${urlParams.toString()}`)
						}}
						value={key}
	                    icon={tab.icon} 
	                    label={tab.label}
	                    iconPosition="start"  
						chil
	                />
				)})}
            </Tabs>
            <Box className="main-container-content" value={activeTab} index={0}>
            	{exec_components[activeTab].component}
            </Box>
		</Box>
	)
}

export default Main
