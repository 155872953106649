import React from "react";

import { 
    Box, 
    Grid, 
    List, 
    ListItem, 
    FormControlLabel,
    Switch, 
    IconButton 
} from "@mui/material";

import TooltipMUI from "@mui/material/Tooltip";
import Table from '../Table'
import Filter from '../Filter'
import Typography from '../Typography'
import OperatorsGraph from "./OperatorsGraph/index.js";
import "./styles.css";
import RefreshIcon from '@mui/icons-material/Refresh';
import TooltipIndicators from './Tooltip';
import Loading from '../Loading';
import { getSession } from "../../auth";
import QueuesLegend from "./QueueLegend";
import api from '../../api';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, 
    Legend, ResponsiveContainer, PieChart, Pie, Sector } from 'recharts';

import useValidationError from '../ErrorValidation';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';

class DashboardGrid extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            groups                : {},
            enterpriseId          : 0,
            chatPeaks             : [],
            answeredChats         : 0,
            missedChats           : 0,
            attendanceStatus      : [],
            attendanceStatusTotal : 0,
            operatorsStatus       : [],
            operatorsStatusTotal  : 0,
            activeIndex_op        : 0,
            activeIndex_at        : 0,
            activeIndex_np        : 0,
            activeIndex_cs        : 0,
            averageDuration       : 0,
            averageResponse       : 0,
            averageWaittime       : 0,
            nps                   : [],
            npsTotal              : 0,
            npsStatus             : [],
            npsGrade              : 0,
            csatGrade             : 0,
            csat                  : [],
            loading               : false,
            key                   : 0,
            intervalId            : null,
            groupsLength          : 0,
            groupAll              : false,
        }

        this.colors  = [];
    }

    renderActiveShape = (props) => {
      const RADIAN = Math.PI / 180;
      const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
        payload,
        percent,
        value
      } = props;

      const sin = Math.sin(-RADIAN * midAngle);
      const cos = Math.cos(-RADIAN * midAngle);
      const sx = cx + (outerRadius + 10) * cos;
      const sy = cy + (outerRadius + 10) * sin;
      const mx = cx + (outerRadius + 30) * cos;
      const my = cy + (outerRadius + 30) * sin;
      const ex = mx + (cos >= 0 ? 1 : -1) * 22;
      const ey = my;
      const textAnchor = cos >= 0 ? "start" : "end";

      return (
        <g>
          <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
            {payload.name}
          </text>
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill}
          />
          <Sector
            cx={cx}
            cy={cy}
            startAngle={startAngle}
            endAngle={endAngle}
            innerRadius={outerRadius + 6}
            outerRadius={outerRadius + 10}
            fill={fill}
          />
          <path
            d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
            stroke={fill}
            fill="none"
          />
          <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            textAnchor={textAnchor}
            fill="#333"
            fontSize="18px"
          >{`${value}`}</text>
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            dy={18}
            textAnchor={textAnchor}
            fill="#999"
            fontSize="12px"
          >
            {`${(percent * 100).toFixed(0)}%`}
          </text>
        </g>
      );
    }

    onPieEnter_op = (_, index) => {
        this.setState({ activeIndex_op: index });
    };

    onPieEnter_at = (_, index) => {
        this.setState({ activeIndex_at: index });
    };

    onPieEnter_np = (_, index) => {
        this.setState({ activeIndex_np: index });
    };

    onPieEnter_cs = (_, index) => {
        this.setState({ activeIndex_cs: index });
    };

    resetChat = (queues) => {
        let chatPeaks    = [];
        let groups       = {};
        let groupsLength = 0;
        const user       = getSession();

        user.profile.enterprise.queues.forEach((queue1, index) => {
            const key = queue1.name.split('_')[1];

            if(this.state.groups[key]) {
                groups[key]  = this.state.groups[key];
                groupsLength = this.state.groupsLength;

            } else {
                groups[key] = { 
                    name    : queue1.description,
                    checked : false,
                    color   : this.colors[index],
                    index   : index 
                };

                user.profile.queues.forEach(queue2 => {
                    if(key == queue2.name.split('_')[1]) {
                        groups[key].checked = true;
                        groupsLength       += 1;
                    }
                });
            }
        });

        for(let i = 0; i < 24; i++) {
            let hourString = '';

            if(i < 10) {
                hourString = `0${i}`;

            } else {
                hourString = `${i}`;
            }

            let chat = { 'hour': hourString };

            Object.keys(queues).forEach((key, index) => {
                const queue_name = groups[key].name;

                chat[queue_name] = 0;
            });

            chatPeaks.push(chat);
        }

        return { 'groups': groups, 'chatPeaks': chatPeaks, 'groupsLength': groupsLength };
    }

    setChecked = (key, condition) => {
        let queues       = {...this.state.groups};
        let groupsLength = this.state.groupsLength;

        if(key === 'all') {
            this.state.groupAll = condition;

            Object.keys(this.state.groups).map((queue, index) => {
                queues[queue].checked = condition;

                if(condition) {
                    groupsLength += 1;

                } else {
                    groupsLength -= 1;
                }
            });
        } else {
            queues[key].checked = condition;

            if(condition) {
                groupsLength += 1;

            } else {
                groupsLength -= 1;
            }
        }

        this.setState({ groups: queues, groupsLength: groupsLength });

        this.fetchDashboard();
    }

    setupColors = (queues) => {
        this.colors = ['#5F8963', '#d5ba7d', '#C95F50', '#6A5ACD', '#4169E1', '#CD853F', '#40E0D0',
            '#BA55D3', '#90EE90', '#A52A2A', '#9370DB', '#6B8E23', '#DAA520', '#DB7093', '#BA55D3'];

        const maxColors = Object.keys(queues).length;

        if(maxColors > this.colors.length) {
            let remainingColors = maxColors - this.colors.length;

            while(remainingColors) {
                // Add random colors
                this.colors.push('#' + Math.floor(Math.random() * 0x1000000).toString(16).padStart(6, '0'));

                remainingColors -= 1;
            }
        }
    }

    showCustomCaption = () => {
        return (
            <Box className="queues-legend">
                {Object.keys(this.state.groups).map((key, index) => (
                    this.state.groups[key].checked &&
                        <QueuesLegend key={index} color={this.state.groups[key].color} queue={this.state.groups[key].name} />
                ))}
            </Box>
        );
    }

    showFilters = () => {
        return (
            <Box>
                <ListItem button key={-1} className="group-item">
                    <FormControlLabel
                        label="Todas"
                        control={
                          <Switch
                            checked={this.state.groupAll}
                            onChange={() => this.setChecked('all', !this.state.groupAll)}
                          />
                        }
                    />
                </ListItem>
                {Object.keys(this.state.groups).map((key, index) => (
                    <ListItem button key={index} className="group-item">
                        <FormControlLabel
                            label={this.state.groups[key].name.length <= 13? this.state.groups[key].name : this.state.groups[key].name.slice(0,13)+'...'}
                            control={
                                <Switch 
                                    checked={this.state.groups[key].checked} 
                                    onChange={() => this.setChecked(key, !this.state.groups[key].checked)}
                                />
                            }
                        />
                    </ListItem>
                ))}
            </Box>
        );
    }

    fetchDashboard = () => {
        this.setState({ loading: true });

        api.get(`/api/enterprises/${this.state.enterpriseId}/dashboard/`)
        .then((res) => {
            if (res.status !== 200) {
                useValidationError(res.status, null);
                
            } else {
                this.setupColors(res.data);
                this.updateIndicators(res.data);
            }
        })
        .catch((error) => {
            console.error(error);
            this.setState({ loading: false });
        });
    }

    updateIndicators = (queues) => {
        let agentsLoggedin        = new Set();
        let agentsTotal           = new Set();

        let answeredChats         = 0;
        let missedChats           = 0;

        let attendanceStatus      = [];
        let attendanceStatusTotal = 0;
        let operatorsStatus       = [];
        let operatorsStatusTotal  = 0;

        let averageDuration       = 0;
        let averageResponse       = 0;
        let averageWaittime       = 0;

        let npsPromoters          = 0;
        let npsDetractors         = 0;
        let npsNeutros            = 0;
        let npsStatus             = [];
        let npsGrade              = 0;
        let npsTotal              = 0;
        let nps                   = [];

        let csatTotal             = 0;
        let csatValid             = 0;
        let csatGrade             = 0;
        let csat                  = [];
        let colorCSAT             = '';

        let selectedRows          = 0;

        const newGroupsAndChat    = this.resetChat(queues);
        let groups                = newGroupsAndChat.groups;
        let chatPeaks             = newGroupsAndChat.chatPeaks; 
        const groupsLength        = newGroupsAndChat.groupsLength;

        Object.keys(queues).forEach((key, index) => {
            const queue_name = groups[key].name;
            let queue        = queues[key];
            if(groups[key].checked) {
                queue.agents_loggedin.forEach(item => agentsLoggedin.add(item));
                queue.agents_total.forEach(item => agentsTotal.add(item));

                for(let i = 0; i < queues[key].chat_peaks.length; i++) {
                    chatPeaks[i][queue_name] += queues[key].chat_peaks[i];
                }

                answeredChats   += queues[key].answered_chats;
                missedChats     += queues[key].missed_chats;

                averageDuration += queues[key].average_duration;
                averageResponse += queues[key].average_response;
                averageWaittime += queues[key].average_waittime;

                npsPromoters    += queues[key].nps_promoters;
                npsDetractors   += queues[key].nps_detractors;
                npsNeutros      += queues[key].nps_total;

                csatValid       += queues[key].csat_valid;
                csatTotal       += queues[key].csat_total;

                selectedRows     = selectedRows + 1;
            }
        });

        // NPS Search

        npsTotal       = npsNeutros;
        npsNeutros     = npsTotal - (npsPromoters + npsDetractors);
        npsGrade       = ((npsPromoters / npsTotal) * 100) - ((npsDetractors / npsTotal) * 100);

        nps            = [{ name: 'Promotores', value: 0, fill: '#5F8963' },
                          { name: 'Detratores', value: 0, fill: '#C95F50' },
                          { name: 'Neutros', value: 0, fill: '#959595' }];

        nps[0].value  += npsPromoters;
        nps[1].value  += npsDetractors;
        nps[2].value  += npsNeutros;

        npsStatus      = [{ name: "Promotores", value: npsPromoters, fill: "#5F8963" },
                          { name: "Detratores", value: npsDetractors, fill: "#C95F50" },
                          { name: "Neutros", value: npsNeutros, fill: "#959595" }]; 

        // CSAT Search
        
        csatGrade       = (csatValid / csatTotal) * 5;

        colorCSAT       = csatGrade >= 4.0  ? '#5F8963' 
                        : csatGrade >= 3.0  ? '#d5ba7d' : '#C95F50';

        const remaining = 5 - csatGrade;

        csat            = [{ name: "Positivas", value: csatGrade, color: colorCSAT }, 
                           { name: "Negativas", value: remaining, color: 'white'}];

        // Average Times

        averageDuration /= selectedRows;
        averageResponse /= selectedRows;
        averageWaittime /= selectedRows;

        // Attendance Status Total and Operators Status Total

        attendanceStatusTotal = Math.abs(answeredChats) + Math.abs(missedChats);

        attendanceStatus      = [{ name: "Realizados", value: answeredChats, fill: "#21446C" },
                                 { name: "Perdidos", value: missedChats, fill: "#C95F50" }];

        operatorsStatusTotal   = agentsTotal.size;

        let totalAgentsOffline = agentsTotal.size - agentsLoggedin.size

        operatorsStatus        = [{ name: "Online", value: agentsLoggedin.size, fill: "#5F8963" },
                                  { name: "Offline", value: totalAgentsOffline, fill: "#C95F50" }];

        // Set Component States

        this.setState({
            answeredChats         : answeredChats,
            missedChats           : missedChats,
            attendanceStatus      : attendanceStatus,
            attendanceStatusTotal : attendanceStatusTotal,
            operatorsStatus       : operatorsStatus,
            operatorsStatusTotal  : operatorsStatusTotal,
            averageDuration       : averageDuration,
            averageResponse       : averageResponse,
            averageWaittime       : averageWaittime,
            nps                   : nps,
            npsGrade              : npsGrade,
            npsTotal              : npsTotal,
            csatGrade             : csatGrade,
            npsStatus             : npsStatus,
            csat                  : csat,
            groups                : groups,
            chatPeaks             : chatPeaks,
            key                   : Math.random(),
            loading               : false,
            groupsLength          : groupsLength,
        });
    }

    componentWillMount() {
        const user = getSession();

        if (user) {
            this.state.enterpriseId = user.profile.enterprise.id;
        }
    }

    componentDidMount() {
        this.fetchDashboard();

        let intervalId = setInterval(this.fetchDashboard, 30000);

        this.setState({ intervalId: intervalId });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    render(){
        return (
            <Box className="dash-body">
                {/* <ReactNotifications /> */}
                <Box id="dash-box-2">
                    <IconButton 
                        className="toolbar-refresh-icon"
                        onClick={() => this.fetchDashboard()}
                    >
                        <RefreshIcon/>
                    </IconButton>
                    <IconButton className="toolbar-filter-icon">
                        <Filter content={
                            <List className="dash-list">
                                <Box className="dash-queues">
                                    <Typography align="center" 
                                        text="Filas de Atendimento" 
                                        color="darkGray" 
                                        fontWeight='500' 
                                        className="group-item" 
                                    />
                                </Box>
                                { this.showFilters() }
                            </List>
                        }/>
                    </IconButton>
                </Box>
                <Grid container spacing={2} id="dash-grid">
                    <Loading loading={this.state.loading} />
                    <Grid item lg={4} md={4} sm={12} xs={12} id="grid-item">
                        <Grid container spacing={2} id="dash-grid">
                            <Grid item lg={12} md={12} sm={12} xs={12} id="grid-item">
                                <Box id="big-frame-2" fullWidth>
                                    <TooltipIndicators text="Total de atendimentos ao longo do dia." />
                                    <Box className="fontSize-title">
                                        <Typography align="center" 
                                            text="Picos de Atendimento" 
                                            color="darkGray" 
                                            fontWeight='500' 
                                            variant="subtitle1" 
                                        />
                                    </Box>
                                    <Box className="barGraph">
                                        <ResponsiveContainer 
                                            width="95%" 
                                            height={
                                                this.state.groupsLength > 2
                                                ? '90%'
                                                : '100%'
                                            }
                                        >
                                            <BarChart 
                                              className={
                                                this.state.groupsLength > 2
                                                  ? 'bar-chart-dash'
                                                  : null
                                              }
                                              width={500}
                                              height={300}
                                              data={this.state.chatPeaks}
                                              margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 5,
                                              }}
                                               key={this.state.key}
                                            >
                                              <XAxis dataKey="hour" />
                                              <YAxis />
                                              <Tooltip />
                                              <Legend />
                                              {Object.keys(this.state.groups).map((key, index) => (
                                                this.state.groups[key].checked &&
                                                    <Bar key={index} dataKey={this.state.groups[key].name} stackId="a" fill={this.state.groups[key].color} />
                                              ))}
                                            </BarChart>
                                        </ResponsiveContainer>
                                        {this.state.groupsLength > 2 && this.showCustomCaption() }
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12} id="grid-item">
                                <Box id="big-frame" fullWidth key={this.state.key}>
                                    {this.state.npsTotal > 0 && 
                                        <Box className="graphCircle" id="dash-box-1" marginRight="1vw">
                                            <Box id="operatorsGraph">
                                                <PieChart width={400} height={400} id="npsGraph">
                                                  <Pie
                                                    activeIndex={this.state.activeIndex_np}
                                                    activeShape={this.renderActiveShape}
                                                    data={this.state.npsStatus}
                                                    cx={200}
                                                    cy={200}
                                                    innerRadius={60}
                                                    outerRadius={80}
                                                    fill="#8884d8"
                                                    dataKey="value"
                                                    onMouseEnter={this.onPieEnter_np}
                                                  />
                                                </PieChart>
                                            </Box>
                                        </Box>
                                    }
                                    {this.state.npsTotal > 0 && 
                                        <Box id="dash-box-1">
                                            <Box id="title-length">
                                                <Typography align="center" 
                                                    text="Nota NPS" 
                                                    color="darkGray" 
                                                    fontWeight='400' 
                                                    variant="subtitle1" 
                                                />
                                            </Box>
                                            { this.state.npsGrade >= 9 &&
                                                <Box className="dash-values" id="color-green">
                                                    { this.state.npsGrade.toFixed(0) }
                                                </Box>
                                            }
                                            { this.state.npsGrade < 9 && this.state.npsGrade >= 7 &&
                                                <Box className="dash-values" id="color-yellow">
                                                    { this.state.npsGrade.toFixed(0) }
                                                </Box>
                                            }
                                            { this.state.npsGrade < 7 &&
                                                <Box className="dash-values" id="color-red">
                                                    { this.state.npsGrade.toFixed(0) }
                                                </Box>
                                            }
                                        </Box>
                                    }
                                    {!this.state.npsTotal && 
                                        <Box className="fontSize-title" id="tooltip-null-2">
                                            <Typography align="center" 
                                                text="Sem dados da pesquisa NPS" 
                                                color="darkGray" 
                                                fontWeight='400' 
                                            />
                                        </Box>
                                    }
                                    <TooltipIndicators text="Em uma escala de 0 a 10, quanto um cliente recomendaria 
                                        sua empresa, seus produtos e/ou serviços para seus amigos, 
                                        familiares e conhecidos nas últimas 24 horas." />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12} id="grid-item">
                        <Grid container spacing={2} id="dash-grid" key={this.state.key}>
                            <Grid item lg={12} md={12} sm={12} xs={12} id="grid-item">
                                <Box id="big-frame-2" fullWidth>
                                    <TooltipIndicators text="Total de atendimentos realizados e perdidos nas últimas 24 horas." />
                                    {this.state.attendanceStatusTotal > 0 &&
                                        <Typography align="center" text="Atendimentos" color="darkGray" fontWeight='500' variant="subtitle1" />
                                    }
                                    {this.state.attendanceStatusTotal > 0 &&
                                        <Box id="operatorsGraph">
                                            <PieChart width={400} height={400} id="callsAndOperatorsGraph">
                                              <Pie
                                                activeIndex={this.state.activeIndex_op}
                                                activeShape={this.renderActiveShape}
                                                data={this.state.attendanceStatus}
                                                cx={200}
                                                cy={200}
                                                innerRadius={60}
                                                outerRadius={80}
                                                fill="#8884d8"
                                                dataKey="value"
                                                onMouseEnter={this.onPieEnter_op}
                                              />
                                            </PieChart>
                                        </Box>
                                    }
                                    {!this.state.attendanceStatusTotal &&
                                        <Box className="fontSize-title" id="tooltip-null">
                                            <Typography align="center" 
                                                text="Sem dados de atendimentos" 
                                                color="darkGray" 
                                                fontWeight='400' 
                                            />
                                        </Box>
                                    }
                                </Box>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12} id="grid-item">
                                <Box id="small-frame" fullWidth>
                                    {this.state.csatGrade > 0 && 
                                        <Box className="graphCircle" id="dash-box-1" marginRight="1vw">
                                            <Box id="csatGraph" width="6vw">
                                                <PieChart width={800} height={400} onMouseEnter={this.onPieEnter_cs}>
                                                    <Pie
                                                        data={this.state.csat}
                                                        cx={220}
                                                        cy={100}
                                                        startAngle={180}
                                                        endAngle={0}
                                                        innerRadius={60}
                                                        outerRadius={80}
                                                        fill="#8884d8"
                                                        paddingAngle={5}
                                                        dataKey="value"
                                                    >
                                                        {this.state.csat.map((csatValue, index) => (
                                                            <Cell key={`cell-${index}`} fill={csatValue.color} />
                                                        ))}
                                                    </Pie>
                                                </PieChart>
                                            </Box>
                                        </Box>
                                    }
                                    {this.state.csatGrade > 0 && 
                                        <Box id="dash-box-1">
                                            <Box className="fontSize-title">
                                                <Typography align="center" 
                                                    text="Nota CSAT" 
                                                    color="darkGray" 
                                                    fontWeight='400' 
                                                    variant="subtitle1" 
                                                />
                                            </Box>
                                            { this.state.csatGrade >= 4.0 &&
                                                <Box className="dash-values" id="color-green">
                                                    { this.state.csatGrade.toFixed(1) }
                                                </Box>
                                            }
                                            { this.state.csatGrade < 4.0 && this.state.csatGrade >= 3.0 &&
                                                <Box className="dash-values" id="color-yellow">
                                                    { this.state.csatGrade.toFixed(1) }
                                                </Box>
                                            }
                                            { this.state.csatGrade < 3.0 &&
                                                <Box className="dash-values" id="color-red">
                                                    { this.state.csatGrade.toFixed(1) }
                                                </Box>
                                            }
                                        </Box>
                                    }
                                    {!this.state.csatGrade && 
                                        <Box className="fontSize-title" id="dash-box-1">
                                            <Typography align="center" 
                                                text="Sem dados da pesquisa CSAT" 
                                                color="darkGray" 
                                                fontWeight='400' 
                                            />
                                        </Box>
                                    }
                                    <TooltipIndicators text="Em uma escala de 0 a 5, qual a percepção que os seus clientes têm 
                                        sobre os produtos e serviços oferecidos pela sua empresa nas últimas 24 horas." />
                                </Box>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12} id="grid-item">
                                <Box id="small-frame" fullWidth>
                                    <Box id="dash-box-1">
                                        <Box className="fontSize-title">
                                            <Typography align="center" 
                                                text="Tempo Médio de Atendimento" 
                                                color="darkGray" 
                                                fontWeight='500' 
                                                variant="subtitle1" 
                                            />
                                        </Box>
                                        <Box className="dash-values" id="color-blue">
                                            { (this.state.averageDuration > 0) 
                                                ? new Date(this.state.averageDuration * 1000).toISOString().substr(11, 8) 
                                                : '00:00:00' 
                                            }
                                        </Box>
                                    </Box>
                                    <TooltipIndicators text="Tempo médio dos atendimentos nas últimas 24 horas." />
                                </Box>
                            </Grid> 
                        </Grid>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12} id="grid-item">
                        <Grid container spacing={2} id="dash-grid" key={this.state.key}>
                            <Grid item lg={12} md={12} sm={12} xs={12} id="grid-item">
                                <Box id="big-frame-2" fullWidth>
                                    <TooltipIndicators text="Total de operadores onlines e/ou offlines no Kwik." />
                                    {this.state.operatorsStatusTotal > 0 &&
                                        <Box className="fontSize-title">
                                            <Typography align="center" 
                                                text="Status dos Operadores" 
                                                color="darkGray" 
                                                fontWeight='500' 
                                                variant="subtitle1" 
                                            />
                                        </Box>
                                    }
                                    {this.state.operatorsStatusTotal > 0 &&
                                        <Box id="operatorsGraph">
                                            <PieChart width={400} height={400} id="callsAndOperatorsGraph">
                                              <Pie
                                                activeIndex={this.state.activeIndex_at}
                                                activeShape={this.renderActiveShape}
                                                data={this.state.operatorsStatus}
                                                cx={200}
                                                cy={200}
                                                innerRadius={60}
                                                outerRadius={80}
                                                fill="#8884d8"
                                                dataKey="value"
                                                onMouseEnter={this.onPieEnter_at}
                                              />
                                            </PieChart>
                                        </Box>
                                    }
                                    {!this.state.operatorsStatusTotal &&
                                        <Box className="fontSize-title" id="tooltip-null">
                                            <Typography align="center" 
                                                text="Sem dados de operadores" 
                                                color="darkGray" 
                                                fontWeight='400' 
                                            />
                                        </Box>
                                    }
                                </Box>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12} id="grid-item">
                                <Box id="small-frame" fullWidth>
                                    <Box id="dash-box-1">
                                        <Box className="fontSize-title">
                                            <Typography align="center" 
                                                text="Tempo Médio de Resposta" 
                                                color="darkGray" 
                                                fontWeight='500' 
                                                variant="subtitle1" 
                                            />
                                        </Box>
                                        <Box className="dash-values" id="color-blue">
                                            { (this.state.averageResponse > 0) 
                                                ? new Date(this.state.averageResponse * 1000).toISOString().substr(11, 8) 
                                                : '00:00:00'  
                                            }
                                        </Box>
                                    </Box>
                                    <TooltipIndicators text="Tempo médio de resposta dos operadores nas últimas 24 horas." />
                                </Box>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12} id="grid-item">
                                <Box id="small-frame" fullWidth>
                                    <Box id="dash-box-1">
                                        <Box className="fontSize-title">
                                            <Typography align="center" 
                                                text="Tempo Médio de Espera" 
                                                color="darkGray" 
                                                fontWeight='500' 
                                                variant="subtitle1" 
                                            />
                                        </Box>
                                        <Box className="dash-values" id="color-blue">
                                            { (this.state.averageWaittime > 0) 
                                                ? new Date(this.state.averageWaittime * 1000).toISOString().substr(11, 8) 
                                                : '00:00:00' 
                                            }
                                        </Box>
                                    </Box>
                                    <TooltipIndicators text="Tempo médio de espera em fila nas últimas 24 horas." />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>  
            </Box >
        );
    }
}

export default DashboardGrid;

