import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Tooltip, Button } from "@mui/material";
import Typography from "../Typography";
import makeStyles from '@mui/styles/makeStyles';
import ReactAudioPlayer from 'react-audio-player';
import MessageItem from "../Chat/MessagesArea/ChatWindow/MessageItem";
import { calcDate } from "../Chat/MessagesArea/ChatWindow/NewMessageArea";
import { TranscriptionButton } from "../Button/TranscriptionButton";
import dayjs from 'dayjs'
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';


const AudioPlayer = ({ src, user, message, key, queuesName, platform, activeChat, enterpriseId, updateMessage }) => {

    const [error, setError] = useState(false)
    const [reload, setReload] = useState(0); // State to force reload
    const message_iso_time = message?.iso_time
    if (message_iso_time == "undefined")
        message_iso_time = "--:--"

    const useStyles = makeStyles((theme) => {
        return {
            root: {
                width: '300px',
                margin: '5px 0',
                height: '40px'
            },
        }
    });

    const now = dayjs();
    const msgtime = dayjs(message.time * 1000);
    const differenceInMinutes = now.diff(msgtime, 'minute');
    let errorMsg = 'Esse áudio não está mais disponível' 
    if (differenceInMinutes < 3) {
        errorMsg = 'Gravação em processamento. Por favor, aguarde.'
    }

    useEffect(() => {
        if (error && differenceInMinutes < 3) {
            const timer = setTimeout(() => {
                setError(false);
                setReload(prev => prev + 1);
            }, 30000); 

            return () => clearTimeout(timer);
        }
    }, [error]);


    const classes = useStyles();


    return (
        <>
            {!error ?

                <Box display="flex" justifyContent={user !== 0 ? 'flex-end' : 'flex-start'}>
                    <Box sx={{ 
                        position      : 'relative', 
                        display       : 'flex', 
                        flexDirection : 'column' 
                    }}>
                        <Tooltip placement='left' title={message?.agent_name ? `Enviado por: ${message?.agent_name}` : ''}>
                            <span>
                                <ReactAudioPlayer
                                    onError={() => { setError(true) }}
                                    src={src}
                                    controls
                                    className={classes.root}
                                    preload
                                />
                            </span>
                        </Tooltip>
                        {updateMessage &&
                            <TranscriptionButton 
                                message_id={message.id}
                                message_transcription={message.transcription ? message.transcription : message.transcription_data?.text || ''}
                                message_transcription_status={message.transcription_status ? message.transcription_status : message.transcription_data?.status || ''}
                                message_type={"audioMessage"}
                                transcriptionEndpointURL={`api/enterprises/${enterpriseId}/calls/chat/transcribe/${message.id}/`}
                                updateMessage={updateMessage} 
                            />
                        }

                        {message &&
                            <Tooltip title={calcDate(message.time, 'DD/MM/YYYY HH:mm')}>
                                <span style={{ textAlign: 'right', marginTop: '-.75rem', marginRight: '.25rem' }}>
                                    <Typography text={`${message_iso_time}`}
                                        sx={{ 
                                            position : 'absolute', 
                                            bottom   : '0.1rem', 
                                            right    : '1.4rem', 
                                            color    : 'black' 
                                        }}
                                        color="black"
                                        variant="caption"
                                        align="left"
                                        justifyContent="left"
                                    />
                                </span>
                            </Tooltip>
                        }
                    </Box>
                </Box >
                :
                <MessageItem
                    key={key}
                    message={{ ...message, message: errorMsg }}
                    user={user}
                    queuesName={queuesName}
                    platform={platform}
                    media={true}
                />
            }
        </>


    )
}

AudioPlayer.PropTypes = {
    src: PropTypes.any,
    user: PropTypes.string
};


export default AudioPlayer;