import React, { useState, useEffect, useContext, useMemo, useRef } from "react"

import {
    Box,
    Button,
    InputAdornment,
    TextField,
    List,
    ListItem,
    IconButton,
    Tooltip,
} from "@mui/material"


import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import SearchIcon from "@mui/icons-material/Search"
import ApartmentIcon from "@mui/icons-material/Apartment"
import PersonIcon from "@mui/icons-material/Person"

import api from "../../../../../api"
import ConfirmationDialog from "../../../../Dialogs/Confirmation"

import useNotification from "../../../../Notification"

import EditGroupEnterprisesModal from "../EditGroupEnterprisesModal"
import EditGroupNameModal from "../EditGroupNameModal"

import { FixedSizeList, ListChildComponentProps } from "react-window"

import { ListItemButton } from "@mui/material"
import { ListItemText } from "@mui/material"

import { ScreenContext } from "../../.."
import ResponsiveCreateEnterpriseGroupButton from "../ResponsiveCreateEnterpriseGroupButton"
import { alphabeticalSort } from "../../../../../utils/alphabeticalSort"

import UserPermissions from "../../../../User/Permissions"
import { getSession } from "../../../../../auth"

let timeoutId

const EnterpriseGroupModal = ({ addEnterpriseGroup, deleteEnterpriseGroupModal, editEnterpriseGroupModal, selectedEnterpriseGroup, filterOnClick, selectedEnterprises, addSelectedEnterprisesToGroup, loadMoreGroups }) => {
    const { getGroups, enterpriseId, enterprises, groups, permissions, settings } = useContext(ScreenContext)
    const [groupList, updateGroupList] = useState([])
    const [enterpriseGroupData, updateEnterpriseGroupData] = useState([])
    const [selectedKey, updateSelectedKey] = useState(0)
    const [editGroupEnterprisesModal, updateEditGroupEnterprisesModal] = useState(false)
    const [selectedGroup, setSelectedGroup] = useState([])
    const optionButton = useRef()
    const optionBox = useRef()

    const handleSelectedGroup = (event, group) => {
        setSelectedGroup(group)
        optionButton.current.focus()
        optionBox.current.style.left = `calc(${event.clientX}px - 5px)`
        optionBox.current.style.top = `calc(${event.clientY}px - 5px)`
        setTimeout(() => { optionButton.current.blur() }, 10)
    }

    useEffect(() => {
        updateGroupList(groups.sort(alphabeticalSort))
    }, [groups])

    const renderRow = (props) => {
        const { index, style } = props
        const newstyle = { ...style }
        const group = groupList[index]

        const secondaryAction = () => {
            return (
                <>
                    <IconButton aria-label="edit" onClick={(event) => {
                        handleSelectedGroup(event, group)
                        event.stopPropagation()
                    }}>
                        <MoreHorizIcon />
                    </IconButton >
                </>
            )
        }

        return (
            <ListItem
                disableGutters
                onClick={() => { filterOnClick(group.name) }} style={newstyle} key={`each-group-${group.id}`}
                secondaryAction={secondaryAction()}
            >
                <Tooltip title={group.name}>
                    <ListItemButton disableGutters sx={{ paddingLeft: "0.5rem", overflow: "hidden", whiteSpace: 'pre' }}>
                        {group.owner !== null && group.owner_id !== null
                            ? <PersonIcon style={{ marginRight: ".5rem", fontSize: "1.25rem" }} />
                            : <ApartmentIcon style={{ marginRight: ".5rem", fontSize: "1.25rem" }} />
                        }
                        {group.name}
                    </ListItemButton>
                </Tooltip>
            </ ListItem >
        )
    }

    const groupListRender = () => {

        const handleScroll = (scrollOffset) => {
            const totalHeight = groupList.length * 40
            const visibleHeight = 160

            const nearBottomThreshold = 1
            const remainingScroll = totalHeight - scrollOffset - visibleHeight

            if (remainingScroll < nearBottomThreshold)
                loadMoreGroups()
        }

        return (
            <>
                {groupList.length === 0 ? (
                    <Box key={"empty-list-nice-key"} sx={{ margin: "1rem" }}>Nenhum segmento encontrado...</Box>
                ) : (
                    <FixedSizeList
                        sx={{ height: "min-content !important" }}
                        className="group-list-flexbox"
                        height={400}
                        width={250}
                        itemSize={40}
                        itemCount={groupList.length}
                        overscanCount={5}
                        onScroll={({ scrollOffset }) => handleScroll(scrollOffset)}
                    >
                        {renderRow}
                    </FixedSizeList>
                )}
            </>
        )
    }

    const handleFilterChange = (event) => {
        if (timeoutId)
            clearTimeout(timeoutId)

        timeoutId = setTimeout(() => getGroups(undefined, event.target.value), 1000)
    }

    const canUserCreateContactGroup = () => {
        return UserPermissions().isAdmin || UserPermissions().isSupervisor || getSession().profile.can_create_contact_group
    }

    return (
        <>
            <>
                {canUserCreateContactGroup() &&
                    <Box sx={{ "marginBottom": ".25rem", "padding": "0 0.2rem" }}>
                        <ResponsiveCreateEnterpriseGroupButton handle={addEnterpriseGroup} />
                    </Box>
                }
                <TextField
                    className={"inputPlaceholder"}
                    placeholder="Buscar Segmentos"
                    onChange={handleFilterChange}
                    variant="outlined"
                    size="small"
                    sx={{ marginTop: ".4rem", marginBottom: ".4rem", backgroundColor: "#EFEFEF" }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    fullWidth
                />
                {groupListRender()}
                <Button ref={optionButton} className="helperButton"></Button>
                <Box ref={optionBox} className={"groupDropDown"}>
                    <Button onClick={() => editEnterpriseGroupModal(selectedGroup)} className="groupOption" disabled={!UserPermissions().isAdmin && !UserPermissions().isSupervisor && selectedGroup.owner_id !== getSession().id}>Editar</Button>
                    <Button onClick={() => selectedEnterpriseGroup(selectedGroup)} className="groupOption">Ver empresas</Button>
                    <Button onClick={() => addSelectedEnterprisesToGroup(selectedGroup)} className="groupOption">Adicionar seleção ao segmento</Button>
                    <Button onClick={() => deleteEnterpriseGroupModal(selectedGroup)} className="groupOption" disabled={!UserPermissions().isAdmin && !UserPermissions().isSupervisor && selectedGroup.owner_id !== getSession().id}>Excluir</Button>
                </Box>
            </>
        </>
    )
}

export default EnterpriseGroupModal
