import React, { useState, useMemo, useEffect } from 'react'

import { Box, IconButton, Tooltip, Button } from "@mui/material";

import AudioPlayer from "../../../../../AudioPlayer";
import ChatImage from "../../../../../ChatImage";
import ChatDocument from "../../../../../ChatDocument";
import ChatLocation from "../../../../../ChatLocation";
import ChatVideo from "../../../../../ChatVideo";
import api from '../../../../../../api';
import { getSession } from '../../../../../../auth';
import useNotification from "../../../../../Notification";
import useValidationError from '../../../../../ErrorValidation';

import MessageItem from "../../MessageItem";
import ChatStory from '../../../../../ChatStory';
import useVisibility from '../../../../../../utils/isVisible';

const isPending = (message) => {
    return 'status' in message && message.status === 'PENDING';
}

const isAudio = (message) => {
    return message.message.includes("wpp-audio");
}

const isStory = (message) => {
    return message.message.includes("[story]") &&
        message.message.includes("[/story]");
}

const isAudioWeb = (message) => {
    return message.message.includes("[audio]") &&
        message.message.includes("[/audio]");
}

const isImage = (message) => {
    return message.message.includes("wpp-image");
}

const isImageWeb = (message) => {
    return message.message.includes("[img]") &&
        message.message.includes("[/img]");
}

const isVideo = (message) => {
    return message.message.includes("wpp-video");
}

const isVideoWeb = (message) => {
    return message.message.includes("[video]") &&
        message.message.includes("[/video]");
}

const isDocument = (message) => {
    return message.message.includes("wpp-document");
}

const isDocumentWeb = (message) => {
    return message.message.includes("[url") &&
        message.message.includes("[/url]");
}

const isLocation = (message) => {
    return message.message.includes("wpp-location");
}

const isButtons = (message) => {
    return message.message.includes("[buttons");
}

const isSurvey = (message) => {
    return message.message.includes("[survey");
}

const isEmailInbox = (message) => {
    return message.message.includes("(emailinbox") || message.message.includes("(emailsubject") || message.message.includes("(emailattachment");
}

const isSpecialMessage = (message) => {
    const isSpecialMessage =
        isAudio(message) ||
        isAudioWeb(message) ||
        isImage(message) ||
        isImageWeb(message) ||
        isVideo(message) ||
        isVideoWeb(message) ||
        isDocument(message) ||
        isDocumentWeb(message) ||
        isLocation(message) ||
        isButtons(message) ||
        isSurvey(message) ||
        isEmailInbox(message) ||
        isStory(message);

    return isSpecialMessage;
}

const isEmailCloseChatMessage = (message, activeChat) => {
    return activeChat.platform === 'EMAIL' && message.message === 'Chat encerrado pelo operador.';
}

const addInfo = (status, rmessage) => {
    let className = 'media-status';
    let message = '';

    switch (status) {
        case 'PENDING':
            className += ' media-pending';
            message = 'Aprovação pendente';
            break;
        case 'REJECTED':
            className += ' media-rejected';
            message = 'Rejeitada';
            break;
        case 'APPROVED':
            className += ' media-approved';
            message = 'Aprovada';
            break;
        default:
            break;
    }

    if (message) {
        return (
            <Box className={className}>
                {message}
            </Box>
        );
    }

    return (<div></div>);
}

const applyActionMsg = (message, index, status, reloadState, buttonDisabledSetter, enterpriseId, activeChat, parentSetter = () => { }) => {
    buttonDisabledSetter(true)
    parentSetter({ loaded: false })
    reloadState()
    api.patch(`/api/enterprises/${enterpriseId}/supervised_message/${message.id}/`, {
        chat: message.chat,
        status: status
    }, {timeout: 20000}).then((res) => {
        if (res.status === 200) {
            activeChat.socket.send(JSON.stringify({
                channel: activeChat.channel ? activeChat.channel : activeChat.hash,
                message: JSON.stringify({ status: status, id: message.id }),
                user: -3
            }))

            switch (status) {
                case 'APPROVED':
                    useNotification(
                        'Sucesso!',
                        'Mensagem aprovada!',
                        'success'
                    )
                    break
                case 'REJECTED':
                    useNotification(
                        'Sucesso!',
                        'Mensagem rejeitada!',
                        'success'
                    );
                    break
                default:
                    break
            }

            message.status = status;
            parentSetter({ loaded: true })
            reloadState()
            return;
        }

        useValidationError(res.status, null);
        throw res;
    }).catch((err) => {
        parentSetter({ loaded: false })
        console.error(err);
    });
}

const addSupervisorAction = (message, index, reloadState, buttonDisabled, buttonDisabledSetter, enterpriseId, activeChat, parentSetter) => {
    return (
        <Box className="supervisor-action">
            <Tooltip title="Aprovar mídia" placement="bottom" disableInteractive>
                <Button
                    variant="contained"
                    color="success"
                    style={{ marginRight: '5px' }}
                    onClick={() => applyActionMsg(message, index, 'APPROVED', reloadState, buttonDisabledSetter, enterpriseId, activeChat, parentSetter)}
                    size="small"
                    disabled={buttonDisabled}>Aprovar</Button>
            </Tooltip>
            <Tooltip title="Rejeitar mídia" placement="bottom" disableInteractive>
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => applyActionMsg(message, index, 'REJECTED', reloadState, buttonDisabledSetter, enterpriseId, activeChat, parentSetter)}
                    size="small"
                    disabled={buttonDisabled}>Rejeitar</Button>
            </Tooltip>
        </Box>
    );
}

const getUrlFromLink = (message) => {
    var regex = /\[url=(.*?)\]\[\/url\]/
    var match = regex.exec(message)
    if (match)
        return match[1]
    else {
        if (message.startsWith("[url=") && message.endsWith("]Baixar arquivo[/url]"))
            return message.replace("[url=", "").replace("]Baixar arquivo[/url]", "")
        return message
    }
}

const renderDefault = (message, messages, index, accountcode, isManageCalls, queuesName, enterpriseId, activeChat, reloadState, buttonDisabled, buttonDisabledSetter, parentSetter, setReplying) => {
    let updateMessage = undefined;

    if (!accountcode)
        accountcode = getSession().profile.enterprise.accountcode
    if (messages) {
        updateMessage = (message_id, status, key, transcription) => {
            parentSetter({
                messages: messages.map((m) => {
                    if (m.id === message_id) {
                        m.transcription_status = status;
                        m.transcription_key = key;
                        m.transcription = transcription;
                    }
                    return m;
                })
            })
        };
    }
    return (
        <div className="message-item" key={index}>
            {isAudio(message) &&
                <AudioPlayer user={message.user_id}
                    key={index}
                    src={"/api/api/whatsapp/audio/"
                        + accountcode
                        + "/"
                        + message.message.split(":")[1].split(";")[1]
                        + "/"
                        + message.message.split(":")[1].split(";")[0]
                        + "/"
                    }
                    message={message}
                    enterpriseId={enterpriseId}
                    updateMessage={updateMessage}
                />
            }
            {isStory(message) &&
                <ChatStory
                    key={index}
                    message={message}
                    user={message.user_id}
                    queuesName={queuesName}
                    platform={activeChat?.platform}
                />
            }
            {isAudioWeb(message) &&
                <AudioPlayer user={message.user_id}
                    message={message}
                    key={index}
                    queuesName={queuesName}
                    platform={activeChat?.platform}
                    src={message.message.split("[audio]")[1].split("[/audio]")[0]}
                    enterpriseId={enterpriseId}
                    updateMessage={updateMessage}
                />
            }
            {isImage(message) &&
                <ChatImage user={message.user_id}
                    src={"/api/api/whatsapp/image/"
                        + accountcode
                        + "/"
                        + message.message.split(":")[1].split(";")[1]
                        + "/"
                        + message.message.split(":")[1].split(";")[0]
                        + "/"
                    }
                />
            }
            {isImageWeb(message) &&
                <ChatImage user={message.user_id}
                    message={message}
                    key={index}
                    queuesName={queuesName}
                    platform={activeChat?.platform}
                    src={message.message.split("[img]")[1].split("[/img]")[0]}
                />
            }
            {isVideo(message) &&
                <ChatVideo user={message.user_id}
                    src={"/api/api/whatsapp/video/"
                        + accountcode
                        + "/"
                        + message.message.split(":")[1].split(";")[1]
                        + "/"
                        + message.message.split(":")[1].split(";")[0]
                        + "/"
                    }
                />
            }
            {isVideoWeb(message) &&
                <ChatVideo user={message.user_id}
                    message={message}
                    key={index}
                    queuesName={queuesName}
                    platform={activeChat?.platform}
                    src={message.message.split("[video]")[1].split("[/video]")[0]}
                />
            }
            {isDocument(message) &&
                <ChatDocument user={message.user_id}
                    name={"Documento"}
                    message={message}
                    src={"/api/api/whatsapp/document/"
                        + accountcode
                        + "/"
                        + message.message.split(":")[1].split(";")[1]
                        + "/"
                        + message.message.split(":")[1].split(";")[0]
                        + "/"
                    }
                />
            }
            {isDocumentWeb(message) &&
                <ChatDocument user={message.user_id}
                    name={"Documento"}
                    message={message}
                    src={getUrlFromLink(message.message)}
                />
            }
            {isLocation(message) &&
                <ChatLocation user={message.user_id}
                    name={"Localização"}
                    src={"https://www.google.com/maps/search/?api=1&query="
                        + message.message.split(":")[1].split(";")[0]
                        + ","
                        + message.message.split(":")[1].split(";")[1]
                    }
                />
            }
            {isButtons(message) &&
                <MessageItem key={index}
                    setReplying={setReplying}
                    message={message}
                    user={message.user_id}
                    type="buttons"
                    queuesName={queuesName}
                    platform={activeChat?.platform}
                    activeChat={activeChat}
                />
            }
            {isSurvey(message) && !message.message.includes('[survey][field') &&
                <>
                    <MessageItem key={index}
                        setReplying={setReplying}
                        message={message}
                        user={message.user_id}
                        type="survey"
                        platform={activeChat?.platform}
                        activeChat={activeChat}
                    />
                </>
            }
            {!isSpecialMessage(message) &&
                <MessageItem key={index}
                    setReplying={setReplying}
                    message={message}
                    user={message.user_id}
                    platform={activeChat?.platform}
                    receiveURL={activeChat?.queue?.settings?.can_receive_url}
                    activeChat={activeChat}
                />
            }

            {isManageCalls && isPending(message) &&
                addSupervisorAction(message, index, reloadState, buttonDisabled, buttonDisabledSetter, enterpriseId, activeChat, parentSetter)
            }

            {!isManageCalls && isPending(message) &&
                addInfo(message.status, message, index)
            }

            {!isPending(message) &&
                addInfo(message.status, message, index)
            }
        </div>
    );
}

const renderSocialChannel = (message, index, accountcode, isManageCalls, queuesName, enterpriseId, activeChat, reloadState, buttonDisabled, buttonDisabledSetter, parentSetter, setReplying) => {
    let parsedMessage = message.message

    parsedMessage = parsedMessage.replace('[img]', '').replace('[/img]', '')
    parsedMessage = parsedMessage.replace('wpp-image:', '')
    parsedMessage = parsedMessage.replace('[video]', '').replace('[/video]', '')
    parsedMessage = parsedMessage.replace('wpp-video:', '')
    parsedMessage = parsedMessage.replace('[audio]', '').replace('[/audio]', '')
    parsedMessage = parsedMessage.replace('wpp-audio:', '')
    if (parsedMessage.includes("[url=")) {
        parsedMessage = parsedMessage.replace('[url=', '').replace('[/url]', '').slice(0, -1)
        if (parsedMessage.endsWith("]"))
            parsedMessage = parsedMessage.slice(0, -1)
    }
    parsedMessage = parsedMessage.replace('wpp-document:', '')

    return (
        <div className="message-item" key={index}>
            {isAudio(message) &&
                <AudioPlayer user={message.user_id}
                    src={parsedMessage}
                    key={index}
                />
            }
            {isAudioWeb(message) &&
                <AudioPlayer user={message.user_id}
                    src={parsedMessage}
                    key={index}
                />
            }
            {isImage(message) &&
                <ChatImage user={message.user_id}
                    src={parsedMessage}
                />
            }
            {isImageWeb(message) &&
                <ChatImage user={message.user_id}
                    src={parsedMessage}
                />
            }
            {isVideo(message) &&
                <ChatVideo user={message.user_id}
                    src={parsedMessage}
                />
            }
            {isVideoWeb(message) &&
                <ChatVideo user={message.user_id}
                    src={parsedMessage}
                />
            }
            {isDocument(message) &&
                <ChatDocument user={message.user_id}
                    name={"Documento"}
                    message={message}
                    src={parsedMessage}
                />
            }
            {isDocumentWeb(message) &&
                <ChatDocument user={message.user_id}
                    name={"Documento"}
                    src={parsedMessage}
                    message={message}
                    receiveURL={activeChat?.queue?.settings?.can_receive_url}
                />
            }
            {isButtons(message) &&
                <MessageItem key={index}
                    setReplying={setReplying}
                    message={message}
                    user={message.user_id}
                    type="buttons"
                    queuesName={queuesName}
                    platform={activeChat?.platform}
                    activeChat={activeChat}
                />
            }
            {isSurvey(message) &&
                <MessageItem key={index}
                    setReplying={setReplying}
                    message={message}
                    user={message.user_id}
                    type="survey"
                    platform={activeChat?.platform}
                    activeChat={activeChat}
                />
            }
            {isEmailInbox(message) &&
                <MessageItem key={index}
                    setReplying={setReplying}
                    message={message}
                    user={message.user_id}
                    type="emailinbox"
                    platform={activeChat?.platform}
                    receiveURL={activeChat?.queue?.settings?.can_receive_url}
                    activeChat={activeChat}
                />
            }
            {!isSpecialMessage(message) && !isEmailCloseChatMessage(message, activeChat) &&
                <MessageItem key={index}
                    setReplying={setReplying}
                    message={message}
                    user={message.user_id}
                    platform={activeChat?.platform}
                    receiveURL={activeChat?.queue?.settings?.can_receive_url}
                    activeChat={activeChat}
                />
            }

            {isManageCalls && isPending(message) &&
                addSupervisorAction(message, index, reloadState, buttonDisabled, buttonDisabledSetter, enterpriseId, activeChat, parentSetter)
            }

            {!isManageCalls && isPending(message) &&
                addInfo(message.status, message, index)
            }

            {!isPending(message) &&
                addInfo(message.status, message, index)
            }
        </div>
    );
}

const MemoMessage = ({ message, messages, index, accountcode, isManageCalls, queuesName, enterpriseId, activeChat, visibleHook, parent, parentState, setReplying }) => {

    const [isVisible, currentElement, calcVisibility] = useVisibility(0, 100, parent)
    const [buttonDisabled, setButtonDisabled] = useState(false)

    useEffect(() => {
        if (isVisible && visibleHook)
            visibleHook(message, currentElement)
        calcVisibility()
    }, [isVisible])


    const [refresh, setRefresh] = useState(false)

    const reloadState = () => { setRefresh(p => !p) }

    const messageComp = useMemo(() => {
        if (!('message' in message))
            message.message = message.msg

        // Ignore start viewer message
        if (message.message.indexOf('<<< STARTING VIEWER >>>') !== -1)
            return

        if (activeChat && 'platform' in activeChat && activeChat.platform === 'EMAIL')
            return renderSocialChannel(message, index, accountcode, isManageCalls, queuesName, enterpriseId, activeChat, reloadState, buttonDisabled, setButtonDisabled, parentState, setReplying)
        else
            return renderDefault(message, messages, index, accountcode, isManageCalls, queuesName, enterpriseId, activeChat, reloadState, buttonDisabled, setButtonDisabled, parentState, setReplying)
    }, [message.id, message.status, message.transcription, message.transcription_status, refresh, isVisible, message.iso_time, message.time, message.is_client_read, message.reaction])

    return (
        <span ref={currentElement}>
            {messageComp}
        </span>
    )
}

function areEqual({ message: prevMessage }, { message }) {
    return message === prevMessage;
}


export default MemoMessage
