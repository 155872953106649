import React, { useEffect, useState } from "react"
import {
	Box,
	Button,
	Card,
	CardContent,
	Typography,
	Autocomplete,
	TextField,
	Chip,
	CircularProgress
} from "@mui/material"

import api from "../../api"
import { getSession } from "../../auth"

import useNotification from "../Notification"

import "./styles.css"
import Loading from "../Loading"

const MyTeam = ({ refresh = () => {} }) => {
	const [users, setUsers] = useState([])
	const [team, setTeam] = useState({})
	const [loading, setLoading] = useState(false)
	const [showRealTime, setShowRealTime] = useState(false)

	useEffect(() => {
		const fetchUsers = async () => {
			
			const response = await api.get(`api/enterprises/${getSession().profile.enterprise.id}/users/`)
			setLoading(false)
			if (response.status === 200)
				setUsers(response.data
					.map((user) => ({ id: user.id, name: `${user.first_name} ${user.last_name}` }))
					.sort((a, b) => a.name.localeCompare(b.name))
				)
		}
		const fetchTeam = async () => {
			const response = await api.get(`api/users/${getSession().id}/myteam/`)
			if (response.status === 200)
				setTeam(response.data[0])
		}

		const initialize = async () => {
			setLoading(true)
			await Promise.all([fetchUsers(), fetchTeam()])		
			setLoading(false)
		}; initialize()

	}, [])

	const addToTeam = (event, user) => {
		if (Object.keys(team).length === 0) team["members_data"] = []
		if (user && !team.members_data.some((member) => member.id === user.id)) {
			setTeam((prevTeam) => ({
				...prevTeam,
				members_data: [...prevTeam.members_data, user]
			}))
		}
	}

	const removeFromTeam = (id) => {
		setTeam((prevTeam) => ({
			...prevTeam,
			members_data: prevTeam.members_data.filter((member) => member.id !== id)
		}))
	}

	const createTeam = async () => {
		setLoading(true)
		const response = await api.post(`api/enterprises/${getSession().profile.enterprise.id}/teams/`, {
			name: `${getSession().first_name} ${getSession().last_name}`, // using supervisor's name,
			members: team.members_data.map((member) => member.id),
			owner: getSession().id
		})
		setLoading(false)

		if (response.status === 201) {
			setTeam(response.data)
			useNotification("Sucesso", "Equipe criada com sucesso", "success")
			refresh()
			return
		}

		useNotification("Ops", "Algo deu errado, tente novamente", "danger")
	}

	const updateTeam = async () => {
		if (!team.id) {
			await createTeam()
			return
		}

		setLoading(true)
		const response = await api.patch(`api/enterprises/${getSession().profile.enterprise.id}/teams/${team.id}/`, {
			members: team.members_data.map((member) => member.id)
		})
		setLoading(false)

		if (response.status === 200) {
			setTeam(response.data)
			useNotification("Sucesso", "Equipe atualizada com sucesso", "success")
			refresh()
			return
		}

		useNotification("Ops", "Algo deu errado, tente novamente", "danger")
	}

	return (
		<Box className="myteam-page">
			<Loading loading={loading}/>
			<Box className="myteam-settings">
				<Box className="page-title">
					Adicione operadores à sua equipe
				</Box>
				<Autocomplete
					noOptionsText={'Não há mais operadores disponíveis para adicionar.'}
					options={users.filter(user => !(team.members_data || []).map(member=>member.id).includes(user.id))}
					getOptionLabel={(option) => option.name}
					onChange={addToTeam}
					renderInput={(params) => (
						<TextField {...params} label="Operadores" size="small" sx={{ maxWidth: "33.5rem" }} />
					)}
				/>
				<Box className="page-title">
					Minha equipe
				</Box>
				<Box className="team-members">
					{!Array.isArray(team?.members_data) || team.members_data?.length === 0 ? (
						<Box ml={1}>Sua equipe não possui nenhum operador.</Box>
					) : null}
					{Array.isArray(team?.members_data) && team.members_data
						.map((member) => ({ id: member.id, name: (!member.name) ? `${member.first_name} ${member.last_name}` : member.name }))
						.sort((a, b) => a.name.localeCompare(b.name)).map((member) => (
							<Chip key={member.id} label={member.name} style={{ margin: 4 }}
								onDelete={() => removeFromTeam(member.id)}
							/>
						))}
				</Box>
				<Button
					variant="contained"
					onClick={() => updateTeam()}
					disabled={loading || !Array.isArray(team?.members_data) || team.members_data?.length === 0}>
					{loading ? (
						<>
							<CircularProgress size={14} style={{ marginRight: 8 }} />
							Salvar
						</>
					) : (
						"Salvar"
					)}
				</Button>
			</Box>
		</Box>
	)
}

export default MyTeam
