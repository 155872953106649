import react, { useContext, useEffect, useState, useMemo } from 'react'
import { Box, IconButton, ListItem, Tooltip } from "@mui/material"
import { Edit } from '@mui/icons-material'
import ChangeField from '../../ChangeField'
import './styles.css'
import { ScreenContext } from '..'
import api from '../../../api'
import { getSession } from '../../../auth'

import dayjs from 'dayjs';

const fetchContactEnterprises = (setEnterprises) => {
    api.get(`api/enterprises/${getSession().profile.enterprise.id}/contact_enterprises/`)
        .then((res) => {
            if (res.status === 200)
                setEnterprises([{
                    "id": NaN,  
                    "name": "Remover empresa",
                },...res.data])
        })
        .catch((err) => {})
}

export const GeneralData = ({ icon, name, data, editable, onClick, tooltip, namealias, options, customField, placeholder, reloadParent=()=>{}, date=false, cpf=false }) => {

    const { enterpriseId, profile, setProfile, getClients } = useContext(ScreenContext)

    const hasclick = onClick ? { sx: { cursor: 'pointer' }, onClick: onClick } : {}

    const [edit, setEdit] = useState(false)
    const [enterprises, setEnterprises ] = useState(null)
    
    useEffect(()=>{
        if (editable && editable === 'contact_enterprise' && !enterprises){
            fetchContactEnterprises(setEnterprises)
        }
    }, [enterprises])

    const decideName = useMemo(() => {
        if (options)
            return options[data[editable]]
        else if (date)
            return (data[editable]) ? dayjs(data[editable]).format("DD/MM/YYYY") : null
        else if (data.custom_field_type === 'boolean')
            return Number(data[editable]) === 1 ? 'Sim' : 'Não'
        else if (namealias)
            return namealias
        else if (editable)
            return data[editable]
        return data
    }, [data, namealias, options, editable])

    return (
        <>
            <ListItem className='contact-general-listitem' >
                <Box className='contact-general-item-box'>
                    {tooltip ? <Tooltip title={name}><span>{icon}</span></Tooltip>
                        :
                        <Tooltip title={name}>
                            <span className='align-icons'>
                                <Box sx={{ display: 'flex' }}>{icon}</Box>
                                {!edit && <Box>{name}</Box>}
                            </span>
                        </Tooltip>
                    }
                </Box>
                {!edit ?
                    <Box className='contact-general-field'>
                        {decideName ? <Box className='contact-general-data' {...hasclick}>{decideName}</Box> : 
                        <Box className='contact-general-data placeholder' onClick={() => { setEdit(true) }}>{placeholder}</Box>}
                        {editable && <IconButton sx={{ fontSize: '.9em', padding: '.1rem' }}><Edit onClick={() => { setEdit(true) }} /></IconButton>}
                    </Box>
                    :
                    <ChangeField value={data[editable]}
                        router={`api/enterprises/${enterpriseId}/contacts/${data.profileId || data.id}/`}
                        field={editable}
                        label={name}
                        editmode={() => setEdit(false)}
                        options={customField && data.custom_field.field_type === 'boolean' ? {1: 'Sim', 0: 'Não'} : options}
                        customField={customField}
                        data={data}
                        updateField={(newdata) => {
                            
                            if (editable && editable === 'contact_enterprise' && enterprises){
                                const editdata = enterprises.find(enterprise => enterprise.id === newdata)
                                data.contact_enterprise_name = editdata?.name || ''
                                profile.contact_enterprise_name = data.contact_enterprise_name
                                if ('fetchEnterprises' in window)
                                    window.fetchEnterprises()
                            }
                            else if (editable && customField){
                                profile.custom_fields = customField
                            }
                            else if (editable && editable === 'owner'){
                                profile.owner = data.owner
                                profile.owner_name = options[newdata]
                            }
                            data[editable] = newdata
                            profile[editable] = newdata
                            setEdit(false)
                            reloadParent()
                            if ('reloadChat' in window)
                                window.reloadChat()
                        }}
                        reloadContact={(data) => {setProfile(data); setEdit(false); getClients()}}
                        contactEnterprises={enterprises}
                        fetchContactEnterprises={() => {fetchContactEnterprises(setEnterprises)}}
                        date={date}
                        cpfField={cpf}
                    />
                }
            </ListItem>

        </>
    )
}