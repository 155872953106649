import React, { useState, useEffect } from 'react';

import axios from "axios"

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    TextField,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Divider,
    Autocomplete,
    CircularProgress
} from '@mui/material';

import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { ptBR as localePTBR } from "@mui/x-date-pickers/locales"

import { number2dayweek } from '../../Report/NewDetailedRegister/detailed_register_leftmenu'

import dayjs from "dayjs"

const default_timezone = "America/Sao_Paulo"

import PropTypes from "prop-types";

import { getSession } from "../../../auth"
import api from "../../../api"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const channelTranslations = {
    "whatsapp": "WhatsApp",
    "telegram": "Telegram",
    "instagram": "Instagram",
    "email": "E-mail",
    "messenger": "Messenger",
    "sms": "SMS"
}

const Event = ({ open, title, handleClose, handleSubmit, isSupervisor, myTeam, selectedEvent, setProfile }) => {

    const [contacts, setContacts] = useState([])
    const [loading, setLoading] = useState(false)
    const [contactSearch, setContactSearch] = useState("")

    const [state, setState] = useState({
        id               : null,
        description      : "",
        long_description : "",
        target_date      : null,
        type             : "",
        target           : null,
        contact          : null,
        status           : ""
    })

    useEffect(() => {
        const source = axios.CancelToken.source()
        const debounceTimeout = setTimeout(async () => {
            setLoading(true)
            try {
                let url = `api/enterprises/${getSession().profile.enterprise.id}/contacts/?limit=25&offset=0`
                if (contactSearch)
                    url = `${url}&name__contains=${contactSearch}`
                const response = await api.get(url, { cancelToken: source.token })
                setContacts(response.data.results)
            } catch (error) {} finally {
                setLoading(false)
            }
        }, 1000)
        return () => {
            clearTimeout(debounceTimeout)
            source.cancel()
        };
    }, [contactSearch])

    useEffect(() => {
        setState({
            id               : selectedEvent.id || null,
            description      : selectedEvent.description || "",
            long_description : selectedEvent.long_description || "",
            target_date      : selectedEvent.target_date || null,
            type             : selectedEvent.type || "",
            target           : isSupervisor ? selectedEvent.target : getSession().id,
            contact          : selectedEvent.contact_data || null,
            status           : selectedEvent.status || ""
        })
    }, [selectedEvent])

    return (
        <div>
            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                disablePortal
                disableEnforceFocus
                sx={{ position:'absolute' }}
            >
                <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
                <Divider />
                <DialogContent>
                    {state.type !== "BIRTHDAY" &&
                        <>
                            <TextField
                                size="small"
                                label="Assunto"
                                fullWidth
                                type="text"
                                variant="outlined"
                                value={state.description}
                                onChange={(event) => setState((prevState) => ({ ...prevState, description: event.target.value }))}
                                className="push-s-top"
                                disabled={selectedEvent.id && selectedEvent.owner !== getSession().id}
                            />
                            <TextField
                                size="small"
                                label="Descrição"
                                fullWidth
                                type="text"
                                variant="outlined"
                                value={state.long_description}
                                onChange={(event) => setState((prevState) => ({ ...prevState, long_description: event.target.value }))}
                                className="push-top"
                                disabled={selectedEvent.id && selectedEvent.owner !== getSession().id}
                            />
                            <LocalizationProvider sx={{ justifyContent: 'space-between' }} 
                                dateAdapter={AdapterDayjs} 
                                adapterLocale={'pt-br'} 
                                localeText={localePTBR.components.MuiLocalizationProvider.defaultProps.localeText}
                            >
                                <Box className='automation-end-form-control' sx={{ width: "100%", justifyContent: 'space-between' }}>
                                    <DemoContainer components={['DatePicker']} sx={{ width: "100%" }}>
                                        <DateTimePicker
                                            sx={{ minWidth: '8rem' }}
                                            views={['day', 'month', 'year', 'hours', 'minutes']}
                                            format={"DD/MM/YYYY HH:mm"}
                                            timezone={default_timezone}
                                            timeSteps={1}
                                            slotProps={{ textField: { size: 'small', sx: { width: "100%", marginTop: "20px" } }}}
                                            value={(state.target_date) ? dayjs(state.target_date) : null}
                                            onChange={(event) => {
                                                if (event)
                                                    setState((prevState) => ({ ...prevState, target_date: event }))
                                            }}
                                            dayOfWeekFormatter={(n, date) => number2dayweek[date.$W]}
                                            disabled={selectedEvent.id && selectedEvent.owner !== getSession().id}
                                        />
                                    </DemoContainer>
                                </Box>
                            </LocalizationProvider>
                            <FormControl fullWidth size="small" className="push-top" disabled={selectedEvent.id && selectedEvent.owner !== getSession().id}>
                                <InputLabel>Tipo</InputLabel>
                                <Select
                                    value={state.type}
                                    label="Tipo"
                                    onChange={(event) => setState((prevState) => ({ ...prevState, type: event.target.value }))}
                                >
                                    {Object.entries(getSession().profile.enterprise.platforms)
                                        .filter(([key, value]) => !["id", "email_boxes", "scout", "scout_licenses", "chat_web"].includes(key) && value)
                                        .sort(([a], [b]) => a.localeCompare(b))
                                        .map(([key]) => (
                                        <MenuItem value={key.toUpperCase()}>
                                            {channelTranslations[key]}
                                        </MenuItem>
                                    ))}
                                    {getSession().profile.enterprise.has_voice && <MenuItem value="VOICE">Voz</MenuItem>}
                                </Select>
                            </FormControl>
                            {isSupervisor &&
                                <FormControl fullWidth size="small" className="push-top" disabled={selectedEvent.id && selectedEvent.owner !== getSession().id}>
                                    <InputLabel>Operador</InputLabel>
                                    <Select
                                        value={myTeam.some(member => member.id === state.target) ? state.target : getSession().id}
                                        label="Operador"
                                        onChange={(event) => setState((prevState) => ({ ...prevState, target: event.target.value }))}
                                    >
                                        <MenuItem value={getSession().id}>Para mim</MenuItem>
                                        {myTeam.sort((a, b) => a.first_name.localeCompare(b.first_name) || a.last_name.localeCompare(b.last_name)).map((member) => (
                                            <MenuItem value={member.id}>
                                                {member.first_name} {member.last_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            }
                            <Autocomplete
                                freeSolo
                                options={contacts}
                                getOptionLabel={(contact) => contact.name}
                                loading={loading}
                                onInputChange={(event, newValue) => setContactSearch(newValue)}
                                disableClearable
                                loadingText="Buscando contatos..."
                                renderInput={(params) => <TextField {...params} label="Contato" variant="outlined" size="small" className="push-top"/>}
                                value={state.contact}
                                onChange={(event, newValue) => setState((prevState) => ({ ...prevState, contact: newValue }))}
                                disabled={selectedEvent.id && selectedEvent.owner !== getSession().id}
                            />
                            {state.id &&
                                <FormControl fullWidth size="small" className="push-top">
                                    <InputLabel>Status</InputLabel>
                                    <Select
                                        value={state.status}
                                        label="Status"
                                        onChange={(event) => setState((prevState) => ({ ...prevState, status: event.target.value }))}
                                    >
                                        <MenuItem value="SCHEDULED">Agendado</MenuItem>
                                        <MenuItem value="COMPLETED">Finalizado</MenuItem>
                                        <MenuItem value="CANCELLED">Cancelado</MenuItem>
                                    </Select>
                                </FormControl>
                            }
                        </>
                    }
                    {state.type === "BIRTHDAY" &&
                        <Box sx={{ minWidth: "20rem" }}>
                            {`Aniversário do contato: `}
                            <span 
                                style={{ cursor: "pointer" }} 
                                onClick={() => { 
                                    handleClose()
                                    setProfile(state.contact)
                                }}
                            >
                                {state.contact.name}
                            </span>
                        </Box>
                    }
                </DialogContent>
                <DialogActions>
                    {state.type !== "BIRTHDAY" &&
                        <>
                            <Button
                                onClick={handleClose}
                                color="primary">
                                Cancelar
                            </Button>
                            <Button
                                onClick={() => handleSubmit(state)}
                                color="primary"
                                className="push-right"
                            >
                                Enviar
                            </Button>
                        </>
                    }
                    {state.type === "BIRTHDAY" &&
                        <Button
                            onClick={handleClose}
                            color="primary">
                            Fechar
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Event;
