import React from "react";

import Header from "../../components/Header";
import CreatePasswordForm from "../../components/Forms/CreatePasswordForm";

import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";

import AlertMobileVersion from "../../components/Dialogs/AlertMobileVersion";

const CreatePassword = () => {
  return (
    <Box display="flex">
        <Header subtitle={"Converse. Conquiste. Converta."}/>
        <CreatePasswordForm/>
        <AlertMobileVersion />
    </Box>
  );
};

export default CreatePassword;
