import React, { useEffect, useState, useMemo, useContext } from "react"

import { IMaskInput } from "react-imask"

import { Dialog, DialogTitle, DialogContent, Box, List, ListItem, ListItemText, ListItemButton, Divider } from "@mui/material"
import { DialogActions, InputAdornment, TextField, Button, Autocomplete } from "@mui/material"
import LoadingButton from "@mui/lab/LoadingButton"

import {
    DataGridPro,
    GridToolbarContainer,
    GridToolbarFilterButton,
    ptBR,
    useGridApiRef,
} from "@mui/x-data-grid-pro"
import { ptBR as corePtBR } from "@mui/material/locale"
import { createTheme, ThemeProvider } from "@mui/material/styles"

import IconButton from "@mui/material/IconButton"
import AddIcon from "@mui/icons-material/Add"
import AutorenewIcon from "@mui/icons-material/Autorenew"
import SearchIcon from "@mui/icons-material/Search"
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline"
import CloseIcon from "@mui/icons-material/Close"
import PhoneIcon from "@mui/icons-material/PhoneAndroid"
import EmailIcon from "@mui/icons-material/Email"

import api from "../../../../../api"
import Table from "../../../../Table"
import { ScreenContext } from "../../.."

import useNotification from "../../../../Notification"

import "./styles.css"

const EditGroupEnterprisesModal = (props) => {
    const { rmvObj, subsObj, updateGroups, updateEnterprises } = useContext(ScreenContext)
    const [selectionModel, updateSelectionModel] = useState([])
    const [enterprisesModel, updateEnterprisesModel] = useState([])
    const [loading, updateLoading] = useState(false)
    const [sortModel, updateSortModel] = useState()
    const [smallLoading, updateSmallLoading] = useState(false)
    const [enterprises, setEnterprises] = useState([])

    const fetchContactEnterprises = async () => {
        const response = await api.get(`/api/enterprises/${props.enterpriseId}/contact_enterprises/`)
        if (response.status === 200)
            setEnterprises(response.data)
    }

    const requestDataGroup = async () => {
        updateLoading(true)
        const url = `/api/enterprises/${props.enterpriseId}/contact_group/${props.selectedGroup.id}/simple_enterprises/`
        const res = await api.get(url, { timeout: 0 })

        if (res.data == 403) {
            useNotification(
                "Ops!",
                "Você não tem permissão para ver esse conteúdo",
                "danger"
            )
            return
        }

        updateLoading(false)
        return (res.data)
    }

    useEffect(async () => {
        const enterprisesData = await requestDataGroup()
        updateSelectionModel(enterprisesData.map(each => each.id))
        updateEnterprisesModel(enterprisesData)
        await fetchContactEnterprises()
    }, [])


    const submit = async () => {

        updateLoading(true)
        const url = `/api/enterprises/${props.enterpriseId}/contact_group/${props.selectedGroup.id}/`
        const body = {
            contact_enterprises: selectionModel
        }
        const response = await api.patch(url, body)
        updateLoading(false)

        if (response.status == 403) {
            useNotification(
                "Ops!",
                "Você não tem permissão para ver esse conteúdo",
                "danger"
            )
            return
        }

        if (response.status === 404)
            return
        
        props.submit()
    }


    const theme = createTheme({
        palette: {
            primary: { main: "#21446C" },
        },
    }, ptBR, corePtBR)

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer >
                <GridToolbarFilterButton />
            </GridToolbarContainer>
        )
    }

    const createEnterprisesColumns = () => {

        let columns = [
            { field: "name", headerName: "Nome", flex: 1, sortable: true },
            { field: "social_reason", headerName: "Razão Social", flex: 1, sortable: true },
            {
                field: "action", headerName: "Ações", flex: .25,
                renderCell: (params) => (
                    <Box>
                        <Button className="action-button delete"
                            onClick={() => removeFromGroup(params.row)}>
                            <RemoveCircleOutlineIcon />
                        </Button>
                    </Box>
                )
            },
            {
                field: "id", headerName: "Pertence ao segmento", flex: 1, sortable: true, hide: true,
                valueGetter: ({ value }) => {
                    const selecteds = props.selectedGroup.contacts.map(each => each.contact)
                    if (selecteds && selecteds.includes(value))
                        return true
                    return false
                },
            },
        ]

        return columns

    }

    const addToGroup = (enterprise) => {
        updateEnterprisesModel(prevState => subsObj(prevState, enterprise))
        updateSelectionModel(prevState => [...new Set([...prevState, enterprise.id])])
    }

    const removeFromGroup = (enterprise) => {
        updateEnterprisesModel(prevState => prevState.filter(each => each.id != enterprise.id))
        updateSelectionModel(prevState => rmvObj(prevState, enterprise.id))
    }

    return (
        <div>
            {
                <Dialog keepMounted
                    open={props.open}
                    aria-labelledby="customized-dialog-title"
                    fullWidth
                    disablePortal
                    disableEnforceFocus
                    sx={{position:"absolute"}}
                >
                    <DialogTitle id="customized-dialog-title" className={"group-flexbox"} >
                        <span>{props.title}: {props.selectedGroup.name}</span> <Button sx={{ "minWidth": "2rem", "padding": "0" }} onClick={props.handleClose} ><CloseIcon /></Button>
                    </DialogTitle>
                    <Divider />
                    <DialogContent sx={{ padding: "1rem" }}>
                        <>
                            <Box className="category-title push-bottom">Empresas</Box>
                            <div>
                                <Box sx={{ width: "100%", height: "300px" }}>
                                    <ThemeProvider theme={theme}>
                                        <DataGridPro
                                            columns={createEnterprisesColumns()}
                                            rows={enterprisesModel}
                                            loading={loading}
                                            disableSelectionOnClick
                                            components={{ Toolbar: CustomToolbar }}
                                            onSelectionModelChange={(newSelectionModel) => {
                                                updateSelectionModel(newSelectionModel)
                                            }}
                                            selectionModel={selectionModel}
                                            density={"compact"}
                                            sortModel={sortModel}
                                            onSortModelChange={(newSortModel) => updateSortModel(newSortModel)}
                                        />
                                    </ThemeProvider>
                                </Box>
                            </div>
                            <Box className="category-title push-top push-bottom">Adicionar</Box>
                            <Autocomplete
                                options={enterprises}
                                getOptionLabel={(option) => option.name || ""}
                                onChange={(event, newValue) => addToGroup(newValue)}
                                renderInput={(params) => (
                                    <TextField {...params} label="Buscar empresas" size="small" />
                                )}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                            />
                        </>
                    </DialogContent>
                    <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                        <LoadingButton onClick={props.handleClose} color="primary">
                            Cancelar
                        </LoadingButton>
                        <LoadingButton onClick={submit} color="primary">
                            {props.buttonText}
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            }
        </div>
    )
}

export default EditGroupEnterprisesModal
