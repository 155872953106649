import React, { useMemo } from 'react'
import { Box } from '@mui/material'


import dayjs from 'dayjs'

import RenderAction from './renderaction';
import RenderDetail from './renderdetail';

import { platformIcon } from '../../Report/NewDetailedRegister/contact_graph_exception';

const actions = {
    'WHATSAPP': (...params) => ({ name: 'Atendimento Whatsapp', actions: ['download', 'see'],  detail: 'messages' }),
    'TELEGRAM': (...params) => ({ name: 'Atendimento telegram', actions: ['download', 'see'],  detail: 'messages' }),
    'INSTAGRAM': (...params) => ({ name: 'Atendimento Instagram', actions: ['download', 'see'],  detail: 'messages' }),
    'MESSENGER': (...params) => ({ name: 'Atendimento Messenger', actions: ['download', 'see'],  detail: 'messages' }),
    'INSTANTCHAT': (...params) => ({ name: 'Atendimento Web', actions: ['download', 'see'],  detail: 'messages' }),
    'EMAIL': (...params) => ({ name: 'Troca de E-mails', actions: ['download', 'see'],  detail: 'messages' }),
    'WHATSAPP_ATIVO': (...params) => ({ name: 'Contato ativo de whatsapp', actions: ['download', 'see'],  detail: 'messages' }),
    'MSTEAMS': (...params) => ({ name: 'Atendimento Msteams', actions: ['download', 'see'],  detail: 'messages' }),
    'INSTANTVOICE': (...params) => ({ name: 'Conversa telefônica', actions: ['downloadvoice'],  detail: 'inout' }),
    'SMS': (...params) => ({ name: 'SMS disparado', actions: ['download', 'see'], detail: 'messages' }),
    'API': (...params) => ({ name: 'Registro da api', actions: ['download', 'see'], detail: 'messages' }),
    'INTEGRATION': (...params) => ({ name: 'Registro de integração', actions: ['download', 'see'],  detail: 'messages' }),
    'AUTOMATION': (...params) => ({ name: 'Registro de automação', actions: ['download', 'see'],  detail: 'messages' }),
    'SCOUT': (...params) => ({name: 'Último contato scout', actions: ['movescout'], detail: 'scout_last_message'})
}

const generateDateV2 = (value) => {
    const date = dayjs(value).locale('pt-BR').format('MMMM DD, YYYY')
    return date[0].toLocaleUpperCase() + date.slice(1, date.length)
}

const generateTime = (value) => {
    return value.substr(0, 5)
}

const HistoricRow = ({ data, contact, setParent }) => {

    const component = data.platform_entrypoint ? useMemo(() => actions[data.platform_entrypoint](data, contact), []) : null

    return (
        <>
            {component &&
                <Box className='historic-row-mainbox'>
                    <Box className='historic-row-icon'>
                        <span class='historic-row-icon-svg'>{platformIcon(data.platform_entrypoint)}</span>
                        <span class='historic-row-segment-line'></span>
                    </Box>
                    <Box className='historic-row-mainbody'>
                        <Box className='historic-row-header'>
                            <Box className='historic-row-title'>{component.name}</Box>
                            <Box className='historic-time-actions'>
                                <Box>
                                    {generateTime(data.time)} - {generateDateV2(data.date)}
                                </Box>
                                <Box className='historic-row-actions'>
                                    {component?.actions.map(action => (
                                        <RenderAction action={action} data={data} contact={contact} setParent={setParent} />
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                        <Box className='historic-row-details'>
                            {component?.detail &&
                                <RenderDetail detail={component.detail} data={data} contact={contact} />
                            }
                        </Box>
                    </Box>
                </Box>}
        </>
    )
}

export default HistoricRow