import React, { useState, useEffect } from "react"

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Divider, 
    Tooltip, 
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from "@mui/material"

import LoadingButton from "@mui/lab/LoadingButton"

import PropTypes from "prop-types"

import CloseIcon from "@mui/icons-material/Close"

import "./styles.css"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MergeEnterpriseDialog = ({ open, enterprises, selectedEnterprises, handleClose, handleSubmit }) => {

    const [enterprise1, setEnterprise1] = useState({})
    const [enterprise2, setEnterprise2] = useState({})
    const [finalEnterprise, setFinalEnterprise] = useState({})

    const renderKeys = {
        "name"           : "Nome",
        "social_reason"  : "Razão Social",
        "cnpj"           : "CNPJ",
        "telephone"      : "Telefone",
        "email"          : "E-mail",
        "address"        : "Endereço",
        "address_number" : "Número",
        "address_extra"  : "Complemento",
        "neighborhood"   : "Bairro",
        "city"           : "Cidade",
        "estate"         : "Estado",
        "postal_code"    : "CEP"
    }

    useEffect(() => {
        const enterprise1 = enterprises.find((enterprise) => enterprise.id === selectedEnterprises[0])
        const enterprise2 = enterprises.find((enterprise) => enterprise.id === selectedEnterprises[1])
        setEnterprise1(enterprise1)
        setEnterprise2(enterprise2)
        setFinalEnterprise(enterprise1)
    }, [])

    const checkSelected = (key, value, priority) => {
        if (value === finalEnterprise[key] && enterprise1[key] === enterprise2[key])
            return priority
        return value === finalEnterprise[key]
    }

    const emptyBox = () => {
        return (
            <Box style={{ height: "20px" }}></Box>
        )
    }

    const updateFinalEnterprise = (key, value) => {
        setFinalEnterprise(prev => ({ ...prev, [key]: value }))
    }

    useEffect(() => {}, [finalEnterprise])

    return (
        <div>
            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                disableEnforceFocus
            >
                <DialogTitle id="alert-dialog-slide-title" className={"group-flexbox"}>
                    <span>Mesclar empresas</span>
                    <Tooltip title="Fechar" >
                        <Button className="action-button">
                            <CloseIcon onClick={handleClose} />
                        </Button>
                    </Tooltip>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    A seguir, selecione o valor que deve ser priorizado no resultado final:
                    <Box className="merge-content">
                        <List>
                            {Object.keys(enterprise1).map((key) => (
                                (key in renderKeys)
                                    ? <ListItem disablePadding key={`${key}-1`} className={checkSelected(key, enterprise1[key], true) ? "item-selected" : ""}>
                                        <ListItemButton onClick={() => updateFinalEnterprise(key, enterprise1[key])}>
                                            <ListItemText 
                                                primary={renderKeys[key]} 
                                                secondary={Array.isArray(enterprise1[key]) ? (enterprise1[key][0] || emptyBox()) : (enterprise1[key] || emptyBox())}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                    : null
                            ))}
                        </List>
                        <List>
                            {Object.keys(enterprise2).map((key) => (
                                (key in renderKeys)
                                    ? <ListItem disablePadding key={`${key}-2`} className={checkSelected(key, enterprise2[key], false) ? "item-selected" : ""}>
                                        <ListItemButton onClick={() => updateFinalEnterprise(key, enterprise2[key])}>
                                            <ListItemText 
                                                primary={renderKeys[key]} 
                                                secondary={Array.isArray(enterprise2[key]) ? (enterprise2[key][0] || emptyBox()) : (enterprise2[key] || emptyBox())}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                    : null
                            ))}
                        </List>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <LoadingButton onClick={() => handleSubmit(enterprise1, enterprise2, finalEnterprise)} color="primary">
                        Enviar
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

MergeEnterpriseDialog.propTypes = {
    open: PropTypes.bool,
    selectedEnterprises: PropTypes.array,
    handleClose: PropTypes.func,
    handleSubmit: PropTypes.func
};

export default MergeEnterpriseDialog;
