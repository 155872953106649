import { Dialog, DialogContent, DialogTitle, Button, Box, Tooltip, List, ListItem } from '@mui/material'
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import api from '../../../api';
import { getSession } from '../../../auth';
import useNotification from '../../Notification'

export const TranscriptionButton = ({
  message_id,
  message_transcription,
  message_transcription_status,
  message_type,
  transcriptionEndpointURL,
  updateMessage,
}) => {
  if (message_type !== "audioMessage") return null;
  const user = getSession();
  const audioTranscription = user.profile.enterprise?.audio_transcription;

  let tooltipTitle = "";
  if (audioTranscription !== true) {
    tooltipTitle = "Funcionalidade desabilitada. Entre em contato com seu gerente de contas.";
  } else if (message_transcription && message_transcription.length > 0) {
    tooltipTitle = message_transcription;
  } else if (message_transcription_status === "IN_PROGRESS") {
    tooltipTitle = "Transcrição em andamento. Clique para conferir o status.";
  } else {
    tooltipTitle = "Clique aqui para iniciar a transcrição";
  }

  return (
    <Tooltip
      title={tooltipTitle}
      placement="right"
      key={`audio-tooltip-${message_id}`}
    >
      <Box
        className="scout-transcription"
        style={{ cursor: "pointer" }}
        onClick={async () => {
          if (audioTranscription !== true) return;
          if (message_transcription && message_transcription.length > 0) return;

          const res = await api.post(transcriptionEndpointURL);
          if (res.status === 201) {

            updateMessage(message_id, "IN_PROGRESS", res.data.key, null);

            useNotification(
              "Transcrição",
              "Transcrição iniciada, por favor aguarde.",
              "success"
            );
          } else if (res.status === 200) {

            if (res.data.status == "COMPLETED") {
              updateMessage(message_id, "COMPLETED", null, res.data.transcription);

              } else {
                useNotification(
                  "Transcrição",
                  "Transcrição em progresso, por favor aguarde.",
                  "success"
                );
              }


            } else if (res.status >= 400) {
              useNotification(
                "Transcrição",
                `Erro! ${res.data.error ? res.data.error : "Erro desconhecido"}`,
                "danger"
              );
            }

            // if (setLoading) setLoading(false);
          }}
        >
        <RecordVoiceOverIcon sx={{ fontSize: "1.2em" }} />
      </Box>
    </Tooltip>
  );
};