import React, { useState, useEffect } from "react"

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    TextField,
    Box,
    Divider,
    Typography,
    Tooltip,
    Autocomplete,
    CircularProgress
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close"

import PropTypes from "prop-types"

import { CnpjMask, TelephoneMask, PostalCodeMask } from "../../../utils/masks"

import api from "../../../api"
import { getSession } from "../../../auth"

import useNotification from "../../Notification"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})

let timeoutId

const ContactEnterpriseDialog = ({ open, handleClose, createEnterprise, updateEnterprise, selectedEnterprise }) => {

    const [state, setState] = useState({
        id                : null,
        name              : "",
        socialReason      : "",
        telephone         : "",
        email             : "",
        cnpj              : "",
        address           : "",
        addressNumber     : "",
        addressExtra      : "",
        neighborhood      : "",
        city              : "",
        estate            : "",
        postalCode        : "",
        groupsSelectOpen  : false,
        contactGroups     : [],
        groupsLoading     : false,
        selectedGroups    : [],
        nextGroupsPointer : null
    })

    const submit = () => (selectedEnterprise !== null && selectedEnterprise !== undefined) ? updateEnterprise(state) : createEnterprise(state)

    useEffect(() => {
        if (selectedEnterprise !== null && selectedEnterprise !== undefined) {
            setState((prevState) => {
                const updatedState = Object.keys(selectedEnterprise).reduce((acc, key) => {
                    const parsedKey = key.replace(/_([a-z])/g, (match, group1) => group1.toUpperCase())
                    if (prevState.hasOwnProperty(parsedKey)) 
                        acc[parsedKey] = selectedEnterprise[key] || ""
                    return acc
                }, {})

                return { ...prevState, ...updatedState, selectedGroups: selectedEnterprise.groups }
            })
        }
    }, [selectedEnterprise])

    const getGroups = async (search, groupsUrl) => {
        if (groupsUrl === undefined || (typeof search !== 'object' && search !== undefined)) {
            setState((prevState) => ({
                ...prevState,
                groupsSelectOpen: true,
                groupsloading: true,
                contactGroups: []
            }))
        } else {
            setState((prevState) => ({
                ...prevState,
                groupsSelectOpen: true,
                groupsloading: true
            }))
        }

        const enterpriseId = getSession().profile.enterprise.id

        let url
        if (groupsUrl !== undefined)
            url = `/api/${groupsUrl.split('/api/')[1]}`
        else {
            url = `/api/enterprises/${enterpriseId}/contact_group/noserialize/`
            if (typeof search !== 'object' && search !== undefined)
                url = `${url}?search=${search}`
        }

        setState((prevState) => ({...prevState, groupsLoading: true }))
        const response = await api.get(url)
        setState((prevState) => ({...prevState, groupsLoading: false }))
        
        if (response.status === 200)
            setState((prevState) => ({
                ...prevState, 
                groupsSelectOpen: true,
                nextGroupsPointer: response.data.next,
                contactGroups: [...prevState.contactGroups, ...response.data.results.filter(newItem => 
                    ! prevState.contactGroups.some(existing => existing.id === newItem.id)
                )]
            }))
    }

    const canRemoveContactGroupRelation = async (group) => {
        const enterpriseId = getSession().profile.enterprise.id
        const response = await api.post(`/api/enterprises/${enterpriseId}/contact_group/can_remove_relation/`, {
            group_id: group.id
        })
        return response.status === 200
    }

    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = event.target;
        if (state.nextGroupsPointer && scrollHeight - scrollTop <= clientHeight + 5)
            getGroups(undefined, state.nextGroupsPointer)
    }

    return (
        <Dialog
            open={open}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            disablePortal
            disableEnforceFocus
            fullWidth
            maxWidth="sm"
            sx={{ position: "absolute" }}
        >
            <DialogTitle id="alert-dialog-slide-title" className={'group-flexbox'}>
                {selectedEnterprise !== null ? "Empresa" : "Nova Empresa"}
                <Tooltip title="Fechar">
                    <Button className="action-button">
                        <CloseIcon onClick={handleClose} />
                    </Button>
                </Tooltip>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <TextField
                        size="small"
                        label="Nome"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={state.name}
                        onChange={(event) => setState((prevState) => ({ ...prevState, name: event.target.value }))}
                        className="push-bottom"
                    />
                    <TextField
                        size="small"
                        label="Razão Social"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={state.socialReason}
                        onChange={(event) => setState((prevState) => ({ ...prevState, socialReason: event.target.value }))}
                        className="push-bottom"
                    />
                    <TextField
                        size="small"
                        label="CNPJ"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={state.cnpj}
                        onChange={(event) => setState((prevState) => ({ ...prevState, cnpj: event.target.value }))}
                        className="push-bottom"
                        InputProps={{ inputComponent: CnpjMask }}
                    />
                    <Box style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                        <TextField
                            size="small"
                            label="Telefone"
                            fullWidth
                            type="text"
                            variant="outlined"
                            value={state.telephone}
                            onChange={(event) => setState((prevState) => ({ ...prevState, telephone: event.target.value }))}
                            className="push-bottom"
                            InputProps={{ inputComponent: TelephoneMask }}
                        />
                        <TextField
                            size="small"
                            label="E-mail"
                            fullWidth
                            type="text"
                            variant="outlined"
                            value={state.email}
                            onChange={(event) => setState((prevState) => ({ ...prevState, email: event.target.value }))}
                            className="push-bottom"
                        />
                    </Box>
                    <Typography marginBottom="1rem" fontSize="19px" fontWeight="500" color="#21446c">
                        Endereço
                    </Typography>
                    <TextField
                        size="small"
                        label="Endereço"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={state.address}
                        onChange={(event) => setState((prevState) => ({ ...prevState, address: event.target.value }))}
                        className="push-bottom"
                    />
                    <Box style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                        <TextField
                            size="small"
                            label="Número"
                            fullWidth
                            type="text"
                            variant="outlined"
                            value={state.addressNumber}
                            onChange={(event) => setState((prevState) => ({ ...prevState, addressNumber: event.target.value }))}
                            className="push-bottom"
                        />
                        <TextField
                            size="small"
                            label="Complemento"
                            fullWidth
                            type="text"
                            variant="outlined"
                            value={state.addressExtra}
                            onChange={(event) => setState((prevState) => ({ ...prevState, addressExtra: event.target.value }))}
                            className="push-bottom"
                        />
                        <TextField
                            size="small"
                            label="Bairro"
                            fullWidth
                            type="text"
                            variant="outlined"
                            value={state.neighborhood}
                            onChange={(event) => setState((prevState) => ({ ...prevState, neighborhood: event.target.value }))}
                            className="push-bottom"
                        />
                    </Box>
                    <Box style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                        <TextField
                            size="small"
                            label="Cidade"
                            fullWidth
                            type="text"
                            variant="outlined"
                            value={state.city}
                            onChange={(event) => setState((prevState) => ({ ...prevState, city: event.target.value }))}
                            className="push-bottom"
                        />
                        <TextField
                            size="small"
                            label="Estado"
                            fullWidth
                            type="text"
                            variant="outlined"
                            value={state.estate}
                            onChange={(event) => setState((prevState) => ({ ...prevState, estate: event.target.value }))}
                            className="push-bottom"
                        />
                        <TextField
                            size="small"
                            label="CEP"
                            fullWidth
                            type="text"
                            variant="outlined"
                            value={state.postalCode}
                            onChange={(event) => setState((prevState) => ({ ...prevState, postalCode: event.target.value }))}
                            className="push-bottom"
                            InputProps={{ inputComponent: PostalCodeMask }}
                        />
                    </Box>
                    <Typography marginBottom="1rem" fontSize="19px" fontWeight="500" color="#21446c">
                        Segmentos
                    </Typography>
                    <Autocomplete
                        multiple
                        sx={{ marginTop: "1rem", marginBottom: "1rem" }}
                        size="small"
                        open={state.groupsSelectOpen}
                        onOpen={getGroups}
                        onClose={() => setState((prevState) => ({ ...prevState, groupsSelectOpen: false }))}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        getOptionLabel={(option) => option.name || ""}
                        options={state.contactGroups || []}
                        loading={state.groupsLoading}
                        filterOptions={(x) => x}
                        onInputChange={(event) => {
                            if (timeoutId)
                                clearTimeout(timeoutId)
                            timeoutId = setTimeout(() => getGroups(event.target.value), 1000)
                        }}
                        value={state.selectedGroups || []}
                        onChange={async (event, newValue) => {
                            const removedItem = state.selectedGroups?.find((item) => !newValue.some((newItem) => newItem.name === item.name))
                            if (removedItem) {
                                const canRemove = await canRemoveContactGroupRelation(removedItem)
                                if (canRemove)
                                    setState((prevState) => ({ ...prevState, selectedGroups: newValue }))
                                else
                                    useNotification(
                                        "Atenção!", 
                                        "Você não tem permissão para remover este segmento.", 
                                        "warning"
                                    )
                            } else {
                                setState((prevState) => ({ ...prevState, selectedGroups: newValue }))
                            }
                        }}
                        ListboxProps={{ onScroll: handleScroll }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Segmentos"
                                slotProps={{
                                    input: {
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {state.groupsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    },
                                }}
                            />
                        )}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={submit} color="primary" className="push-right" disabled={! state.name}>
                    Enviar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ContactEnterpriseDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func
};

export default ContactEnterpriseDialog
