import { Box, Grid, InputLabel, TextField } from '@mui/material'
import React from 'react'
import ButtonBlock from '../MediaInteractiveBody/ButtonBlock'
import EyeIcon from '@mui/icons-material/Visibility';
import AttachmentItem from '../../../../Chat/MessagesArea/ChatWindow/MessageItem/attachment';

const VisualizeTemplate = ({ template, category, canVisualize, processed }) => {

    const matchRegLen = value => {
        const matcher = value.match(/{{\d+}}/g)
        if (matcher)
            return matcher.length
        return 0
    }

    return (
        <>
            <Box className={'templatePadding'}>
                {(template.name || category) && <Box className={'templateFlexBox'}>
                    {template.name && <TextField sx={{ flex: 1 }}
                        id="display-name"
                        label="Nome"
                        defaultValue={template.name}
                        variant="standard"
                        inputProps={{ readOnly: true }}
                    />}
                    {category && <TextField sx={{ flex: 1 }}
                        id="display-category"
                        label="Categoria"
                        defaultValue={category}
                        variant="standard"
                        inputProps={{ readOnly: true }}
                    />}
                </Box>}

                {template.components.map(component => (
                    <>
                        {component.type == 'HEADER' ?
                            <>
                                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ marginTop: '0.5rem' }}>
                                    {component.format === 'TEXT' ? (
                                        <>
                                            <TextField
                                                multiline
                                                rows="2"
                                                size="small"
                                                label="Cabeçalho"
                                                fullWidth
                                                inputProps={{ readOnly: true }}
                                                type="text"
                                                variant="outlined"
                                                value={component.text}
                                            />
                                            <Box className={'templates-footer flexedGap'}>
                                                <Box className="character-counter positionUnset" >
                                                    {`${component.text.length} / 60 caracteres`}
                                                </Box>
                                                <Box className="character-counter positionUnset" >
                                                    {`${matchRegLen(component.text)} / 1 variáveis`}
                                                </Box>
                                            </Box>
                                        </>
                                    )
                                        :
                                        <>
                                            <TextField
                                                size="small"
                                                label="Cabeçalho"
                                                fullWidth
                                                inputProps={{ readOnly: true }}
                                                type="text"
                                                variant="outlined"
                                                value={
                                                    component.format == 'IMAGE'
                                                        ? 'Imagem'
                                                        : component.format == 'DOCUMENT' ?
                                                            'Documento' : 'Vídeo'}
                                            />
                                            {canVisualize &&
                                                <Box className={'mediaVisualize'} onClick={() => window.open(component.link)}>
                                                    Visualizar:
                                                    <EyeIcon />
                                                </Box>
                                            }
                                        </>
                                    }
                                </Grid>
                            </> : null}
                        {component.type == 'BODY' ?
                            <>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <TextField
                                        id="body-textarea"
                                        multiline
                                        rows="4"
                                        size="small"
                                        label="Corpo"
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        inputProps={{ readOnly: true }}
                                        value={component.text}
                                    />
                                    <Box className={'templates-footer flexedGap'}>
                                        <Box className="character-counter positionUnset" >
                                            {`${component.text.length} / 1024 caracteres`}
                                        </Box>
                                        {!processed &&
                                            <Box className="character-counter positionUnset" >
                                                {`${matchRegLen(component.text)} / 10 variáveis`}
                                            </Box>}
                                    </Box>
                                </Grid>
                            </> : null}
                        {component.type == 'FOOTER' ?
                            <>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <>
                                        <TextField
                                            multiline
                                            rows="2"
                                            size="small"
                                            label="Rodapé"
                                            fullWidth
                                            type="text"
                                            variant="outlined"
                                            inputProps={{ readOnly: true }}
                                            value={component.text}
                                        />
                                        <Box className={'templates-footer flexedGap'}>
                                            <Box className="character-counter positionUnset" >
                                                {`${component.text.length} / 60 caracteres`}
                                            </Box>
                                        </Box>
                                    </>
                                </Grid>
                            </> : null}
                        {component.type == 'BUTTONS' ?
                            <>
                                <>
                                    <Box className={'button-box-new-template'} >
                                        {component.buttons.map(button =>
                                        (
                                            <Box>
                                                <ButtonBlock
                                                    parent={button}
                                                    updateParent={() => { }}
                                                    rerender={() => { }}
                                                    display={true}
                                                    editable={false}
                                                />
                                            </Box>
                                        )
                                        )}
                                    </Box>
                                </>
                            </> : null}
                        {component.type === 'ATTACHMENTS' ?
                            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ marginTop: '0.5rem' }}>
                                <InputLabel>Anexos</InputLabel>
                                <Box sx={{ display: 'flex' }}>
                                    {component.files.map(file => (
                                        <AttachmentItem data={file} />
                                    ))}
                                </Box>
                            </Grid>
                            : null}
                    </>
                ))}
            </Box>
        </>
    )

}

export default VisualizeTemplate