import React from "react";

import { saveToken } from "../../auth";

import { Box } from "@mui/material";

import Header from "../../components/Header";
import ResetPassword from "../../components/Forms/ResetPassword";

import AlertMobileVersion from "../../components/Dialogs/AlertMobileVersion";

class ClassResetPassword extends React.Component {

  render() {
    return (
      <Box display="flex">
        <Header subtitle={"Converse. Conquiste. Converta."}/>
        <ResetPassword/>
        <AlertMobileVersion />
      </Box>
    );
  } 
}

export default ClassResetPassword;
