import React, { useEffect, useReducer, useState, useContext } from 'react'

import {
    Dialog, DialogActions, DialogContent, DialogTitle, Divider, Tooltip, Button
    , FormControl, InputLabel, Select, MenuItem, Box, TextField
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import defaultReducer from '../../../../../utils/defaultReducer'
import { getSession } from '../../../../../auth'
import api from '../../../../../api'
import LoadingButton from '@mui/lab/LoadingButton';
import style from './styles.module.css'
import { ScreenContext } from '../../..'
import useNotification from '../../../../Notification'
import Loading from '../../../../Loading'
import { Dropdown } from '../../../Templates/ParameterFiller'
import EmailFooter from '../../../../Chat/MessagesArea/ChatWindow/EmailFooter'
import VariableTextField from '../../../../Input/VariableTextField'
import { mimeTypes } from '../../../../Settings/Email/AttachmentsDialog/mimetypes'

const enterpriseId = getSession()?.profile?.enterprise?.id

const getEmailConfs = async (setState, setLoading) => {
    setLoading(true)
    const res = await api.get(`/api/enterprises/${enterpriseId}/email/data/`)
    setLoading(false)
    if (res.status === 200)
        return setState({ config: res.data })
    return false
}

export const postEmailAttachments = async (message, attachments, sender) => {
    const data = new FormData()
    attachments.map(each => data.append('files', each))
    data.append('sender', sender)
    const res = await api.post(`/api/enterprises/${enterpriseId}/email/attachments/`, data)
    if (res.status === 200) {
        message += res.data.join('')
    }
    else if (res.status === 400) {
        const res_message = JSON.parse(res.data.join(''))
        const type = res_message.type
        const extension = mimeTypes[res_message.type] || res_message.type
        useNotification(
            'Erro!',
            res_message.msg.replace(type, extension),
            'danger'
        );
        return -1
    }
    else {
        useNotification(
            'Erro!',
            'Ocorreu um erro ao importar os anexos.',
            'danger'
        );
        return -1
    }

    return message
}

const submit = async (emailMessageData, state, close, setLoading) => {

    if (emailMessageData && emailMessageData.attachments?.length) {
        emailMessageData.bodyTextContent = await postEmailAttachments(emailMessageData.bodyTextContent, emailMessageData.attachments, state.email)
        if (emailMessageData.bodyTextContent === -1) {
            return -1
        }

    }
    setLoading(true)
    const res = await api.post(`/api/enterprises/${enterpriseId}/email/notification/`, { ...emailMessageData, ...state, clients: state.clients })
    setLoading(false)
    if (res.status === 200) {
        useNotification(
            'Sucesso!',
            'Email enviado aos contatos selecionados.',
            'success'
        );
        close()
        return
    }
    useNotification(
        'Ops!',
        res.data?.message || 'Email não enviado.',
        'danger'
    );
}

export const ShouldSendEmail = (state) => {
    const message = []

    if (state.email === '')
        message.push(<Box>Obrigatório escolher caixa de e-mail.</Box>)

    if (message.length === 0)
        return ''
    return message
}

export const getEmailDefaultState = {
    clients: [],
    subject: '',
    msg: '',
    email: '',
    config: [],
    queue: '',
    agent: getSession()?.profile?.agent?.user,
    emailMessageData: {}
}

export const SendEmailContent = ({ setLoading, parent, setParent, queues, emailMessageData }) => {

    const [ready, setReady] = useState(false)

    useEffect(() => {
        getEmailConfs(setParent, setLoading)
    }, [])

    useEffect(()=>{
        if ('setEmailData' in window){
            window.setEmailData(parent.emailMessageData)
            setReady(true)
        }
    }, [parent])

    return (
        <>
            <FormControl fullWidth size="small">
                <InputLabel id="email-simple-select-label">Origem</InputLabel>
                <Select
                    labelId="email-simple-select-label"
                    id="email-simple-select"
                    label="Origem"
                    onChange={({ target }) => setParent({ email: target.value })}
                    value={parent.email}
                    size='small'
                >
                    {/*<MenuItem key='default-selected-email' value={''}>Selecione*</MenuItem>*/}
                    {parent.config.map(({ id, imap_username }) => (
                        <MenuItem key={`${id}-select-email`} value={id}>{imap_username}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Fila</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Fila"
                    onChange={({ target }) => setParent({ queue: target.value })}
                    value={parent.queue}
                >
                    <MenuItem key='default-selected-queue' value={0}>Nenhuma</MenuItem>
                    {Object.entries(queues).map(([key, value]) => (
                        <MenuItem key={`${value}-select-queue`} value={value}>{key}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <VariableTextField
                label='Assunto'
                sx={{ width: '100%' }}
                value={parent.subject}
                onChange={({ target }) => setParent({ subject: target.value })}
                onVariableSelect={(value) => setParent({ subject: `${parent.subject} ${value}` })}
                size='small'
                inputProps={{
                    maxLength: 100
                }}
            />
            {<EmailFooter 
            asBuiltIn={(data) => {
                if (ready)
                    emailMessageData(data)
            }} />}
        </>
    )

}


const SendEmail = ({ title, handleClose, clients, queues, positionFixed }) => {

    const [state, setState] = useReducer(defaultReducer, {
        ...getEmailDefaultState,
        clients: clients
    })
    
    const shouldSendEmail = ShouldSendEmail(state)

    const [loading, setLoading] = useState(false)

    return (
        <>
            <Loading loading={loading} />
            <Dialog
                open={true}
                disablePortal
                disableEnforceFocus
                onClose={() => {}}
                sx={{ position: positionFixed ? 'fixed': 'absolute' }}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
                    <span>{title ? title : "Enviar e-mail aos contatos selecionados"}</span>
                    <Tooltip title="Fechar" >
                        <Button className="action-button">
                            <CloseIcon onClick={handleClose} />
                        </Button>
                    </Tooltip>
                </DialogTitle>
                <Divider />
                <DialogContent className={style.defaultSpacing}>
                    <SendEmailContent setLoading={setLoading} parent={state} setParent={setState} queues={queues} emailMessageData={(data) => {state.emailMessageData = data}}/>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        onClick={handleClose}
                        color="primary"
                    >
                        Cancelar
                    </LoadingButton>
                    <Tooltip title={shouldSendEmail} placement="top" describeChild={true}>
                        <span><LoadingButton
                            onClick={() => submit(state.emailMessageData, state, handleClose, setLoading)}
                            color="primary"
                            disabled={shouldSendEmail}
                        >
                            Enviar
                        </LoadingButton></span>
                    </Tooltip>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default SendEmail