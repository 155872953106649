import React, { useState, useContext } from 'react'

import LoadingButton from '@mui/lab/LoadingButton'

import { Dialog } from '@mui/material'
import { ScreenContext } from '../..'

import CreateCampaignModal from '../../CreateCampaignModal'

import AddIcon from '@mui/icons-material/Add';

const CreateCampaign = () => {
    const {fetchCampaigns ,queues, originNumbers, templates, enterpriseId} = useContext(ScreenContext)
    const [showCampaignModal, updateShowCampaignModal] = useState(false)
    
    const submit = () => {
        updateShowCampaignModal(false)
        return(true)
    }

    return (
        <>
            {showCampaignModal && 
                <CreateCampaignModal open={showCampaignModal}
                    title="Nova campanha"
                    buttonText="Adicionar"
                    queues={queues}
                    senders={originNumbers}
                    templates={templates}
                    enterpriseId={enterpriseId}
                    handleClose={() => {}}
                    submit={submit}
                />
            }
            <LoadingButton 
                className="button-notify nomargin"
                onClick={() => updateShowCampaignModal(true)}
                variant={"contained"}
                color="warning"
                startIcon={<AddIcon />}
                sx={{ marginBottom: "0 !important" }}
            >
                Nova campanha
            </LoadingButton>
        </>
    )
}

export default CreateCampaign