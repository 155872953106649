import React, { useEffect, useLayoutEffect, useReducer } from 'react'

import {
    Button, Dialog, DialogTitle, DialogContent, Divider,
    Tooltip, Box, DialogActions, TextField, FormControl, InputLabel, Select, MenuItem
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { getSession } from '../../../../../auth'
import defaultReducer from '../../../../../utils/defaultReducer'
import useNotification from '../../../../Notification'
import api from '../../../../../api'
import Loading from '../../../../Loading'
import LoadingButton from '@mui/lab/LoadingButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ptBR as corePtBR } from '@mui/material/locale';
import {
    DataGridPro,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    gridClasses,
    LicenseInfo,
    ptBR
} from '@mui/x-data-grid-pro';

import {
    createTheme,
    ThemeProvider,
    StyledEngineProvider
} from '@mui/material/styles';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationDialogCaptcha from '../../../../Dialogs/ConfimationDialogCaptcha'
import { possible_channels } from '../../../../ManageCalls/FilterComponent'
import { replaceMCDU } from '../../../../Settings/Vivre/Vivremenu/replaceMCDU'

import CheckIcon from '@mui/icons-material/Check';

const theme = createTheme({
    palette: {
        primary: { main: '#21446C' },
    },
}, ptBR, corePtBR);

const submitDelete = async (data, state, setState) => {
    const enterprise = getSession()?.profile?.enterprise
    setState({ loading: true })
    const res = await api.delete(`api/enterprises/${enterprise.id}/contact_channels/${state.id}/`)
    if (res.status === 204) {
        useNotification('Sucesso!',
            `Canal excluído com sucesso!`,
            'success')
        setState({ delete: null })
        initialize(data, setState)
        return
    }
    setState({ loading: false })
    let message = 'Algo deu errado, tente novamente'
    if (res.status == 404){
        message = 'Não é possível excluir um canal automático por aqui, altere diretamente no campo do contato.'
    }
    useNotification(
        'Ops!',
        message,
        'danger'
    )
}

const submitSave = async (data, newstate, setState) => {
    const enterprise = getSession()?.profile?.enterprise
    setState({ loading: true })
    let res = null
    if (newstate.id)
        res = await api.patch(`api/enterprises/${enterprise.id}/contact_channels/${newstate.id}/`, newstate)
    else
        res = await api.post(`api/enterprises/${enterprise.id}/contact_channels/`, { ...newstate, contact: data.id })
    if ([201, 200].includes(res.status)) {
        setState({ rows: res.data, loading: false, edit: null })
        useNotification(
            'Sucesso!',
            `Canal ${data.id ? 'alterado' : 'criado'} com sucesso!`,
            'success'
        )
        initialize(data, setState)
        return
    }
    setState({ loading: false })
    let message = 'Algo deu errado, tente novamente'
    if (res.status === 404){
        message = 'Não é possível alterar um canal automático por aqui, altere diretamente no campo do contato.'
    } else if (res.status === 400){
        const non_field = res.data.non_field_errors
        const validation_error = res.data.validation_error
        if (non_field  && non_field[0] === 'The fields enterprise, nick must make a unique set.')
            message = 'Número/Email/Chave já cadastrada para essa conta.'
        else if (validation_error)
            message = 'Utilize um e-mail válido'
    }
    useNotification(
        'Ops!',
        message,
        'danger'
    )
}

const initialize = async (data, setState) => {
    const enterprise = getSession()?.profile?.enterprise
    setState({ loading: true })
    const res = await api.get(`api/enterprises/${enterprise.id}/contact_channels/${data.id}/contact/`)
    if (res.status === 200) {
        setState({ rows: res.data, loading: false })
        return
    }
    setState({ loading: false })
    useNotification(
        'Ops!',
        'Algo deu errado, tente novamente',
        'danger'
    )

}

const CustomToolbar = (setState) => {

    return (
        <GridToolbarContainer className={gridClasses.toolbarContainer}>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <LoadingButton
                size="small"
                component="label"
                startIcon={<AddCircleIcon />}
                onClick={() => { setState({ edit: {} }) }}
            >
                Adicionar
            </LoadingButton>
        </GridToolbarContainer>
    );
}

const defColumns = (setState) => {
    const columns = []

    columns.push({ field: 'nick', headerName: 'Número', flex: 1 })
    columns.push({ field: 'channel', headerName: 'Canal', width: 64,
        renderCell: ({value}) => {
            return <Tooltip title={value}>
                <Box>{replaceMCDU(value)}</Box>
            </Tooltip>
        }
     })
    columns.push({
        field: 'automatic', headerName: 'Auto', width: 64,
        renderCell: ({ value }) => {
            if (value) {
                return (<CheckIcon className="icon-answered" />);
            }
            return (<CloseIcon className="icon-not-answered" />);
        }
    })
    columns.push({
        field: 'actions', headerName: 'Ações', width: 64,
        renderCell: ({ row }) => (
            <>
                {(!row.automatic) &&
                    <>
                        <Tooltip title="Editar" >
                            <Button
                                className="action-button"
                                onClick={() => { setState({ edit: row }) }}
                            >
                                <EditIcon />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Excluir">
                            <Button className="action-button delete"
                                onClick={() => { setState({ delete: row }) }}>
                                <DeleteIcon />
                            </Button>
                        </Tooltip>
                    </>
                }
            </>
        )
    })

    return columns
}

const EditContactChannels = ({ data, handleClose, submit }) => {

    const [state, setState] = useReducer(defaultReducer, {
        id: 0,
        nick: '',
        channel: '',
        ...data
    })

    const allParamsFilled = (state.nick !== '' && state.channel !== '') ? '' : 'Preencha todos os campos'

    return (
        <>
            <Dialog keepMounted
                open={true}
                aria-labelledby="customized-dialog-title"
                disablePortal
                disableEnforceFocus
                sx={{ position: 'absolute' }}
                maxWidth='xs'
                fullWidth
            >
                <DialogTitle id="customized-dialog-title" className={'group-flexbox'}>
                    <Tooltip title={`${data.id ? 'Editar' : 'Adicionar'} canal`}>
                        <span sx={{
                            maxQidth: '15rem',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>{data.id ? 'Editar' : 'Adicionar'} canal</span>
                    </Tooltip>
                    <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={handleClose} >
                        <CloseIcon />
                    </Button>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <TextField
                            sx={{ flex: .7 }}
                            label='Número/Email/Chave'
                            size='small'
                            fullWidth
                            type='text'
                            value={state.nick}
                            onChange={(e) => setState({ nick: e.target.value })}
                            inputProps={{
                                maxLength: 100,
                            }}
                        />
                        <FormControl
                            fullWidth
                            size="small"
                            sx={{ flex: .3 }}
                        >
                            <InputLabel id="demo-simple-select-label">Canal</InputLabel>
                            <Select
                                sx={{ '& .MuiInputBase-input': { display: 'flex', maxWidth: '2rem' } }}
                                size='small'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Canal"
                                onChange={(event) => setState({ channel: event.target.value })}
                                value={state.channel}
                            >
                                {Object.entries(possible_channels).map(([key, value]) => (
                                    <MenuItem key={key} value={key}>{replaceMCDU(key)} - {value}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                    <Button
                        onClick={handleClose}
                        color="primary"
                    >
                        Cancelar
                    </Button>
                    <Tooltip title={allParamsFilled}>
                        <span>
                            <Button
                                onClick={() => { submit(state) }}
                                disabled={allParamsFilled}
                                color="primary"
                            >
                                Salvar
                            </Button>
                        </span>
                    </Tooltip>

                </DialogActions>

            </Dialog>
        </>
    )
}

const ContactChannels = ({ data, handleClose }) => {

    const [state, setState] = useReducer(defaultReducer, {
        rows: [],
        loading: false,
        edit: null,
        delete: null
    })

    useLayoutEffect(() => {
        initialize(data, setState)
    }, [])

    return (
        <>
            <Loading loading={state.loading} />
            <Dialog keepMounted
                open={true}
                aria-labelledby="customized-dialog-title"
                disablePortal
                disableEnforceFocus
                sx={{ position: 'absolute', '& .MuiPaper-root': { height: '100%' } }}
                maxWidth='sm'
                fullWidth
            >
                <DialogTitle id="customized-dialog-title" className={'group-flexbox'}>
                    <Tooltip title={`Canais: ${data.name || ''}`}>
                        <span sx={{
                            maxQidth: '15rem',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>Canais: {data.name || ''}</span>
                    </Tooltip>
                    <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={handleClose} >
                        <CloseIcon />
                    </Button>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Box className="settings-body-bot" >
                        <StyledEngineProvider injectFirst>
                            <ThemeProvider theme={theme}>
                                <DataGridPro
                                    sx={{ height: '100%' }}
                                    pageSize={100}
                                    pagination
                                    disableSelectionOnClick
                                    disableDensitySelector
                                    density="compact"
                                    columns={defColumns(setState)}
                                    rows={state.rows}
                                    components={{
                                        Toolbar: () => CustomToolbar(setState),
                                    }}
                                />
                            </ThemeProvider>
                        </StyledEngineProvider>
                    </Box>
                </DialogContent>
            </Dialog>

            {state.edit && <EditContactChannels data={state.edit} handleClose={() => setState({ edit: null })} submit={(edited) => submitSave(data, edited, setState)} />}
            {state.delete && <ConfirmationDialogCaptcha
                open={true}
                maxWidth='xs'
                fullWidth={true}
                title={`Remover ${state.delete.nick}`}
                description={<Box>Deseja excluir o número {state.delete.nick} do canal {state.delete.channel} do contato 
                {data.name}? Para isso, digite <span style={{fontWeight: 'bold'}}>remover</span> no campo abaixo.</Box>}
                captcha='remover'
                handleClose={() => setState({ delete: null })}
                handleSubmit={() => submitDelete(data, state.delete, setState)}
            />}
        </>
    )
}

export default ContactChannels

