import React from "react";

import { saveToken } from "../../auth";

import { Box } from "@mui/material";

import Header from "../../components/Header";
import LoginForm from "../../components/Forms/LoginForm";

import AlertMobileVersion from "../../components/Dialogs/AlertMobileVersion";

class Login extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Box display="flex">
        <Header subtitle={"Converse. Conquiste. Converta."}/>
        <LoginForm/>
      </Box>
    );
  } 
}

export default Login;
