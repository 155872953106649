import React from "react";
import {
    Box,
    Grid,
    TextField,
    Button,
    Chip,
    Tooltip,
    IconButton,
    ListItem,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    ListItemButton,
    List
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import {
    DataGridPro,
    LicenseInfo,
    ptBR,
    gridClasses,
    GridToolbarContainer,
    useGridApiRef,
    gridVisibleSortedRowEntriesSelector,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
    useGridApiContext,
    GridRow,
    useGridSelector,
    gridFilteredDescendantCountLookupSelector,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarExport,
    getGridNumericOperators
} from '@mui/x-data-grid-pro';
import {
    getGridBooleanOperators,
    getGridDateOperators,
    getGridSingleSelectOperators,
    getGridStringOperators,
    getGridNumericOperator
} from '@mui/x-data-grid-pro'
import { ptBR as corePtBR } from '@mui/material/locale';
import {
    createTheme,
    ThemeProvider,
    StyledEngineProvider
} from '@mui/material/styles';

import { FileDrop } from 'react-file-drop';

import LogoImage from '../../../assets/images/kwik_white_bg_resized.png';

import ExportIcon from '@mui/icons-material/GetApp';
import GraphIcon from '@mui/icons-material/Equalizer';
import TableIcon from '@mui/icons-material/TableChart';
import ChatIcon from '@mui/icons-material/Chat';
import EditIcon from '@mui/icons-material/Edit';
import TrashIcon from '@mui/icons-material/Delete';
import DeleteIcon from '@mui/icons-material/HighlightOff';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InstantChatIcon from '@mui/icons-material/Language';
import PlayIcon from '@mui/icons-material/PlayCircle';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import CutIcon from '@mui/icons-material/ContentCut';
import TranscriptionIcon from '@mui/icons-material/AudioFile';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import PauseIcon from '@mui/icons-material/Pause';
import CampaignIcon from '@mui/icons-material/Campaign';
import TableRowsIcon from '@mui/icons-material/TableRows';
import ApprovalIcon from '@mui/icons-material/Approval';
import UploadIcon from '@mui/icons-material/Upload';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SendIcon from '@mui/icons-material/Send';
import SendReportDialog from "./SendReportDialog";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EyeIcon from '@mui/icons-material/Visibility';
import MicrosoftIcon from '@mui/icons-material/Groups';
import EmailIcon from '@mui/icons-material/Email';

import ConfirmationDialog from "../../Dialogs/Confirmation";
import HistoryMessagesDialog from "../../Dialogs/HistoryMessagesDialog";
import AutomatedReportDialog from "../../Dialogs/AutomatedReportDialog";
import Templates from "../../Crm/Templates";

import ReportGraphs from "../../ReportGraphs";
import Loading from '../../Loading';
import useValidationError from '../../ErrorValidation';
import useNotification from '../../Notification';
import ResponsiveButton from '../../Crm/ResponsiveButton';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import ApiIcon from '@mui/icons-material/DataObject';

import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import { notificationError } from "../../Crm/TabTables/NotificationTable";

import * as moment from "moment";

import api from '../../../api';

import { getSession } from "../../../auth";

import GraphicsSettings from "./graphicsSettings";
import FastFiltersDialog from "./FastFiltersDialog";

import { read, utils, writeFile } from 'xlsx';

import { getNotificationColumns } from "../../Crm/TabTables/NotificationTable";

import "./styles.css";

LicenseInfo.setLicenseKey(process.env.REACT_APP_DATAGRID_LICENSE_KEY);

const theme = createTheme({
    palette: {
        primary: { main: '#21446C' },
    },
}, ptBR, corePtBR);

const translateSystemName = {
    'sys_timeout': "Desligada por “timeout”",
    'sys_timeout_fds': "Desligada por “timeout” (final de semana)",
    'sys_fora_hora': "Fora do horário de atendimento"
}

const filterOperators = getGridStringOperators().filter(
    (operator) => ['contains', 'equals'].includes(operator.value)
)
const dateOperators = getGridDateOperators().filter(
    (operator) => ['after', 'before'].includes(operator.value)
)
const booleanOperators = getGridBooleanOperators()
const choiceOperators = getGridSingleSelectOperators().filter(
    (operator) => ['is', 'not'].includes(operator.value)
)
const numericOperators = getGridNumericOperators().filter(
    (operator) => ['=', '>', '>=', '<', '<='].includes(operator.value)
)
numericOperators.map(each => { each.value = each.value.replace('=', '|') })

export const oldFeaturescheduleReport = (period, recipients, hour, weekdays, months, days, active,
     reportType, dataColumns, fieldsExport, showAutomatedReportType, enterpriseId, 
     urlFilter, notificationObjSort, notificationObjFilters, dateStartTime, dateEndTime) => {

    const usedHeaders = dataColumns.filter(each => fieldsExport.includes(each.field)).map(each => ({ "value": each.field, "header": each.headerName }))
    const haveMessages = usedHeaders.find(each => each.value == 'messages')

    const data = {
        _type         : showAutomatedReportType,
        period        : period,
        recipients    : recipients,
        minutes       : hour.split(':').pop(),
        hour          : hour.split(':')[0],
        year          : (new Date).getFullYear(),
        active        : active,
        is_xlsx       : reportType == 'xlsx' ? true : false,
        have_messages : haveMessages ? true : false
    };

    if (days.length != 0)
        data['days'] = days.join(',');
    if (months.length != 0)
        data['months'] = months.join(',');
    if (weekdays.length != 0)
        data['week_days'] = weekdays.join(',');

    let url = `/api/enterprises/${enterpriseId}/automated_report/?filter=True`

    if (urlFilter && showAutomatedReportType !== 'notification')
        url += urlFilter
    else {
        if (notificationObjFilters['items'].length !== 0) {
            notificationObjFilters['items'].forEach((each) => {
                url += `&${each['columnField']}__${each['operatorValue']}=${each['value']}`
            })
        }
        if (notificationObjSort.length !== 0) {
            const field = notificationObjSort[0]['field']
            const sort  = notificationObjSort[0]['sort']
            url += `&sort=${field}__${sort}`
        }
    }

    const startDate = moment(dateStartTime).format("YYYY-MM-DD").toString()
    const endDate = moment(dateEndTime).format("YYYY-MM-DD").toString()    
    const startTime = moment(dateStartTime).format("HH:mm:ss").toString()
    const endTime = moment(dateEndTime).format("HH:mm:ss").toString()
    url += `&date__gte=${startDate}&date__lte=${endDate}&time__gte=${startTime}&time__lte=${endTime}`

    const res = api.post(url, data, { timeout: 0 })
        .then(async (res) => {
            // if (res.status === 201) {
            //     setState({ showAutomatedReport: false });
            //     setAutomatedReports();
            //     useNotification(
            //         'Sucesso!',
            //         'Relatório agendado com sucesso.',
            //         'success'
            //     );
            //     return;
            // }
            if (res.status === 200) {
                //setState({ showEmailInputDialog: false });
                useNotification(
                    'Sucesso!',
                    'O relatório será enviado para o e-mail escolhido assim que processado.',
                    'success'
                );
                return res
            }
            throw res;
        })
        .catch(async (err) => {
            console.error(err);
            if (err.status === 400) {
                useNotification(
                    'Atenção!',
                    'Já existe um agendamento com esse período de relatório e filtros.',
                    'warning'
                );
                return;
            }
            await useNotification(
                'Ops!',
                'Ocorreu um erro no sistema',
                'danger'
            );
        });

    return res
}

const CustomGridTreeDataGroupingCell = (props) => {
    const { id, field, rowNode } = props;
    const apiRef = useGridApiContext();
    const filteredDescendantCountLookup = useGridSelector(
        apiRef,
        gridFilteredDescendantCountLookupSelector,
    );

    const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

    const handleKeyDown = (event) => {
        if (event.key === ' ') {
            event.stopPropagation();
        }
        if (isNavigationKey(event.key) && !event.shiftKey) {
            apiRef.current.publishEvent(GridEvents.cellNavigationKeyDown, props, event);
        }
    };

    const handleClick = (event) => {
        apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
        apiRef.current.setCellFocus(id, field);
        event.stopPropagation();
    };

    return (
        <Box sx={{ ml: rowNode.depth * 4 }}>
            <div>
                {filteredDescendantCount == 1 &&
                    <Button
                        onClick={handleClick}
                        onKeyDown={handleKeyDown}
                        tabIndex={-1}
                        size="small"
                    >
                        Ver {filteredDescendantCount} transferência
                    </Button>
                }
                {filteredDescendantCount > 1 &&
                    <Button
                        onClick={handleClick}
                        onKeyDown={handleKeyDown}
                        tabIndex={-1}
                        size="small"
                    >
                        Ver {filteredDescendantCount} transferências
                    </Button>
                }
            </div>
        </Box>
    );
}

const formatRowDateTime = (params) => {
    let date = params.row.date;
    let dateParts = date.split('-');

    let year = dateParts[0];
    let month = dateParts[1];
    let day = dateParts[2];

    let time = params.row.time;
    let timeParts = time.split(':');

    let hours = timeParts[0];
    let minutes = timeParts[1];
    let seconds = timeParts[2];

    let newDate = new Date(year, month - 1, day, hours, minutes, seconds)

    return newDate.toLocaleString('pt-BR').replace(',', '');
}

const getQueueNameAndDesc = (params) => {
    if ('queue_data' in params.row) {
        const queue_name = params.row.queue_name;
        const queue_desc = params.row.queue_data.description;
        return `${queue_desc} (${queue_name})`
    }
    return params.row.queue_name;
}

const getAgentNameAndCode = (params) => {
    if ('agent_data' in params.row) {
        const agent_code = params.row.agent;
        const agent_name = params.row.agent_data.name;
        return `${agent_name} (${agent_code})`
    }
    return params.row.agent;
}


const SurveyCell = ({ type, children }) => {
    return (<Box className="survey-cell">
        <Box className="answers-survey">
            <Box className={
                type === 'BUILDER' ? 'answer answer-green' :
                    type === 'CSAT' && Number(children) >= 4 ? 'answer answer-green' :
                        type === 'CSAT' && Number(children) >= 3 ? 'answer answer-yellow' :
                            type === 'CSAT' && Number(children) < 3 ? 'answer answer-red' :
                                type === 'NPS' && Number(children) >= 9 ? 'answer answer-green' :
                                    type === 'NPS' && Number(children) >= 7 ? 'answer answer-yellow' :
                                        'answer answer-red'
            }>
                {children}
            </Box>
        </Box>
    </Box>)
}

class DetailedRegister extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            enterpriseId: 0,
            filters: {},
            dataReports: [],
            filteredDataReports: [],
            dataColumns: [],
            graphics: false,
            schedule: false,
            reportSelected: null,
            dateStartTime: null,
            dateEndTime: null,
            pageSize: 25,
            automatedPageSize: 0,
            showChatHistory: false,
            showSettingsGraph: false,
            historyMessages: [],
            platform: null,
            uniqueId: null,
            lastDateTime: null,
            nick: null,
            loading: false,
            generateLoading: false,
            selectedFilters: {},
            activeGraphics: {},
            uniqueidAndHour: {},
            fieldsExport: [],
            voiceFieldsExport: [],
            accountcode: null,
            hasTransfers: false,
            renderKey: 0,
            objFilters: { items: [] },
            notificationObjFilters: { items: [] },
            objSort: [],
            notificationObjSort: [],
            reportSelectType: 'chat',
            reportType: 'chat',
            voiceDataColumns: [],
            voiceData: [],
            notificationDataColumns: [],
            notificationData: [],
            showAutomatedReport: false,
            showAutomatedReportType: 'chat',
            automatedReportsColumns: [],
            automatedReports: [],
            playingAudio: { id: null, audio: null },
            smallLoading: false,
            urlFilter: '',
            visibleColumnsChatModel: { items: [] },
            visibleColumnsVoiceModel: { items: [] },
            visibleColumnsNotificationModel: { items: [] },
            showSchedulerDeleteDialog: false,
            showSchedulerEditDialog: false,
            selectedSchedulerRow: null,
            authenticate: false,
            files: [],
            authSeal: {},
            showFastFilters: false,
            selectedChatRows: [],
            selectedNotifyUsers: [],
            showNotifyTemplates: false,
            queues: {},
            templates: [],
            notifyNumbers: [],
            offset: 0,
            rowCount: 0,
            page: 0,
            showEmailInputDialog: false,
            graphRows: [],
            filteredGraphRows: [],
            voiceQueues: []
        };

        this.groupingColDef = {
            field: 'transfer',
            headerName: 'Transferência',
            renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />
        };

        this.filtersName = {};
        this.apiRef = this.props.apiRef;

        this.lastMessagesColStatus = false;

    }

    calculateScreenHeight = () => {
        const screenHeight = window.screen.height > 1200 ? 15 :
            window.screen.height > 1100 ? 15 :
                window.screen.height > 1000 ? 15 :
                    window.screen.height > 900 ? 8 :
                        window.screen.height > 800 ? 6 :
                            window.screen.height > 700 ? 4 : 2;

        return screenHeight;
    }

    formatPhoneNumber = (string) => {
        if (string.length < 11)
            return string
        const regex = /^([0-9]{2})([0-9]{4,5})([0-9]{4})$/;
        let str = string.replace(/[^0-9]/g, "")
        if (str.slice(0, 2) === '55')
            str = str.slice(2, 13)

        return str.replace(regex, "($1) $2-$3");
    }

    getQueueNameAndDescToXLSX = (params) => {
        if ('queue_data' in params) {
            const queue_name = params.queue_name;
            const queue_desc = params.queue_data.description;
            return `${queue_desc} (${queue_name})`
        }
        return params.queue_name;
    }

    getAgentNameAndCodeToXLSX = (params) => {
        if ('agent_data' in params) {
            const agent_code = params.agent;
            const agent_name = params.agent_data.name;
            return `${agent_name} (${agent_code})`
        }
        return params.agent;
    }

    generateXLSX = () => {
        const usedHeaders = this.state.dataColumns.filter(each => this.state.fieldsExport.includes(each.field)).map(each => ({ "value": each.field, "header": each.headerName }))

        let usedData
        if (this.state.filteredDataReports.length !== 0)
            usedData = JSON.parse(JSON.stringify(this.state.filteredDataReports))
        else
            usedData = JSON.parse(JSON.stringify(this.state.dataReports))

        usedData.map(each => { each['date_time'] = [each.date, each.time].join(' ') })
        usedData.map(each => { each['queue_name'] = this.getQueueNameAndDescToXLSX(each) })
        usedData.map(each => { each['agent'] = this.getAgentNameAndCodeToXLSX(each) })
        usedData.map(each => { each['labels'] = each.labels?.map(each => each.name).join(', ') })
        usedData.map(each => { each['message'] = String(each.message).substring(0, 32767) })
        usedData.map(each => { each['queue_duration'] = each.queue_duration - each.survey_duration })

        const newrows = []
        for (let x = 0; x < usedData.length; x++) {
            const row = usedData[x]
            const newrow = {}
            for (let y = 0; y < usedHeaders.length; y++) {
                newrow[usedHeaders[y].header] = row[usedHeaders[y].value]
            }
            newrows.push(newrow)
        }

        const worksheet = utils.json_to_sheet(newrows);
        const workbook = utils.book_new()
        utils.book_append_sheet(workbook, worksheet, "arquivo")
        const filename = `kwik_${moment().format("DD-MM-YYYY_HH-mm-ss").toString()}.xlsx`
        writeFile(workbook, filename, { compression: true });
    }

    getQueues = () => {
        const queues = {}
        getSession().profile.queues.forEach((each) => {
            queues[each.description] = each.name
        })
        return queues
    }


    getVoiceQueues = async () => {
        const res = await api.get(`api/enterprises/${this.state.enterpriseId}/queues/voice/`)

        if (res.status === 200) {
            const data = Object.fromEntries(res.data.map(each => {
                const name = each.name.split('_').pop()
                return ([name, `${each.description} (${name})`])
            }))
            this.setState({ voiceQueues: data })
        }

    }

    getTemplates = async () => {
        if (this.state.enterpriseId === 0) return

        let templates

        await api.get(`/api/enterprises/${this.state.enterpriseId}/templates/`, { timeout: 0 })
            .then((res) => {
                if (res.status == 200) {
                    templates = res.data.waba_templates.filter((template) => {
                        return (template.status == 'approved' &&
                            template.language == 'pt_BR' &&
                            !template.name.includes('sample_'));
                    });
                    return;
                }
                throw res;
            })
            .catch((err) => {
                console.error(err);
            });

        return templates
    }

    getNotifyNumbers = () => {
        return getSession().profile.enterprise.channels.map(obj => obj.phone_number)
    }

    notifyUsers = async () => {
        const numbers = new Set()

        this.setState({ loading: true })

        this.state.selectedChatRows.forEach((row) => {
            if (row.platform_entrypoint === 'WHATSAPP')
                numbers.add(row.origin.match(/\d+/g).join(''))
        })

        let queues = this.state.queues
        if (Object.keys(queues).length === 0)
            queues = this.getQueues()

        let templates = this.state.templates
        if (templates.length === 0)
            templates = await this.getTemplates()

        let notifyNumbers = this.state.notifyNumbers
        if (notifyNumbers.length === 0)
            notifyNumbers = this.getNotifyNumbers()

        this.setState({
            selectedNotifyUsers: [...numbers],
            showNotifyTemplates: true,
            queues: queues,
            templates: templates,
            notifyNumbers: notifyNumbers,
            loading: false
        })
    }

    downloadReport = (style, reportType) => {
        this.setState({ showEmailInputDialog: style, showAutomatedReportType: reportType })
    }

    CustomToolbar = () => {
        return (
            <GridToolbarContainer className={gridClasses.toolbarContainer}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <>
                    <LoadingButton
                        startIcon={<FilterAltIcon />}
                        size="small"
                        onClick={() => this.setState({ showFastFilters: true })}
                    >
                        Filtros Rápidos
                    </LoadingButton>
                </>
                <GridToolbarExportContainer>

                    <ListItem onClick={() => this.downloadReport('csv', 'chat')} className={'menuItem'}>Exportar como CSV</ListItem>
                    <ListItem onClick={() => this.downloadReport('xlsx', 'chat')} className={'menuItem'}>Exportar como XLSX</ListItem>
                </GridToolbarExportContainer>
                <>
                    <LoadingButton
                        startIcon={<CampaignIcon />}
                        size="small"
                        onClick={() => {
                            this.setState({
                                showAutomatedReport: true,
                                showAutomatedReportType: 'chat'
                            });
                        }}
                    >
                        Agendar
                    </LoadingButton>
                </>
                <>
                    <LoadingButton
                        startIcon={<SendIcon />}
                        size="small"
                        onClick={this.notifyUsers}
                    >
                        Notificar
                    </LoadingButton>
                </>
            </GridToolbarContainer>
        );
    }

    voiceCustomToolbar = () => {
        return (
            <GridToolbarContainer className={gridClasses.toolbarContainer}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarExportContainer>
                    <ListItem onClick={() => this.downloadReport('csv', 'calls')} className={'menuItem'}>Exportar como CSV</ListItem>
                    <ListItem onClick={() => this.downloadReport('xlsx', 'calls')} className={'menuItem'}>Exportar como XLSX</ListItem>
                </GridToolbarExportContainer>
                <>
                    <LoadingButton
                        startIcon={<CampaignIcon />}
                        size="small"
                        onClick={() => {
                            this.setState({
                                showAutomatedReport: true,
                                showAutomatedReportType: 'calls'
                            });
                        }}
                    >
                        Agendar
                    </LoadingButton>
                </>
            </GridToolbarContainer>
        );
    }

    notificationCustomToolbar = () => {
        return (
            <GridToolbarContainer className={gridClasses.toolbarContainer}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarExportContainer>
                    <ListItem onClick={() => this.downloadReport('csv', 'notification')} className={'menuItem'}>Exportar como CSV</ListItem>
                    <ListItem onClick={() => this.downloadReport('xlsx', 'notification')} className={'menuItem'}>Exportar como XLSX</ListItem>
                </GridToolbarExportContainer>
            </GridToolbarContainer>
        );
    }

    automatedReportsCustomToolbar = () => {
        return (
            <GridToolbarContainer className={gridClasses.toolbarContainer}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
            </GridToolbarContainer>
        );
    }

    CustomRow = (props) => {
        const apiContext = useGridApiContext();
        const rowNode = apiContext.current.getRowNode(props.rowId);

        return (
            <GridRow
                {...props}
                className={(rowNode?.depth ?? 0) > 0 ? 'custom-row' : ''}
            />
        );
    }

    fetchHistoryMessages = (uniqueid) => {
        this.setState({ loading: true });

        api.get(`/api/LHC/chats/${uniqueid}/`, { timeout: 0 })
            .then((res) => {
                if (res.status !== 200) {
                    useValidationError(res.status, null);
                } else {
                    this.setState({
                        showChatHistory: true,
                        historyMessages: res.data.messages,
                        platform: res.data.platform,
                        uniqueId: uniqueid,
                        lastDateTime: res.data.time,
                        nick: res.data.nick
                    });
                }

                this.setState({ loading: false });
            })
            .catch((err) => {
                console.error(err);
                this.setState({ loading: false });
            });
    }

    handleReportRow = (item, uniqueidAndHour) => {
        item.path = [item.uniqueid + '_' + item.time];
        item.actions = 'notTransfer_' + item.uniqueid + '_';
        uniqueidAndHour[item.uniqueid] = item.time;

        if (item.platform_entrypoint !== 'INSTANTVOICE' && item.status === 'Atendida' && !item.queue_name)
            item.status = 'ChatBot';
        if (item.platform_entrypoint !== 'EMAIL' && item.status === 'Desistencia')
            item.status = 'Desistência';
        if (item.platform_entrypoint === 'EMAIL' && item.status === 'Desistencia' || item.status === 'Nao Atendida')
            item.status = 'Não Atendida';

        item.origin = this.formatPhoneNumber(item.origin);
        item.dst_num = this.formatPhoneNumber(item.dst_num);

        if (item.platform_entrypoint === 'INSTANTVOICE') {
            item.queue_name = this.state.voiceQueues[item.queue_name]
        }

        if (item.survey) {
            item.survey_type = 'BUILDER'
            if (item.survey.type)
                item.survey_type = item.survey.type.toUpperCase()

            item.survey_numbers = item.survey.answers.filter(each => each.question !== 'comment')
            item.survey_comment = item.survey.answers.filter(each => each.question === 'comment')?.map(each => each.value)?.join(', ')

            let aux = 1
            item.survey_numbers.some(each => {
                if (each.question == -1) {
                    item[`survey_number_${aux}`] = each.value
                    aux += 1
                } else item[`survey_number_${each.question}`] = each.value
            })
        } else {
            item.survey = '';
        }
    }

    fetchNotificationReport = (dateStartTime, dateEndTime) => {
        this.setState({ loading: true })

        let url = `/api/enterprises/${this.state.enterpriseId}/notifications/pre_filtered/?limit=${this.state.pageSize}&offset=${this.state.offset}`

        if (this.state.notificationObjFilters['items'].length !== 0) {
            this.state.notificationObjFilters['items'].forEach((each) => {
                url += `&${each['columnField']}__${each['operatorValue']}=${each['value']}`
            })
        }

        if (this.state.notificationObjSort.length !== 0) {
            const field = this.state.notificationObjSort[0]['field']
            const sort = this.state.notificationObjSort[0]['sort']
            url += `&sort=${field}__${sort}`
        }

        api.post(url, {
            start_datetime: moment(dateStartTime).format("YYYY-MM-DD HH:mm").toString(),
            end_datetime: moment(dateEndTime).format("YYYY-MM-DD HH:mm").toString()
        }, { timeout: 0 }).then((res) => {
            this.setState({
                notificationData: res.data.results,
                reportType: 'notification',
                loading: false,
                generateLoading: false,
                rowCount: res.data.count
            })
        }).catch((err) => {
            useValidationError(err.status, null)
            this.setState({
                loading: false,
                generateLoading: false
            })
        })
    }

    onNotificationFilterChange = (model) => {
        const dateStartTime = document.getElementById('dateStartTime').value
        const dateEndTime = document.getElementById('dateEndTime').value

        this.setState({ notificationObjFilters: model }, () => {
            let shouldFetch = true
            this.state.notificationObjFilters['items'].forEach((item) => {
                if (item.value === undefined)
                    shouldFetch = false
            })
            if (shouldFetch)
                this.fetchNotificationReport(dateStartTime, dateEndTime)
        })
    }

    onNotificationSortChange = (model) => {
        const dateStartTime = document.getElementById('dateStartTime').value
        const dateEndTime = document.getElementById('dateEndTime').value

        this.setState({ notificationObjSort: model }, () => {
            this.fetchNotificationReport(dateStartTime, dateEndTime)
        })
    }

    fetchVoiceReport = (dateStartTime, dateEndTime) => {
        this.setState({ loading: true });

        let url = `/api/enterprises/${this.state.enterpriseId}/reports/call/detailed/?filter=True`

        if (this.state.urlFilter)
            url += this.state.urlFilter

        const startDate = moment(dateStartTime).format("YYYY-MM-DD").toString()
        const endDate = moment(dateEndTime).format("YYYY-MM-DD").toString()
        const startTime = moment(dateStartTime).format("HH:mm:ss").toString()
        const endTime = moment(dateEndTime).format("HH:mm:ss").toString()

        url += `&date__gte=${startDate}`;
        url += `&date__lte=${endDate}`;
        url += `&time__gte=${startTime}`;
        url += `&time__lte=${endTime}`;
        url += `&limit=${this.state.pageSize}&offset=${this.state.offset}`;

        api.get(url, { timeout: 0 })
            .then((res) => {
                if (res.status == 200) {
                    const rows = [];
                    let hasTransfers = false;
                    res.data.results.forEach((item, index) => {
                        item.path = [item.uniqueid + '_' + item.time];
                        item.actions = 'notTransfer_' + item.uniqueid + '_';
                        rows.push(item);
                        // item.transfers.forEach((transfer, index) => {
                        //     transfer.actions = 'transfer_' + transfer.uniqueid + '_' + index;
                        //     let transferPath = transfer.uniqueid + '_' + transfer.time;
                        //     transfer.path = item.path.concat(transferPath);
                        //     rows.push(transfer);
                        //     hasTransfers = true;
                        // });
                    });

                    this.setState({
                        voiceData: rows,
                        reportType: 'voice',
                        loading: false,
                        generateLoading: false,
                        hasTransfers: hasTransfers,
                        rowCount: res.data.count
                    });
                    return;
                }

                throw res;
            }).catch((err) => {
                console.error(err);
                useValidationError(err.status, null);
                this.setState({
                    loading: false,
                    generateLoading: false
                });
            });
    }

    fetchReport = (dateStartTime, dateEndTime) => {
        this.setState({ loading: true });

        let url = `/api/enterprises/${this.state.enterpriseId}/reports/chat/detailed/?filter=True`

        if (this.state.urlFilter)
            url += this.state.urlFilter

        const startDate = moment(dateStartTime).format("YYYY-MM-DD").toString()
        const endDate = moment(dateEndTime).format("YYYY-MM-DD").toString()
        const startTime = moment(dateStartTime).format("HH:mm:ss").toString()
        const endTime = moment(dateEndTime).format("HH:mm:ss").toString()

        url += `&date__gte=${startDate}&date__lte=${endDate}&time__gte=${startTime}&time__lte=${endTime}&limit=${this.state.pageSize}&offset=${this.state.offset}`

        api.get(url, { timeout: 0 })
            .then((res) => {
                let uniqueidAndHour = {};
                let rows = [];
                let hasTransfers = false;
                let lastSurvey = 1
                let hasComment = false
                const survey_show = {}

                if (res.status == 200) {

                    if (res.data.results.length) {
                        res.data.results.forEach((item, index) => {
                            this.handleReportRow(item, uniqueidAndHour);
                            rows.push(item);
                            item.transfers.forEach((transfer, index) => {
                                this.handleReportRow(transfer, uniqueidAndHour);
                                transfer.actions = 'transfer_' + transfer.uniqueid + '_' + index;
                                let transferPath = transfer.uniqueid + '_' + transfer.time;
                                transfer.path = item.path.concat(transferPath);
                                transfer.platform_entrypoint = item.platform_entrypoint;
                                rows.push(transfer);
                                hasTransfers = true;
                            });
                            if (lastSurvey < 10 && `survey_number_${lastSurvey + 1}` in item) {
                                survey_show[`survey_number_${lastSurvey}`] = true
                                lastSurvey += 1
                            }
                            if (!hasComment && 'survey_comment' in item && item.survey_comment !== '') {

                                hasComment = true
                                survey_show['survey_comment'] = true
                            }
                        })
                    }

                    const newVisibleColumns = {
                        ...this.state.visibleColumnsChatModel,
                        ...survey_show
                    }

                    this.setState({
                        dataReports: rows,
                        filteredDataReports: [],
                        loading: false,
                        uniqueidAndHour: uniqueidAndHour,
                        hasTransfers: hasTransfers,
                        reportType: 'chat',
                        rowCount: res.data.count,
                        visibleColumnsChatModel: newVisibleColumns
                    });

                    this.fastFiltersDialog.setState({
                        normal: true,
                        timeout: true,
                        weekend: true,
                        chatbot: true,
                        checktime: true
                    })

                    this.visibleRowModels();

                    if (this.reportGraphs)
                        this.reportGraphs.refresh();

                    this.setState({
                        generateLoading: false
                    });
                }

                throw res;
            })
            .catch((err) => {
                console.error(err);
                useValidationError(err.status, null);
                this.setState({
                    loading: false,
                    generateLoading: false
                });
            });
    }

    generateReport = (clear = null) => {
        const extraState = {}
        if (clear)
            extraState['selectedChatRows'] = []
        this.setState({ generateLoading: true, ...extraState });

        const dateStartTime = document.getElementById('dateStartTime').value;
        const dateEndTime = document.getElementById('dateEndTime').value;

        if (this.state.reportSelectType == 'voice') {
            this.fetchVoiceReport(dateStartTime, dateEndTime);
            return;
        }

        if (this.state.reportSelectType == 'notification') {
            this.fetchNotificationReport(dateStartTime, dateEndTime);
            return;
        }

        this.fetchReport(dateStartTime, dateEndTime);
    }

    fetchGraphsView = () => {
        this.setState({ loading: true });

        const dateStartTime = document.getElementById('dateStartTime').value;
        const dateEndTime = document.getElementById('dateEndTime').value;

        let url = `/api/enterprises/${this.state.enterpriseId}/reports/chat/detailed/?filter=True`

        if (this.state.urlFilter)
            url += this.state.urlFilter

        const startDate = moment(dateStartTime).format("YYYY-MM-DD").toString()
        const endDate = moment(dateEndTime).format("YYYY-MM-DD").toString()
        const startTime = moment(dateStartTime).format("HH:mm:ss").toString()
        const endTime = moment(dateEndTime).format("HH:mm:ss").toString()

        url += `&date__gte=${startDate}&date__lte=${endDate}&time__gte=${startTime}&time__lte=${endTime}&nopagination=true`

        api.get(url, { timeout: 0 })
            .then((res) => {
                let uniqueidAndHour = {};
                let rows = [];
                let hasTransfers = false;

                if (res.status == 200) {

                    if (res.data.results.length) {
                        res.data.results.forEach((item, index) => {
                            this.handleReportRow(item, uniqueidAndHour);
                            rows.push(item);
                            item.transfers.forEach((transfer, index) => {
                                this.handleReportRow(transfer, uniqueidAndHour);
                                transfer.actions = 'transfer_' + transfer.uniqueid + '_' + index;
                                let transferPath = transfer.uniqueid + '_' + transfer.time;
                                transfer.path = item.path.concat(transferPath);
                                transfer.platform_entrypoint = item.platform_entrypoint;
                                rows.push(transfer);
                                hasTransfers = true;
                            });
                        });
                    }


                    this.setState({
                        graphRows: rows,
                        filteredGraphRows: [],
                        loading: false,
                        uniqueidAndHour: uniqueidAndHour,
                        reportType: 'chat',
                        graphics: true,
                        schedule: false,
                        authenticate: false,
                        generateLoading: false
                    });

                    this.visibleRowModels();

                    if (this.reportGraphs)
                        this.reportGraphs.refresh();
                }

                throw res;
            })
            .catch((err) => {
                console.error(err);
                useValidationError(err.status, null);
                this.setState({
                    loading: false,
                    generateLoading: false
                });
            });

    }


    handlePageSizeChange = (newSize) => {
        this.state.pageSize = newSize
        this.generateReport()
    }

    handlePageChange = (newPage) => {
        this.state.offset = this.state.pageSize * newPage
        this.state.page = newPage
        this.generateReport()
    }

    handleExport = () => {
        if (this.state.graphics && this.reportGraphs) {
            this.reportGraphs.exportToPDF();
        }
    }

    updateActiveGraphics = (newActiveGraphics) => {
        this.setState({ activeGraphics: newActiveGraphics });
    }

    deleteFilter = async (columnName) => {
        let filterModel = { ...this.state.objFilters };

        if (filterModel.items.length) {
            filterModel.items = filterModel.items.filter(item => item.columnField !== columnName);
        }

        await this.setState({ objFilters: filterModel });
        this.selectedFilters(filterModel);
        this.visibleRowModels();
    }

    selectedFilters = (filterModel) => {
        const selectedFilters = {};

        if (filterModel.items.length) {
            filterModel.items.forEach(filter => {
                selectedFilters[filter.columnField] = this.filtersName[filter.columnField];
            });
        }

        this.setState({ selectedFilters, renderKey: Math.random() });
    }

    onFilterChange = async (filterModel) => {
        this.setState({ objFilters: filterModel, page: 0, offset: 0 });
        this.selectedFilters(filterModel);
        this.visibleRowModels();
    }

    // When filter change, we should do the new call to the API and replace data
    componentDidUpdate(prevProps, prevState) {
        if (prevState.objFilters !== this.state.objFilters || prevState.objSort !== this.state.objSort) {
            const filter = this.state.objFilters
            const sort = this.state.objSort

            let filterString = filter.items.map(each => {
                if (each.value)
                    return (`&${each.columnField}__${each.operatorValue}=${each.value}`)
            }).join('')

            if ('linkOperator' in filter && filter.linkOperator == 'or')
                filterString += '&or=True'

            const preSort = '&sort=leg__desc'
            const sortString = sort.map(each => (`&sort=${each.field}__${each.sort}`)).join('')
            const new_url = filterString + preSort + sortString
            this.setState({ urlFilter: new_url })
            return
        }

        if (prevState.urlFilter !== this.state.urlFilter) {

            const dateStartTime = document.getElementById('dateStartTime').value;
            const dateEndTime = document.getElementById('dateEndTime').value;

            let shouldFetch = true

            if (this.state.reportSelectType == 'voice') {
                this.state.objFilters['items'].forEach((item) => {
                    if (item.value === undefined)
                        shouldFetch = false
                })
                if (shouldFetch)
                    this.fetchVoiceReport(dateStartTime, dateEndTime);
                return;
            }

            if (this.state.reportSelectType == 'notification') {
                this.state.notificationObjFilters['items'].forEach((item) => {
                    if (item.value === undefined)
                        shouldFetch = false
                })
                if (shouldFetch)
                    this.fetchNotificationReport(dateStartTime, dateEndTime);
                return;
            }

            this.state.objFilters['items'].forEach((item) => {
                if (item.value === undefined)
                    shouldFetch = false
            })
            if (shouldFetch)
                this.fetchReport(dateStartTime, dateEndTime);
        }
    }

    updateMessagesExport = (shouldExport) => {
        if (shouldExport) {
            const fieldsExport = this.state.fieldsExport;
            fieldsExport.push('messages');
            this.setState({ fieldsExport: fieldsExport });
        } else {
            const fieldsExport = this.state.fieldsExport;
            fieldsExport.pop();
            this.setState({ fieldsExport: fieldsExport });
        }
    }

    setExportColumns = async (columns) => {
        let fieldsExport = [];

        columns.forEach(column => {
            if (column.field === '__check__' ||
                column.field === 'actions' ||
                column.field === 'messages') // Not exporting messages
                return;
            fieldsExport.push(column.field);
            this.filtersName[column.field] = column.headerName;
        });

        await this.setState({ fieldsExport: fieldsExport });
    }

    setVoiceExportColumns = async (columns) => {
        let fieldsExport = [];

        columns.forEach(column => {
            if (
                column.field === '__check__'
                || column.field === 'actions'
                //|| column.hide
            ) {
                return;
            }

            fieldsExport.push(column.field);

            this.filtersName[column.field] = column.headerName;
        });

        await this.setState({ voiceFieldsExport: fieldsExport });
    }

    onNotificationColumnVisibilityModelChange = (params, event) => {
        const visibleColumns = event.api.getVisibleColumns();
        this.setState({ visibleColumnsNotificationModel: params })
    }

    onVoiceColumnVisibilityModelChange = (params, event) => {
        const visibleColumns = event.api.getVisibleColumns();
        this.setState({ visibleColumnsVoiceModel: params })
    }

    onColumnVisibilityModelChange = (params, event) => {
        const visibleColumns = event.api.getVisibleColumns();
        this.setState({ visibleColumnsChatModel: params })
        if (params.messages != this.lastMessagesColStatus) {
            this.lastMessagesColStatus = params.messages;
            this.updateMessagesExport(params.messages);
        }
    }


    visibleRowModels = () => {
        this.setState({ visibleRowModels: this.props.visibleRowModels() });
    }

    platformIcon = (platform) => {
        switch (platform) {
            case 'INSTANTCHAT':
                return (
                    <Tooltip title="Chat Web" placement="bottom" disableInteractive>
                        <InstantChatIcon className="instantchat" />
                    </Tooltip>
                );
            case 'WHATSAPP':
                return (
                    <Tooltip title="WhatsApp" placement="bottom" disableInteractive>
                        <WhatsAppIcon className="whatsapp" />
                    </Tooltip>
                );
            case 'TELEGRAM':
                return (
                    <Tooltip title="Telegram" placement="bottom" disableInteractive>
                        <TelegramIcon className="telegram" />
                    </Tooltip>
                );
            case 'INSTAGRAM':
                return (
                    <Tooltip title="Instagram" placement="bottom" disableInteractive>
                        <InstagramIcon className="instagram" />
                    </Tooltip>
                );
            case 'MESSENGER':
                return (
                    <Tooltip title="Messenger" placement="bottom" disableInteractive>
                        <FacebookIcon className="messenger" />
                    </Tooltip>
                );
            case 'MSTEAMS':
                return (
                    <Tooltip title="Microsoft Teams" placement="bottom" disableInteractive>
                        <MicrosoftIcon className="msteams" />
                    </Tooltip>
                );
            case 'EMAIL':
                return (
                    <Tooltip title="Email" placement="bottom" disableInteractive>
                        <EmailIcon className="email" />
                    </Tooltip>
                );
            case 'AUTOMATION':
                return (
                    <Tooltip title="Automação" placement="bottom" disableInteractive>
                        <SmartToyIcon className="email" />
                    </Tooltip>
                );
            case 'WHATSAPP_ATIVO':
                return (
                    <Tooltip title="WhatsApp" placement="bottom" disableInteractive>
                        <WhatsAppIcon className="whatsapp" />
                    </Tooltip>
                );
            case 'INSTANTVOICE':
                return (
                    <Tooltip title="Voz" placement="bottom" disableInteractive>
                        <PhoneEnabledIcon />
                    </Tooltip>
                );
            case 'API':
                return (
                    <Tooltip title="API" placement="bottom" disableInteractive>
                        <ApiIcon className="email" />
                    </Tooltip>
                );
        }
    }

    convertAudio = async (exportUrl, accountcode) => {

        const data = {
            file: exportUrl
        }

        const res = await api.post(`/api/convertaudio/`, data, { timeout: 0 })

        if (res.status == 200)
            return res.data
        console.error('Failed to convert data.')
        return ''
    }

    handleAudioPlay = async (id, exportUrl, accountcode, uniqueid) => {

        if (this.state.smallLoading)
            return

        if (this.state.playingAudio.audio != null) {
            this.state.playingAudio.audio.pause()
            this.state.playingAudio.audio.currentTime = 0;
        }


        this.setState({ smallLoading: true, playingAudio: { id: id, audio: null } })

        let audio = new Audio(exportUrl);
        audio.onended = (() => { this.setState({ playingAudio: { id: null, audio: null } }) })
        try {
            await audio.play();
            this.setState({ playingAudio: { id: id, audio: audio } })
        } catch (err) {
            useNotification(
                'Aguarde!',
                'O áudio está sendo preparado.',
                'info'
            )
            try {
                const res = await this.convertAudio(exportUrl, accountcode)
                audio = new Audio(res);
                audio.onended = (() => { this.setState({ playingAudio: { id: null, audio: null } }) })
                await audio.play()
                this.setState({ playingAudio: { id: id, audio: audio } })
            }
            catch (err) {
                console.error(err)
                this.setState({ playingAudio: { id: null, audio: null } })
                useNotification(
                    'Ops...',
                    'O arquivo de áudio não existe.',
                    'danger'
                )
            }
        }

        this.setState({ smallLoading: false })

    }

    getAutomatedReportsColumns = () => {
        return [
            {
                field: '_type', headerName: 'Relatório', flex: .5,
                renderCell: (params) => {
                    let report = '';
                    switch (params.value) {
                        case 'chat':
                            report = 'Contatos';
                            break;
                        // case 'calls':
                        //     report = 'Chamadas de voz';
                        //     break;
                        default:
                            break;
                    }
                    return (<Box>{report}</Box>);
                }
            },
            {
                field: 'filters_changed', headerName: 'Filtros', flex: 1,
                renderCell: (params) => {
                    let filters = 'Sem filtros.';
                    if (Object.keys(params.value).length != 0) {
                        filters = '';
                        Object.keys(params.value).forEach((each) => {
                            filters = `${filters}, ${each} ${params.value[each]}`;
                        });
                        filters = filters.slice(2);
                    }
                    return (<Box>{filters}</Box>);
                }
            },
            {
                field: 'period', headerName: 'Período', flex: .5,
                renderCell: (params) => {
                    let period = '';
                    switch (params.value) {
                        case 'today':
                            period = 'Hoje';
                            break;
                        case 'last_day':
                            period = 'Último dia';
                            break;
                        case 'last_week':
                            period = 'Última semana';
                            break;
                        case 'last_month':
                            period = 'Último mês';
                            break;
                        case 'last_3_months':
                            period = 'Últimos 3 meses';
                            break;
                        case 'last_6_months':
                            period = 'Últimos 6 meses';
                            break;
                        default:
                            break;
                    }
                    return (<Box>{period}</Box>);
                }
            },
            { field: 'recipients', headerName: 'Destinatários', flex: 1 },
            {
                field: 'active', headerName: 'Status', flex: .25,
                renderCell: (params) => {
                    if (params.value) {
                        return (
                            <Box sx={{ backgroundColor: "#5399db" }} className={'notification-status'}>
                                Agendado
                            </Box>
                        );
                    } else {
                        return (
                            <Box sx={{ backgroundColor: "#ed6c02" }} className={'notification-status'}>
                                Inativo
                            </Box>
                        );
                    }
                }
            },
            {
                field: 'created_at', type: 'dateTime', headerName: 'Data de criação', flex: .5, hide: true,
                valueGetter: ({ value }) => {
                    if (value) {
                        const date = new Date(value);
                        date.setHours(date.getHours() - 3);
                        return date;
                    }
                },
                renderCell: (params) => (
                    <Box>
                        {params.value ? moment(params.value).format("DD/MM/YYYY HH:mm:ss").toString() : ''}
                    </Box>
                ),
            },
            {
                field: 'last_run', type: 'dateTime', headerName: 'Última execução', flex: .5, hide: true,
                valueGetter: ({ value }) => {
                    if (value) {
                        const date = new Date(value);
                        date.setHours(date.getHours() - 3);
                        return date;
                    }
                },
                renderCell: (params) => (
                    <Box>
                        {params.value ? moment(params.value).format("DD/MM/YYYY HH:mm:ss").toString() : ''}
                    </Box>
                ),
            },
            {
                field: 'next_execution', type: 'dateTime', headerName: 'Próxima execução', flex: .5,
                valueGetter: ({ value }) => {
                    if (value) {
                        const date = new Date(value);
                        date.setHours(date.getHours() - 3);
                        return date;
                    }
                },
                renderCell: (params) => (
                    <Box>
                        {params.value ? moment(params.value).format("DD/MM/YYYY HH:mm:ss").toString() : ''}
                    </Box>
                ),
            },
            {
                field: 'actions', headerName: 'Ações', flex: 0.2, filterable: false, sortable: false,
                renderCell: (params) => (
                    <Box>
                        <Button className="action-button"
                            onClick={() => this.setState({
                                showSchedulerEditDialog: true,
                                selectedSchedulerRow: params.row
                            })}>
                            <EditIcon />
                        </Button>
                        <Button className="action-button delete"
                            onClick={() => this.setState({
                                showSchedulerDeleteDialog: true,
                                selectedSchedulerRow: params.row
                            })}>
                            <TrashIcon />
                        </Button>
                    </Box>
                )
            },
        ];
    }

    handleEditAutomatedReport = (period, recipients, hour, weekdays, months, days, active, reportType) => {

        const id = this.state.selectedSchedulerRow.id;
        const enterpriseId = this.state.enterpriseId;

        const data = {
            period: period,
            recipients: recipients,
            minutes: hour.split(':').pop(),
            hour: hour.split(':')[0],
            year: (new Date).getFullYear(),
            active: active,
            is_xlsx: reportType == 'xlsx' ? true : false
        };

        data['days'] = days.length != 0 ? days.join(',') : '*';
        data['months'] = months.length != 0 ? months.join(',') : '*';
        data['week_days'] = weekdays.length != 0 ? weekdays.join(',') : '*';

        this.setState({
            showSchedulerEditDialog: false,
            selectedSchedulerRow: null
        })

        api.patch(`/api/enterprises/${enterpriseId}/automated_report/${id}/`, data)
            .then((res) => {
                if (res.status == 200) {
                    this.setAutomatedReports();
                    return;
                }
                throw res;
            })
            .catch(async (err) => {
                console.error(err);
                await useNotification(
                    'Ops!',
                    'Ocorreu um erro no sistema',
                    'danger'
                );
            });
    }

    handleDeleteAutomatedReport = (row) => {
        const id = row.id;
        const enterpriseId = this.state.enterpriseId;

        this.setState({
            showSchedulerDeleteDialog: false,
            selectedSchedulerRow: null
        })

        api.delete(`/api/enterprises/${enterpriseId}/automated_report/${id}/`)
            .then((res) => {
                if (res.status == 204) {
                    this.setAutomatedReports();
                    return;
                }
                throw res;
            })
            .catch(async (err) => {
                console.error(err);
                await useNotification(
                    'Ops!',
                    'Ocorreu um erro no sistema',
                    'danger'
                );
            });
    }

    setAutomatedReports = () => {
        this.setState({ loading: true });

        const enterpriseId = this.state.enterpriseId;
        const url = `/api/enterprises/${enterpriseId}/automated_report/`;

        api.get(url, { timeout: 0 }).then((res) => {
            if (res.status == 200) {
                let screenHeight = this.calculateScreenHeight();
                if (screenHeight > res.data.length)
                    screenHeight = res.data.length;
                this.setState({
                    automatedReports: res.data,
                    automatedPageSize: screenHeight,
                    loading: false,
                    graphics: false,
                    schedule: true,
                    authenticate: false
                });
                return;
            }
            throw err;
        }).catch((err) => { console.error(err); });
    }

    handleNotificationErrorMessage = (message) => {
        /** 
         * Handle WhatsApp API errors
         * https://developers.facebook.com/docs/whatsapp/on-premises/errors 
         */

        if (!message) return;

        try {
            const parsedMessage = JSON.parse(message.replace(/'/g, '"'));
            const code = parsedMessage.errors[0].code;
            return notificationError[code]
        } catch (err) {
            console.error(err);
            return 'Erro desconhecido';
        }
    }

    getVoiceDataColumns = () => {
        return [
            { field: 'uniqueid', headerName: 'Identificador', flex: 1, filterOperators: filterOperators },
            {
                field: 'date_time', type: 'dateTime', headerName: 'Data e hora', flex: 1, filterable: false,
                valueGetter: formatRowDateTime,
                renderCell: (params) => (
                    <Box>
                        {params.value}
                    </Box>
                ),
            },
            {
                field: 'status', headerName: 'Status', flex: 1,
                filterOperators: choiceOperators,
                valueOptions: ['Atendida', 'Nao Atendida', 'Desistencia']
            },
            {
                field: 'type', headerName: 'Tipo', flex: 1,
                filterOperators: choiceOperators,
                valueOptions: ['Comando', 'Sainte', 'Entrante'],
                renderCell: (params) => {
                    if (params.row.leg != 0)
                        return params.row.leg_type;
                    return params.row.value;
                }
            },
            { field: 'origin', headerName: 'Origem', flex: 1, filterOperators: filterOperators },
            { field: 'destination', headerName: 'Destino', flex: 1, filterOperators: filterOperators },
            {
                field: 'duration_total', type: 'number', headerName: 'Duração (Total)', flex: 1,
                filterOperators: numericOperators,
                renderCell: (params) => (
                    <Box>{new Date(params.value * 1000).toISOString().substr(11, 8)}</Box>
                ),
            },
            {
                field: 'duration', type: 'number', headerName: 'Duração (Atendimento)', flex: 1,
                filterOperators: numericOperators,
                renderCell: (params) => (
                    <Box>{new Date(params.value * 1000).toISOString().substr(11, 8)}</Box>
                ),
            },
            {
                field: 'hangupby', headerName: 'Finalizador', flex: 1,
                filterOperators: choiceOperators,
                valueOptions: ['Chamador', 'Recebedor']
            },
            {
                field: 'actions', headerName: 'Ações', flex: 0.5, filterable: false, sortable: false,
                renderCell: (params) => { return (this.renderVoiceActions(params)) }
            }
        ];
    }

    renderVoiceActions = (params) => {
        const accountcode = getSession().profile.enterprise.accountcode;
        const uniqueid = params.row.uniqueid;
        const date = params.row.date.replaceAll('-', '');
        const destination = params.row.destination;
        const fileName = `${uniqueid}.WAV`;

        if (params.row.platform_entrypoint === 'INSTANTVOICE') {
            params.row.leg_type = params.row.custom_field1_value
            params.row.type = params.row.custom_field2_value
        }



        let type = params.row.type === 'Entrante'
            ? 'inbound'
            : 'outbound'
            ;

        if (params.row.leg !== 0)
            type = params.row.leg_type?.toLowerCase();


        let directory = `${accountcode}_${destination}`;
        // if (type === 'inbound' && params.row.platform_entrypoint !== 'INSTANTVOICE')
        //     // Directory name is different for inbound calls
        //     directory = directory.split('_').pop();

        //const exportUrl = `/export/${accountcode}/${type}/${date}/${directory}/${fileName}`;
        let exportUrl = ''

        let source = 'record'
        if (params.row.platform_entrypoint === 'INSTANTVOICE') {
            source = 'messages'
        }

        try {
            if (params.row[source])
                exportUrl = atob(params.row[source])
        } catch (e) {
            console.error(e)
            exportUrl = ''
        }


        if (params.row.status !== 'Atendida') {
            // Don't show actions if calls has not been picked up
            // Should it be shown?
            return (<></>);
        }

        return (
            <Box>

                {this.state.playingAudio.id == params.row.id ?
                    <>
                        {this.state.playingAudio.paused ?
                            <Tooltip title="Continuar" placement="top"
                                disableInteractive>
                                <Button className="action-button"
                                    onClick={(event) => {
                                        this.state.playingAudio.audio.play()
                                        this.setState({ playingAudio: { ...this.state.playingAudio, paused: false } })
                                    }}>
                                    <PlayIcon sx={{ color: 'green' }} />
                                </Button>
                            </Tooltip>
                            :
                            <Tooltip title={this.state.smallLoading ? 'Carregando' : 'Pausar'} placement="top"
                                disableInteractive>
                                <Button className="action-button"
                                    onClick={(event) => {
                                        if (this.state.smallLoading)
                                            return
                                        this.state.playingAudio.audio.pause()
                                        this.setState({ playingAudio: { ...this.state.playingAudio, paused: true } })
                                    }}>
                                    {this.state.smallLoading ? <AutorenewIcon className={'spinner'} /> : <PauseIcon />}
                                </Button>
                            </Tooltip>
                        }
                    </>
                    :
                    <Tooltip title="Ouvir gravação" placement="top"
                        disableInteractive>
                        <Button className="action-button"
                            onClick={(event) => {
                                this.handleAudioPlay(
                                    params.row.id,
                                    exportUrl,
                                    accountcode,
                                    uniqueid
                                );
                            }}>
                            <PlayIcon />
                        </Button>
                    </Tooltip>
                }
                <Tooltip title="Baixar gravação" placement="top"
                    disableInteractive>
                    <Button className="action-button"
                        href={exportUrl}
                        download>
                        <ExportIcon />
                    </Button>
                </Tooltip>
            </Box>
        )
    }

    fillLabels = (labels) => {

        const filteredLabels = labels

        return (
            <Box className='wrapped-parent'>
                <Box
                    className='wrapped-relevant'
                >
                    <List
                        className={'special-inline-flex'}
                        sx={{ height: "100%" }}>
                        {filteredLabels?.map(label => (
                            <ListItem
                                disableGutters
                                className="label-item"
                                onClick={() => { }}
                                sx={{ background: `${label.color} !important` }}
                            >
                                <ListItemButton disableGutters sx={{ padding: "4px", overflow: "hidden", fontSize: "0.6rem", }}>
                                    <span>{label.name}</span>
                                </ListItemButton>
                            </ ListItem >
                        ))}
                    </List>
                </Box>
            </Box>)
    }

    componentDidMount = () => {
        useNotification('Informação útil!', 'Os relatórios das conversas são processados a cada 5 minutos!', 'info');

    }

    componentWillMount() {
        const user = getSession();
        let accountcode = null;


        if (user) {
            this.state.enterpriseId = user.profile.enterprise.id;
            accountcode = user.profile.agent.accountcodes_accountcode;
            this.state.accountcode = accountcode;
        }

        if (user.profile.enterprise.has_voice)
            this.getVoiceQueues()

        const urlParams = new URLSearchParams(window.location.search)

        const reportType = urlParams.get('reportType')
        const startDate = urlParams.get('startDate')
        const endDate = urlParams.get('endDate')
        const campaignId = urlParams.get('campaignId')

        let dateStartTime = startDate ? startDate : moment().set({ hour: 8, minute: 0 }).format("YYYY-MM-DDTHH:mm").toLocaleString();
        let dateEndTime = endDate ? endDate : moment().set({ hour: 18, minute: 0 }).format("YYYY-MM-DDTHH:mm").toLocaleString();

        this.setState({
            reportType: reportType ? reportType : 'chat',
            reportSelectType: reportType ? reportType : 'chat',
            notificationObjFilters: campaignId
                ? {
                    items: [{
                        columnField: 'campaign_id',
                        operatorValue: 'equals',
                        value: campaignId
                    }]
                }
                : { items: [] }
            , // Preset campaign ID filter
            visibleColumnsChatModel: {
                uniqueid: false,
                bot_duration: false,
                wait_duration: false,
                queue_duration: false,
                survey_duration: false,
                duration: false,
                messages: false,
                labels: false,
                contact_name: false,
                origin: false,
                dst_num: false,
                tags: false,
                transfer: false,
                survey_comment: false,
                survey_number_2: false,
                survey_number_3: false,
                survey_number_4: false,
                survey_number_5: false,
                survey_number_6: false,
                survey_number_7: false,
                survey_number_8: false,
                survey_number_9: false,
                survey_number_10: false,
                status_hangup: false,
                custom_field2_value: false,
                custom_field3_value: false,
                cortex_duration: false,
                cortex_kb: false,
                cortex_question_amount: false,
                cortex_answer_amount: false,
                cortex_valid_answers: false
            },
            visibleColumnsVoiceModel: {
                uniqueid: false,
                duration_total: false,
                duration: false
            },
            visibleColumnsNotificationModel: {
                campaign_id: false
            }
        }, () => {
            if (reportType == 'notification')
                this.fetchNotificationReport(dateStartTime, dateEndTime)
        })

        if (reportType !== 'notification')
            this.fetchReport(dateStartTime, dateEndTime);

        const dataColumns = [
            { field: 'uniqueid', headerName: 'Identificador', flex: 1, filterOperators: filterOperators },
            {
                field: 'date_time', type: 'dateTime', headerName: 'Data e hora', flex: 1, filterable: false,
                filterOperators: dateOperators,
                valueGetter: formatRowDateTime,
                renderCell: (params) => (
                    <Box>
                        {params.value}
                    </Box>
                ),
            },
            {
                field: 'platform_entrypoint', headerName: 'Canal', flex: 0.3,
                valueOptions: ['Whatsapp', 'InstantChat', 'Telegram', 'Instagram', 'Messenger', 'Email', 'Voz', 'Sms'],
                filterOperators: choiceOperators,
                renderCell: (params) => (
                    <Box>
                        {this.platformIcon(params.value)}
                    </Box>
                ),
            },
            {
                field: 'bot_duration', type: 'number', headerName: 'Duração (ChatBot)', flex: 1,
                filterOperators: numericOperators,
                renderCell: (params) => (
                    <Box>{new Date(params.value * 1000).toISOString().substr(11, 8)}</Box>
                ),
            },
            {
                field: 'wait_duration', type: 'number', headerName: 'Duração (Fila)', flex: 1,
                filterOperators: numericOperators,
                renderCell: (params) => (
                    <Box>{new Date(params.value * 1000).toISOString().substr(11, 8)}</Box>
                ),
            },
            {
                field: 'queue_duration', type: 'number', headerName: 'Duração (Atendimento)', flex: 1,
                filterOperators: numericOperators,
                valueGetter: (params) => {
                    return params.value - params.row.survey_duration
                },
                renderCell: (params) => (
                    <Box>{new Date((params.value) * 1000).toISOString().substr(11, 8)}</Box>
                ),
            },
            {
                field: 'survey_duration', type: 'number', headerName: 'Duração (Pesquisa)', flex: 1,
                filterOperators: numericOperators,
                renderCell: (params) => (
                    <Box>{new Date(params.value * 1000).toISOString().substr(11, 8)}</Box>
                ),
            },
            {
                field: 'duration', type: 'number', headerName: 'Duração (Total)', flex: 1,
                filterOperators: numericOperators,
                renderCell: (params) => (
                    <Box>{new Date(params.value * 1000).toISOString().substr(11, 8)}</Box>
                ),
            },
            {
                field: 'status', type: 'singleSelect', headerName: 'Status', flex: 0.5,
                valueOptions: ['Atendida', 'ChatBot', 'Desistência', 'Não Atendida'],
                filterOperators: choiceOperators,
            },
            {
                field: 'status_hangup', type: 'singleSelect', headerName: 'Desligamento', flex: 1, filterable: false, sortable: false,
                valueGetter: (params) => {
                    if (params.row.status === 'Desistência')
                        return null
                    if (params.row.tags) {
                        let tags = params.row.tags.split(',')
                        let filteredTags = tags.filter(tag => tag.startsWith('sys_'))
                        if (filteredTags.includes('sys_timeout'))
                            return 'Desligada por “timeout”'
                        if (filteredTags.includes('sys_timeout_fds'))
                            return 'Desligada por “timeout” (fds)'
                        if (filteredTags.includes('sys_fora_hora'))
                            return 'Fora do horário de atendimento'
                    }
                    if (params.row.status === 'ChatBot' && !params.row.agent)
                        return 'Retida no “chatbot”'
                    return 'Normal'
                },
            },
            {
                field: 'custom_field3_value', headerName: 'Finalizador', flex: 1,
                filterOperators: choiceOperators,
                valueOptions: ['Chamador', 'Recebedor']
            },
            {
                field: 'custom_field2_value', headerName: 'Tipo', flex: 1,
                filterOperators: choiceOperators,
                valueOptions: ['Comando', 'Sainte', 'Entrante'],
                renderCell: (params) => {
                    if (params.row.leg != 0)
                        return params.row.CUSTOM_FIELD1_VALUE;
                    return params.row.value;
                }
            },
            {
                field: 'queue_name', headerName: 'Fila', flex: 1, valueGetter: getQueueNameAndDesc,
                filterOperators: filterOperators
            },
            {
                field: 'messages', headerName: 'Mensagens', flex: 1,
                filterOperators: filterOperators,
                renderCell: (params) => (
                    <Box>
                        Na coluna ações
                    </Box>
                ),
            },
            {
                field: 'agent', headerName: 'Operador', flex: 1, valueGetter: getAgentNameAndCode,
                filterOperators: filterOperators,
            },
            {
                field: 'labels', headerName: 'Etiquetas', flex: 1,
                filterOperators: filterOperators, sortable: false,
                valueGetter: (params) => { return params.value ? params.value.map(each => each.name).join(', ') : '' },
                renderCell: (params) => (
                    <>
                        {this.fillLabels(params.row.labels)}
                    </>
                )
            },
            {
                field: 'contact_name', headerName: 'Nome do contato', flex: 1,
                filterOperators: filterOperators
            },
            {
                field: 'origin', headerName: 'Origem', flex: 1,
                filterOperators: filterOperators
            },
            {
                field: 'dst_num', headerName: 'Destino', flex: 1,
                filterOperators: filterOperators
            },
            {
                field: 'survey_type', headerName: 'Tipo de pesquisa', flex: 1,
                valueOptions: ['CSAT', 'NPS'],
                filterOperators: choiceOperators
            },
            {
                field: 'survey_number_1', headerName: 'Resposta 1', flex: 1,
                filterOperators: numericOperators,
                renderCell: (params) => (<SurveyCell type={params.row.survey_type}>{params.value}</SurveyCell>)
            },
            {
                field: 'survey_number_2', headerName: 'Resposta 2', flex: 1,
                filterOperators: numericOperators,
                renderCell: (params) => (<SurveyCell type={params.row.survey_type}>{params.value}</SurveyCell>)
            },
            {
                field: 'survey_number_3', headerName: 'Resposta 3', flex: 1,
                filterOperators: numericOperators,
                renderCell: (params) => (<SurveyCell type={params.row.survey_type}>{params.value}</SurveyCell>)
            },
            {
                field: 'survey_number_4', headerName: 'Resposta 4', flex: 1,
                filterOperators: numericOperators,
                renderCell: (params) => (<SurveyCell type={params.row.survey_type}>{params.value}</SurveyCell>)
            },
            {
                field: 'survey_number_5', headerName: 'Resposta 5', flex: 1,
                filterOperators: numericOperators,
                renderCell: (params) => (<SurveyCell type={params.row.survey_type}>{params.value}</SurveyCell>)
            },
            {
                field: 'survey_number_6', headerName: 'Resposta 6', flex: 1,
                filterOperators: numericOperators,
                renderCell: (params) => (<SurveyCell type={params.row.survey_type}>{params.value}</SurveyCell>)
            },
            {
                field: 'survey_number_7', headerName: 'Resposta 7', flex: 1,
                filterOperators: numericOperators,
                renderCell: (params) => (<SurveyCell type={params.row.survey_type}>{params.value}</SurveyCell>)
            },
            {
                field: 'survey_number_8', headerName: 'Resposta 8', flex: 1,
                filterOperators: numericOperators,
                renderCell: (params) => (<SurveyCell type={params.row.survey_type}>{params.value}</SurveyCell>)
            },
            {
                field: 'survey_number_9', headerName: 'Resposta 9', flex: 1,
                filterOperators: numericOperators,
                renderCell: (params) => (<SurveyCell type={params.row.survey_type}>{params.value}</SurveyCell>)
            },
            {
                field: 'survey_number_10', headerName: 'Resposta 10', flex: 1,
                filterOperators: numericOperators,
                renderCell: (params) => (<SurveyCell type={params.row.survey_type}>{params.value}</SurveyCell>)
            },
            {
                field: 'survey_comment', headerName: 'Comentários', flex: 1,
                filterOperators: filterOperators,
                renderCell: (params) => (
                    <Box className="survey-cell">{params.value}</Box>
                )
            },
            {
                field: 'tags', headerName: 'Tags', flex: 1,
                filterOperators: filterOperators,
                valueGetter: (params) => {
                    if (params.value) {
                        let tags = params.value.split(',')
                        let filteredTags = tags.filter(tag => !tag.startsWith('sys_'))
                        return filteredTags.join(',')
                    }
                    return params.value
                }
            },
            {
                field: 'cortex_duration', headerName: 'Duração (Cortex)', flex: 1,
                filterOperators: numericOperators,
                renderCell: (params) => (
                    <Box>{new Date(Number(params.value) * 1000).toISOString().substr(11, 8)}</Box>
                ),
            },
            {
                field: 'cortex_question_amount', headerName: 'Qtd. Perguntas (Cortex)', flex: 1,
                filterOperators: numericOperators
            },
            {
                field: 'cortex_answer_amount', headerName: 'Qtd. Respostas (Cortex)', flex: 1,
                filterOperators: numericOperators
            },
            {
                field: 'cortex_valid_answers', headerName: 'Qtd. Respostas Válidas (Cortex)', flex: 1,
                filterOperators: numericOperators
            },
            {
                field: 'cortex_kb', headerName: 'Base de conhecimento (Cortex)', flex: 1,
                filterOperators: filterOperators,
                valueGetter: (params) => {
                    if (params.value != 'False') {
                        return params.value
                    }
                    return ''
                }
            },
            {
                field: 'actions', headerName: 'Ações', flex: 0.5, filterable: false, sortable: false,
                renderCell: (params) => {

                    if (params.row.platform_entrypoint === 'INSTANTVOICE') {
                        return (this.renderVoiceActions(params))
                    }

                    return (
                        <Box>
                            <Tooltip title="Visualizar histórico de mensagens" placement="top" disableInteractive>
                                <Button className={params.value.split('_')[0] !== 'transfer'
                                    ? 'action-button'
                                    : 'action-button is-transfer'
                                }
                                    onClick={() => this.fetchHistoryMessages(params.value.split('_')[1])}
                                >
                                    <ChatIcon />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Exportar histórico de mensagens" placement="top" disableInteractive>
                                <Button className="action-button"
                                    href={"/api/api/enterprises/"
                                        + getSession().profile.enterprise.id
                                        + "/reports/chat/detailed/download/"
                                        + params.row.uniqueid
                                        + "/"
                                        + params.row.time
                                        + "/"
                                    }
                                >
                                    <ExportIcon />
                                </Button>
                            </Tooltip>
                        </Box>
                    )
                },
            }
        ];

        const voiceDataColumns = this.getVoiceDataColumns();
        const notificationDataColumns = getNotificationColumns(false, () => { });
        const automatedReportsColumns = this.getAutomatedReportsColumns();

        this.setExportColumns(dataColumns);
        this.setVoiceExportColumns(voiceDataColumns);

        let activeGraphics = {
            serviceQueues: { title: 'Atendimentos (filas)', active: true },
            serviceStatus: { title: 'Atendimentos (status)', active: true },
            npsSurvey: { title: 'Pesquisa NPS', active: true },
            csatSurvey: { title: 'Pesquisa CSAT', active: true }
        };

        this.setState({
            dateStartTime: dateStartTime,
            dateEndTime: dateEndTime,
            dataColumns: dataColumns,
            voiceDataColumns: voiceDataColumns,
            notificationDataColumns: notificationDataColumns,
            automatedReportsColumns: automatedReportsColumns,
            activeGraphics: activeGraphics
        });
    }

    scheduleReport = (period, recipients, hour, weekdays, months, days, active, reportType) => {

        const usedHeaders = this.state.dataColumns.filter(each => this.state.fieldsExport.includes(each.field)).map(each => ({ "value": each.field, "header": each.headerName }))
        const haveMessages = usedHeaders.find(each => each.value == 'messages')

        const data = {
            _type: this.state.showAutomatedReportType,
            period: period,
            recipients: recipients,
            minutes: hour.split(':').pop(),
            hour: hour.split(':')[0],
            year: (new Date).getFullYear(),
            active: active,
            is_xlsx: reportType == 'xlsx' ? true : false,
            have_messages: haveMessages ? true : false
        };

        if (days.length != 0)
            data['days'] = days.join(',');
        if (months.length != 0)
            data['months'] = months.join(',');
        if (weekdays.length != 0)
            data['week_days'] = weekdays.join(',');

        const enterpriseId = this.state.enterpriseId;

        let url = `/api/enterprises/${enterpriseId}/automated_report/?filter=True`

        if (this.state.urlFilter && this.state.showAutomatedReportType !== 'notification')
            url += this.state.urlFilter
        else {
            if (this.state.notificationObjFilters['items'].length !== 0) {
                this.state.notificationObjFilters['items'].forEach((each) => {
                    url += `&${each['columnField']}__${each['operatorValue']}=${each['value']}`
                })
            }
            if (this.state.notificationObjSort.length !== 0) {
                const field = this.state.notificationObjSort[0]['field']
                const sort = this.state.notificationObjSort[0]['sort']
                url += `&sort=${field}__${sort}`
            }
        }

        const dateStartTime = document.getElementById('dateStartTime').value;
        const dateEndTime = document.getElementById('dateEndTime').value;

        const startDate = moment(dateStartTime).format("YYYY-MM-DD").toString()
        const endDate = moment(dateEndTime).format("YYYY-MM-DD").toString()
        const startTime = moment(dateStartTime).format("HH:mm:ss").toString()
        const endTime = moment(dateEndTime).format("HH:mm:ss").toString()
        url += `&date__gte=${startDate}&date__lte=${endDate}&time__gte=${startTime}&time__lte=${endTime}`

        api.post(url, data, { timeout: 0 })
            .then(async (res) => {
                if (res.status === 201) {
                    this.setState({ showAutomatedReport: false });
                    this.setAutomatedReports();
                    useNotification(
                        'Sucesso!',
                        'Relatório agendado com sucesso.',
                        'success'
                    );
                    return;
                }
                else if (res.status === 200) {
                    this.setState({ showEmailInputDialog: false });
                    useNotification(
                        'Sucesso!',
                        'O relatório será enviado para o e-mail escolhido assim que processado.',
                        'success'
                    );
                    return
                }
                throw res;
            })
            .catch(async (err) => {
                console.error(err);
                if (err.status === 400) {
                    useNotification(
                        'Atenção!',
                        'Já existe um agendamento com esse período de relatório e filtros.',
                        'warning'
                    );
                    return;
                }
                await useNotification(
                    'Ops!',
                    'Ocorreu um erro no sistema',
                    'danger'
                );
            });
    }

    getAuthenticitySeal = (data) => {
        const createdDate = new Date(data['date'] + ' ' + data['time'])
        const currentDate = new Date()

        return {
            enterprise: getSession().profile.enterprise.name,
            fileName: this.state.files[0].name,
            createdAt: createdDate.toLocaleString('pt-BR'),
            validatedAt: currentDate.toLocaleString('pt-BR'),
            result: 'Autêntico',
            user: getSession().first_name + ' ' + getSession().last_name,
            hash: data['content_hash']
        }
    }

    authenticateFile = async (file) => {
        const enterpriseId = getSession().profile.enterprise.id

        const formdata = new FormData()
        formdata.append('file', file[0])

        this.setState({ loading: true })
        const res = await api.post(`/api/enterprises/${enterpriseId}/reports/chat/detailed/authenticate/`, formdata)
        this.setState({ loading: false })

        const files = this.state.files

        if (files.length == 0)
            return

        if (res.status !== 200) {
            await useNotification(
                'Atenção!',
                'O arquivo não é autêntico.',
                'danger'
            );
            files[0].authentic = false
            this.setState({
                files: files,
                authSeal: {}
            })
            return;
        }

        files[0].authentic = true
        this.setState({
            files: files,
            authSeal: this.getAuthenticitySeal(res.data)
        })
    }

    filterTableRows = (filters) => {
        const rows = []

        if (filters['normal'])
            rows.push({ "columnField": "status", "operatorValue": "is", "id": Math.random(), "value": "Atendida" })
        if (filters['chatbot'])
            rows.push({ "columnField": "status", "operatorValue": "is", "id": Math.random(), "value": "ChatBot" })
        if (filters['timeout'])
            rows.push({ "columnField": "tags", "operatorValue": "equals", "id": Math.random(), "value": "sys_timeout" })
        if (filters['weekend'])
            rows.push({ "columnField": "tags", "operatorValue": "equals", "id": Math.random(), "value": "sys_timeout_fds" })
        if (filters['checktime'])
            rows.push({ "columnField": "tags", "operatorValue": "equals", "id": Math.random(), "value": "sys_fora_hora" })

        this.onFilterChange({ items: rows, linkOperator: 'or' })
        this.setState({ showFastFilters: false })
    }

    render() {
        const filters = [];
        this.state.objFilters.items.forEach((item) => {

            let value = item.value
            if (value in translateSystemName)
                value = translateSystemName[value]

            filters.push(
                `${this.state.selectedFilters[item.columnField]}: ${value}`
            );
        });

        return (
            <Box style={{ height: "100%", padding: "1rem" }}>
                <Loading loading={this.state.loading} />
                {/* <ReactNotifications /> */}
                {this.state.showEmailInputDialog &&
                    <SendReportDialog
                        type={this.state.showEmailInputDialog}
                        submit={(email) => {
                            this.scheduleReport('instant', email, '', '', '', '', true, this.state.showEmailInputDialog);
                            this.setState({ showEmailInputDialog: false })
                        }}
                        handleClose={() => this.setState({ showEmailInputDialog: false })}
                    />
                }
                <AutomatedReportDialog open={this.state.showSchedulerEditDialog}
                    handleClose={() => this.setState({
                        showSchedulerEditDialog: false,
                        selectedSchedulerRow: null
                    })}
                    handleSubmit={this.handleEditAutomatedReport}
                    selectedRow={this.state.selectedSchedulerRow}
                />
                <ConfirmationDialog open={this.state.showSchedulerDeleteDialog}
                    title="Excluir agendamento"
                    description="Tem certeza que deseja excluir esse agendamento de relatório?"
                    handleClose={() => this.setState({
                        showSchedulerDeleteDialog: false,
                        selectedSchedulerRow: null
                    })}
                    handleSubmit={() => this.handleDeleteAutomatedReport(this.state.selectedSchedulerRow)}
                />
                <HistoryMessagesDialog open={this.state.showChatHistory}
                    uniqueId={this.state.uniqueId}
                    nick={this.state.nick}
                    lastDateTime={this.state.lastDateTime}
                    historyMessages={this.state.historyMessages}
                    platform={this.state.platform}
                    accountcode={this.state.accountcode}
                    handleClose={() => this.setState({ showChatHistory: false })}
                />
                <AutomatedReportDialog open={this.state.showAutomatedReport}
                    handleClose={() => this.setState({
                        showAutomatedReport: false
                    })}
                    handleSubmit={this.scheduleReport}
                    filters={filters}
                />
                {this.state.showNotifyTemplates &&
                    <>
                        <Templates
                            open={this.state.showNotifyTemplates}
                            templates={this.state.templates}
                            queues={this.state.queues}
                            clients={[]}
                            selectionIds={this.state.selectedNotifyUsers}
                            originNumbers={this.state.notifyNumbers}
                            handleClose={() => this.setState({ showNotifyTemplates: false })}
                            handlePostSuccess={() => {
                                this.setState({
                                    selectedNotifyUsers: [],
                                    selectedChatRows: []
                                })
                                if (this.apiRef.current)
                                    this.apiRef.current.setSelectionModel([])
                            }}
                            selectedNumberModel={this.state.selectedNotifyUsers}
                        />
                    </>
                }
                <FastFiltersDialog ref={
                    instance => {
                        this.fastFiltersDialog = instance
                    }
                }
                    open={this.state.showFastFilters}
                    handleClose={() => this.setState({ showFastFilters: false })}
                    filterTableRows={this.filterTableRows}
                />
                <GraphicsSettings open={this.state.showSettingsGraph}
                    activeGraphics={this.state.activeGraphics}
                    updateActiveGraphics={this.updateActiveGraphics}
                    handleClose={() => this.setState({ showSettingsGraph: false })}
                />
                <Box className="report-container">
                    <Box className="report-header">
                        {this.state.authenticate &&
                            <Box className="report-authenticate-title">
                                Verificação de autenticidade de documento
                            </Box>
                        }
                        <Box className={this.state.authenticate ? "report-header-left hidden" : "report-header-left"}>
                            <Box>
                                <FormControl size="small">
                                    <InputLabel id="report-type">
                                        Relatório
                                    </InputLabel>
                                    <Select
                                        labelId="report-type"
                                        id="report-type"
                                        value={this.state.reportSelectType}
                                        label="Relatório"
                                        onChange={(event) => {
                                            this.state.reportSelectType = event.target.value
                                            this.setState({
                                                reportSelectType: event.target.value,
                                                objFilters: { items: [] },
                                                objSort: [],
                                                notificationObjFilters: { items: [] },
                                                notificationObjSort: [],
                                                selectedChatRows: []
                                            });
                                            this.selectedFilters({ items: [] })
                                        }}
                                        disabled={this.state.graphics || this.state.schedule}
                                    >
                                        <MenuItem value={'chat'}>Contatos</MenuItem>
                                        {/* <MenuItem value={'voice'}>Chamadas de voz</MenuItem> */}
                                        <MenuItem value={'notification'}>Disparos ativos</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box>
                                <TextField
                                    id="dateStartTime"
                                    size="small"
                                    label="Início"
                                    type="datetime-local"
                                    variant="outlined"
                                    defaultValue={this.state.dateStartTime}
                                    InputLabelProps={{ shrink: true }}
                                    disabled={this.state.graphics || this.state.schedule}
                                />
                            </Box>
                            <Box>
                                <TextField
                                    id="dateEndTime"
                                    size="small"
                                    label="Fim"
                                    type="datetime-local"
                                    variant="outlined"
                                    defaultValue={this.state.dateEndTime}
                                    InputLabelProps={{ shrink: true }}
                                    disabled={this.state.graphics || this.state.schedule}
                                />
                            </Box>
                            {!this.state.graphics && !this.state.schedule &&
                                <Box>
                                    <LoadingButton
                                        onClick={() => this.generateReport(true)}
                                        loadingPosition="start"
                                        loading={this.state.generateLoading}
                                        variant="contained"
                                        startIcon={<TableRowsIcon />}
                                    >
                                        Pesquisar
                                    </LoadingButton>
                                </Box>
                            }
                        </Box>
                        <Box className="report-header-right">
                            <Box>
                                <Button className={this.state.authenticate ? "btn-switch active" : "btn-switch inactive"}
                                    onClick={() => this.setState({
                                        graphics: false,
                                        schedule: false,
                                        authenticate: true
                                    })}
                                    variant={"contained"}
                                    endIcon={<ApprovalIcon />}
                                >
                                    Autenticar
                                </Button>
                            </Box>
                            <Box>
                                <Button className={this.state.schedule ? "btn-switch active" : "btn-switch inactive"}
                                    onClick={() => this.setAutomatedReports()}
                                    variant={"contained"}
                                    endIcon={<CampaignIcon />}
                                >
                                    Agendamentos
                                </Button>
                            </Box>
                            <Box>
                                <Button className={this.state.graphics ? "btn-switch active" : "btn-switch inactive"}
                                    onClick={this.fetchGraphsView}
                                    variant={"contained"}
                                    endIcon={<GraphIcon />}
                                  //  disabled={this.state.reportSelectType === 'voice' || this.state.reportSelectType === 'notification'}
                                >
                                    Gráficos
                                </Button>
                            </Box>
                            <Box>
                                <Button className={!this.state.graphics && !this.state.schedule && !this.state.authenticate ? "btn-switch active" : "btn-switch inactive"}
                                    onClick={async () => {
                                        if (this.state.graphics || this.state.schedule || this.state.authenticate)
                                            await this.setState({
                                                graphics: false,
                                                schedule: false,
                                                authenticate: false
                                            });
                                    }}
                                    variant={"contained"}
                                    endIcon={<TableIcon />}
                                >
                                    Tabela
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="report-filters" key={this.state.renderKey}>
                        {!this.state.schedule && Object.keys(this.state.selectedFilters).map((key, index) => (
                            <Chip id="report-chip"
                                label={this.state.selectedFilters[key]}
                                onDelete={() => !this.state.graphics ? this.deleteFilter(key) : null}
                                deleteIcon={!this.state.graphics ? <DeleteIcon className="report-deleteIcon" /> : <DeleteIcon className="report-none" />}
                            />
                        ))}
                    </Box>
                    <Box className="report-content">
                        {!this.state.graphics && !this.state.schedule && !this.state.authenticate &&
                            <StyledEngineProvider injectFirst>
                                <ThemeProvider theme={theme}>
                                    {this.state.reportType === 'voice' && this.state.hasTransfers &&
                                        <DataGridPro
                                            checkboxSelection
                                            disableSelectionOnClick
                                            disableDensitySelector
                                            density="compact"
                                            columns={this.state.voiceDataColumns}
                                            rows={this.state.voiceData}
                                            selectionModel={this.state.selectedChatRows.map(each => each.id)}
                                            onSelectionModelChange={(selection) => {
                                                const rows = this.state.voiceData
                                                const rowsIds = rows.map(each => each.id)
                                                const removeFromRows = this.state.selectedChatRows.filter(each => !rowsIds.includes(each.id))
                                                const addRows = rows.filter(each => selection.includes(each.id))
                                                const newData = [...removeFromRows, ...addRows]
                                                this.setState({ selectedChatRows: newData })
                                            }}
                                            treeData
                                            getTreeDataPath={(row) => row.path}
                                            groupingColDef={this.groupingColDef}
                                            components={{ Toolbar: this.voiceCustomToolbar, Row: this.CustomRow }}
                                            apiRef={this.apiRef}
                                            onColumnVisibilityModelChange={this.onVoiceColumnVisibilityModelChange}
                                            columnVisibilityModel={this.state.visibleColumnsVoiceModel}
                                            filterMode="server"
                                            onFilterModelChange={this.onFilterChange}
                                            filterModel={this.state.objFilters}
                                            sortingMode="server"
                                            sortModel={this.state.objSort}
                                            onSortModelChange={e => { this.setState({ objSort: e }) }}

                                            //pagination
                                            pagination
                                            paginationMode="server"
                                            rowsPerPageOptions={[25, 50, 100]}
                                            page={this.state.page}
                                            onPageSizeChange={this.handlePageSizeChange}
                                            onPageChange={this.handlePageChange}
                                            rowCount={this.state.rowCount}
                                            keepNonExistentRowsSelected
                                            initialState={{
                                                pagination: {
                                                    pageSize: Number(this.state.pageSize)
                                                }
                                            }}
                                        />
                                    }
                                    {this.state.reportType === 'voice' && !this.state.hasTransfers &&
                                        <DataGridPro
                                            checkboxSelection
                                            disableSelectionOnClick
                                            disableDensitySelector
                                            density="compact"
                                            selectionModel={this.state.selectedChatRows.map(each => each.id)}
                                            onSelectionModelChange={(selection) => {
                                                const rows = this.state.voiceData
                                                const rowsIds = rows.map(each => each.id)
                                                const removeFromRows = this.state.selectedChatRows.filter(each => !rowsIds.includes(each.id))
                                                const addRows = rows.filter(each => selection.includes(each.id))
                                                const newData = [...removeFromRows, ...addRows]
                                                this.setState({ selectedChatRows: newData })
                                            }}
                                            columns={this.state.voiceDataColumns}
                                            rows={this.state.voiceData}
                                            components={{ Toolbar: this.voiceCustomToolbar, Row: this.CustomRow }}
                                            apiRef={this.apiRef}
                                            onColumnVisibilityModelChange={this.onVoiceColumnVisibilityModelChange}
                                            columnVisibilityModel={this.state.visibleColumnsVoiceModel}
                                            filterMode="server"
                                            onFilterModelChange={this.onFilterChange}
                                            filterModel={this.state.objFilters}
                                            sortingMode="server"
                                            sortModel={this.state.objSort}
                                            onSortModelChange={e => { this.setState({ objSort: e }) }}

                                            //pagination
                                            pagination
                                            paginationMode="server"
                                            rowsPerPageOptions={[25, 50, 100]}
                                            page={this.state.page}
                                            onPageSizeChange={this.handlePageSizeChange}
                                            onPageChange={this.handlePageChange}
                                            rowCount={this.state.rowCount}
                                            keepNonExistentRowsSelected
                                            initialState={{
                                                pagination: {
                                                    pageSize: Number(this.state.pageSize)
                                                }
                                            }}
                                        />
                                    }
                                    {this.state.reportType === 'chat' && this.state.hasTransfers &&
                                        <DataGridPro
                                            checkboxSelection
                                            disableSelectionOnClick
                                            disableDensitySelector
                                            density="compact"
                                            columns={this.state.dataColumns}
                                            selectionModel={this.state.selectedChatRows.map(each => each.id)}
                                            onSelectionModelChange={(selection) => {
                                                const rows = this.state.filteredDataReports.length !== 0 ? this.state.filteredDataReports : this.state.dataReports
                                                const rowsIds = rows.map(each => each.id)
                                                const removeFromRows = this.state.selectedChatRows.filter(each => !rowsIds.includes(each.id))
                                                const addRows = rows.filter(each => selection.includes(each.id))
                                                const newData = [...removeFromRows, ...addRows]
                                                this.setState({ selectedChatRows: newData })
                                            }}
                                            treeData
                                            rows={this.state.filteredDataReports.length !== 0 ? this.state.filteredDataReports : this.state.dataReports}
                                            getTreeDataPath={(row) => row.path}
                                            groupingColDef={this.groupingColDef}
                                            apiRef={this.apiRef}
                                            components={{ Toolbar: this.CustomToolbar, Row: this.CustomRow }}
                                            filterMode="server"
                                            onFilterModelChange={this.onFilterChange}
                                            onColumnVisibilityModelChange={this.onColumnVisibilityModelChange}
                                            columnVisibilityModel={this.state.visibleColumnsChatModel}
                                            filterModel={this.state.objFilters}
                                            sortingMode="server"
                                            sortModel={this.state.objSort}
                                            onSortModelChange={e => { this.setState({ objSort: e }) }}

                                            //pagination
                                            pagination
                                            paginationMode="server"
                                            rowsPerPageOptions={[25, 50, 100]}
                                            page={this.state.page}
                                            onPageSizeChange={this.handlePageSizeChange}
                                            onPageChange={this.handlePageChange}
                                            rowCount={this.state.rowCount}
                                            keepNonExistentRowsSelected
                                            initialState={{
                                                pagination: {
                                                    pageSize: Number(this.state.pageSize)
                                                }
                                            }}
                                        />
                                    }
                                    {this.state.reportType === 'chat' && !this.state.hasTransfers &&
                                        <DataGridPro
                                            checkboxSelection
                                            disableSelectionOnClick
                                            disableDensitySelector
                                            density="compact"
                                            columns={this.state.dataColumns}
                                            selectionModel={this.state.selectedChatRows.map(each => each.id)}
                                            onSelectionModelChange={(selection) => {
                                                const rows = this.state.filteredDataReports.length !== 0 ? this.state.filteredDataReports : this.state.dataReports
                                                const rowsIds = rows.map(each => each.id)
                                                const removeFromRows = this.state.selectedChatRows.filter(each => !rowsIds.includes(each.id))
                                                const addRows = rows.filter(each => selection.includes(each.id))
                                                const newData = [...removeFromRows, ...addRows]
                                                this.setState({ selectedChatRows: newData })
                                            }}
                                            rows={this.state.filteredDataReports.length !== 0 ? this.state.filteredDataReports : this.state.dataReports}
                                            apiRef={this.apiRef}
                                            components={{ Toolbar: this.CustomToolbar, Row: this.CustomRow }}
                                            filterMode="server"
                                            onFilterModelChange={this.onFilterChange}
                                            onColumnVisibilityModelChange={this.onColumnVisibilityModelChange}
                                            columnVisibilityModel={this.state.visibleColumnsChatModel}
                                            filterModel={this.state.objFilters}
                                            sortingMode="server"
                                            sortModel={this.state.objSort}
                                            onSortModelChange={e => { this.setState({ objSort: e }) }}

                                            //pagination
                                            pagination
                                            paginationMode="server"
                                            rowsPerPageOptions={[25, 50, 100]}
                                            page={this.state.page}
                                            onPageSizeChange={this.handlePageSizeChange}
                                            onPageChange={this.handlePageChange}
                                            rowCount={this.state.rowCount}
                                            keepNonExistentRowsSelected
                                            initialState={{
                                                pagination: {
                                                    pageSize: Number(this.state.pageSize)
                                                }
                                            }}
                                        />
                                    }
                                    {this.state.reportType === 'notification' &&
                                        <DataGridPro
                                            columns={this.state.notificationDataColumns}
                                            rows={this.state.notificationData}
                                            disableSelectionOnClick
                                            components={{ Toolbar: this.notificationCustomToolbar }}
                                            density={'compact'}
                                            columnVisibilityModel={this.state.visibleColumnsNotificationModel}
                                            onColumnVisibilityModelChange={this.onNotificationColumnVisibilityModelChange}
                                            pagination
                                            paginationMode="server"
                                            rowsPerPageOptions={[25, 50, 100, 250]}
                                            page={this.state.page}
                                            onPageSizeChange={this.handlePageSizeChange}
                                            onPageChange={this.handlePageChange}
                                            rowCount={this.state.rowCount}
                                            keepNonExistentRowsSelected
                                            initialState={{
                                                pagination: {
                                                    pageSize: Number(this.state.pageSize)
                                                }
                                            }}
                                            filterMode='server'
                                            onFilterModelChange={this.onNotificationFilterChange}
                                            filterModel={this.state.notificationObjFilters}
                                            sortingMode="server"
                                            onSortModelChange={this.onNotificationSortChange}
                                            sortModel={this.state.notificationObjSort}
                                        />
                                    }
                                </ThemeProvider>
                            </StyledEngineProvider>
                        }
                        {this.state.graphics && !this.state.schedule && !this.state.authenticate &&
                            <ReportGraphs
                                ref={instance => { this.reportGraphs = instance; }}
                                startDate={document.getElementById('dateStartTime').value}
                                endDate={document.getElementById('dateEndTime').value}
                                data={this.state.filteredGraphRows.length !== 0 ? this.state.filteredGraphRows : this.state.graphRows}
                                filters={this.state.objFilters.items}
                                columns={this.state.dataColumns}
                                activeGraphics={this.state.activeGraphics}
                            />
                        }
                        {!this.state.graphics && this.state.schedule && !this.state.authenticate &&
                            <StyledEngineProvider injectFirst>
                                <ThemeProvider theme={theme}>
                                    <DataGridPro
                                        pageSize={this.state.pageSize}
                                        pagination
                                        disableSelectionOnClick
                                        disableDensitySelector
                                        density="compact"
                                        columns={this.state.automatedReportsColumns}
                                        rows={this.state.automatedReports}
                                        components={{ Toolbar: this.automatedReportsCustomToolbar }}
                                    />
                                </ThemeProvider>
                            </StyledEngineProvider>
                        }
                        {!this.state.graphics && !this.state.schedule && this.state.authenticate &&
                            <Box className="report-authenticate-container">
                                <Box className="filedrop-container">
                                    <FileDrop onDrop={(files, event) => {
                                        this.setState({ files: files })
                                        this.authenticateFile(files)
                                    }}>
                                        {this.state.files.length > 0 ?
                                            <span className='filedrop-filename'>
                                                {Object.values(this.state.files).map((each, index) => (
                                                    <Chip
                                                        className={this.state.files[0].authentic ? 'greenshadow' : 'redshadow'}
                                                        key={`kb-filedrop-${index}`}
                                                        label={each.name}
                                                    />
                                                ))}
                                            </span>
                                            :
                                            <span>Arraste e solte o arquivo.</span>
                                        }
                                        <span>ou</span>
                                        <LoadingButton
                                            variant="contained"
                                            color="primary"
                                            component="label"
                                            loading={false}
                                            loadingPosition="start"
                                            startIcon={<UploadIcon />}
                                            disabled={false}
                                        >
                                            Selecione o arquivo.
                                            <input
                                                hidden
                                                type="file"
                                                accept={'.pdf'}
                                                onChange={(e) => {
                                                    this.setState({ files: e.target.files })
                                                    this.authenticateFile(e.target.files)
                                                }}
                                            />
                                        </LoadingButton>
                                    </FileDrop>
                                </Box>
                                {Object.keys(this.state.authSeal).length !== 0 &&
                                    <Box className="report-authenticity-seal">
                                        <Box className="header">
                                            <img src={LogoImage} alt="Logo" className="logo" />
                                            <Box className="report-authenticate-title">
                                                Selo de autenticidade
                                            </Box>
                                        </Box>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Empresa</td>
                                                    <td>{this.state.authSeal.enterprise}</td>
                                                </tr>
                                                <tr>
                                                    <td>Usuário</td>
                                                    <td>{this.state.authSeal.user}</td>
                                                </tr>
                                                <tr>
                                                    <td>Arquivo</td>
                                                    <td>{this.state.authSeal.fileName}</td>
                                                </tr>
                                                <tr>
                                                    <td>Data de criação</td>
                                                    <td>{this.state.authSeal.createdAt}</td>
                                                </tr>
                                                <tr>
                                                    <td>Data de autenticação</td>
                                                    <td>{this.state.authSeal.validatedAt}</td>
                                                </tr>
                                                <tr>
                                                    <td>Resultado</td>
                                                    <td>{this.state.authSeal.result}</td>
                                                </tr>
                                                <tr>
                                                    <td>Código de autenticidade</td>
                                                    <td>{this.state.authSeal.hash}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Box>
                                }
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
        );
    }
}

export default () => {
    const apiRef = useGridApiRef();

    const visibleSortedRowEntriesSelector = () => {
        return gridVisibleSortedRowEntriesSelector(apiRef.current.state);
    }

    return (
        <DetailedRegister
            apiRef={apiRef}
            visibleRowModels={visibleSortedRowEntriesSelector}
        />
    );
}

