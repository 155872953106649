import React, { useEffect, useLayoutEffect, useReducer, useRef } from 'react'

import { Box } from "@mui/material";

import defaultReducer from '../../../../../utils/defaultReducer'

import { getSession } from "../../../../../auth";

import MemoMessage from '../MessageArea/MemoMessage';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import api from '../../../../../api';
import Loading from '../../../../Loading';

import { ShowComponent } from '../../../../../utils/isVisible';

import AutorenewIcon from '@mui/icons-material/Autorenew';

import dayjs from 'dayjs'


const fetchDelay = 1000

export const calcDate = (time, mask = 'YYYYMMDD') => {
    return dayjs(time * 1000).format(mask)
}

const loadMessages = async (cursor, chatid, isHistory = null, isReport = null) => {
    const append = (isHistory ? `?history=${isHistory}` : '') + (isReport ? `&report=${isReport}` : '')
    if (chatid === undefined) {
        return [[], 0, chatid]
    }
    let url = `api/LHC/chats/messages/${chatid}/${cursor}/` + append
    const res = await api.get(url)
    if (res.status === 200) {
        return res.data
    }
    return [[], 0, chatid]
}

const loadMore = async (e, state, setState, scrollArea) => {
    if (!e || state.fetching || state.last_cursor) return
    setState({ fetching: true })
    const previousScrollHeight = scrollArea.current?.scrollHeight || 0
    const [messages, cursor, newchatid] = await loadMessages(state.cursor, state.chatid, state.isHistory, state.isReport)
    setState({
        messages: [...state.messages, ...messages].toSorted((x, y) => x.time - y.time),
        cursor: cursor !== 0 ? cursor : state.cursor,
        last_cursor: cursor === 0,
        chatid: newchatid
    })
    const nextScrollHeight = scrollArea.current?.scrollHeight || 0
    if (previousScrollHeight && nextScrollHeight && scrollArea && scrollArea.current && cursor !== 0) {
        scrollArea.current.scrollBy(0, Math.abs(nextScrollHeight - previousScrollHeight))
    }

    setTimeout(() => setState({ fetching: false }), fetchDelay)
}

const initializeMessageArea = async (chatid, props, setState) => {
    const user = getSession();
    let queuesName = {};
    setState({ fetching: true })
    user?.profile.enterprise.queues.forEach(queue => {
        const name_queue = queue.name.split('_')[1];
        queuesName[name_queue] = queue.description;
    });

    const [messages, cursor, newchatid] = await loadMessages(0, chatid, props.isHistory, props.isReport)

    setState({
        messages: messages.toSorted((x, y) => x.time - y.time),
        cursor: cursor,
        queuesName: queuesName,
        nameArea: props.isManageCalls ? 'messageArea-manageCalls' : 'messageArea',
        loaded: true,
        chatid: newchatid
    })
    setTimeout(() => setState({ fetching: false }), fetchDelay)
}

const dragScrollDownEffect = (scrollArea, state, setState) => {
    if (scrollArea?.current) {
        scrollArea.current.scrollTop = scrollArea.current.scrollHeight;
    }
    if (state.shouldScrollDown)
        setState({ shouldScrollDown: false, messageAmount: 0 })
}

const dragScrollDown = (scrollArea, state, setState) => {
    if (state.gluedOnBottom) {
        dragScrollDownEffect(scrollArea, state, setState)
        return
    }

    if (!state.shouldScrollDown)
        setState({ shouldScrollDown: true })
}

const detectPosition = (event, gluedOnBottom, setState) => {
    if ((event.target.scrollHeight - Math.round(event.target.scrollTop)) <= event.target.offsetHeight + 1) {
        setState({ gluedOnBottom: true, messageAmount: 0 })
        return
    }
    if (gluedOnBottom)
        setState({ gluedOnBottom: false })
}

const handleAddReaction = (messageId, reaction, state, setState) => {
    const message = state.messages.find(each => each.message_id === messageId)
    if (message) {
        Object.assign(message, {...message, reaction: reaction})
        setState({ messages: [...state.messages] })
    }
}

const handleAddNewMessage = (newMsg, state, setState, scrollArea) => {
    if (newMsg) {
        const oldMsg = state.messages.find(each => each.id === newMsg.id)
        if (oldMsg) {
            Object.assign(oldMsg, newMsg)
            setState({ messages: [...state.messages] })
            if (state.gluedOnBottom)
                dragScrollDownEffect(scrollArea, state, setState)
            return
        }
        if (!('id' in newMsg))
            newMsg.id = Math.random()
        setState({ messages: [...state.messages, newMsg], messageAmount: state.messageAmount + 1 })
        if (state.gluedOnBottom) {
            dragScrollDownEffect(scrollArea, state, setState)
        }

    }
}


export const DateDisplayer = ({ id, time }) => {
    return (
        <Box
            key={`message-date-${id}`}
            className='date-centralizer-box'
        >
            <Box className='date-centralizer-line'></Box>
            <Box className='date-displayer'>{calcDate(time, 'DD/MM/YYYY')}</Box>
            <Box className='date-centralizer-line'></Box>
        </Box>
    )
}

const NewMessageArea = (props) => {

    const scrollArea = useRef()
    const anchor = useRef()

    const [state, setState] = useReducer(defaultReducer, {
        messages: [],
        cursor: 0,
        shouldScrollDown: false,
        gluedOnBottom: true,
        messageAmount: 0,
        nameArea: '',
        queuesName: {},
        loaded: false,
        fetching: false,
        last_cursor: false,
        chatid: 'lh_chat_id' in props.chat ? props.chat.lh_chat_id : props.chat.id,
        isHistory: props.isHistory || props.chat.platform === 'EMAIL',
        isReport: props.isReport || false,
        chatCursor: 0
    })

    dragScrollDown(scrollArea, state, setState)

    if (window.receiveChatCursor)
        window.receiveChatCursor(state.chatCursor)

    props.innerRef(
        {
            messages: state.messages,
            handleAddNewMessage: (newMsg) => handleAddNewMessage(newMsg, state, setState),
            handleAddReaction: (messageId, reaction) => handleAddReaction(messageId, reaction, state, setState),
            dragScrollDownEffect: () => dragScrollDownEffect(scrollArea, state, setState)
        }
    )

    useLayoutEffect(() => {
        const chatid = 'lh_chat_id' in props.chat ? props.chat.lh_chat_id : props.chat.id
        initializeMessageArea(chatid, props, setState)

        return () => {
            setState({
                messages: [],
                loaded: false,
                cursor: 0,
                last_cursor: false,
                chatid: chatid
            })
        }
    }, [])

    useEffect(() => {
        dragScrollDownEffect(scrollArea, state, setState)
        window.test = scrollArea
    }, [state.loaded])

    return (
        <>
            {(!state.loaded && props.isManageCalls) && <Loading loading={true} />}
            <Box style={{ display: props.preview ? 'none' : 'block', height: '100%' }}
                id={state.nameArea}
                key={'this-message-area'}
                ref={scrollArea}
                onScroll={(e) => detectPosition(e, state.gluedOnBottom, setState)}>
                <Box sx={{ minHeight: '1rem', display: 'flex', justifyContent: 'center' }}>
                    {(!state.last_cursor && !state.fetching && state.loaded) &&
                        <ShowComponent
                            parent={scrollArea}
                            loading={(!state.last_cursor && !state.fetching && state.loaded)}
                            isVisibleHook={(e) => { loadMore(e, state, setState, scrollArea) }}>
                        </ShowComponent>}
                    {(state.fetching && !state.last_cursor && state.loaded) &&
                        <AutorenewIcon sx={{ width: '100%' }} className={'fileIcon spinner'} />}
                </Box>

                {
                    state.messages && state.messages.map((message, i) => (
                        <>
                            {i === 0 &&
                                <DateDisplayer
                                    id={message.id}
                                    time={message?.time}
                                />}
                            <MemoMessage
                                key={`message-main-${message.id}`}
                                message={message}
                                messages={state.messages}
                                index={i}
                                accountcode={props.accountcode}
                                isManageCalls={props.isManageCalls}
                                queuesName={state.queuesName}
                                enterpriseId={props.enterpriseId}
                                activeChat={props.chat}
                                visibleHook={(e, ele) => {
                                    if (ele?.current?.getBoundingClientRect().top >= anchor?.current?.getBoundingClientRect().top) {
                                        setState({ chatCursor: e.uniqueid })
                                    }
                                }}
                                parent={scrollArea}
                                parentState={setState}
                                setReplying={props.setReplying}
                            />
                            {((state.messages[i + 1]?.time && calcDate(message?.time) !== calcDate(state.messages[i + 1]?.time)) && i !== 0) &&
                                <DateDisplayer
                                    id={message.id}
                                    time={state.messages[i + 1]?.time}
                                />
                            }
                        </>
                    ))
                }
            </Box >
            {(state.shouldScrollDown && !state.gluedOnBottom && !props.preview) &&
                <Box className='scrolldown-box' onClick={() => dragScrollDownEffect(scrollArea, state, setState)}>
                    <Box sx={{ display: 'flex', alignItems: 'bottom' }}>
                        <ArrowDownwardIcon />
                    </Box>
                    {state.messageAmount > 0 &&
                        <Box
                            className='messageAmount-badge'>
                            {state.messageAmount}
                        </Box>
                    }
                </Box>
            }
            <Box ref={anchor} sx={{ position: 'absolute', bottom: '20%', right: 0, display: 'none' }}>
                anchor
            </Box>
        </>
    )
}


export default NewMessageArea